import instance, { setAuthHeader } from "./axiosEnv";


const getAllLogs = (token) => {
    const config = setAuthHeader(token);
    return instance.get('/changeLogs', config);
}

const getLogsById = (token, id) => {
    const config = setAuthHeader(token);
    return instance.get('/changeLogs/' + id, config);
}


const logsAPI = {
    getAllLogs,
    getLogsById,
};

export default logsAPI;