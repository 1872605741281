import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { userSelector, permissionSelector, organizationAdminSelector } from "redux/userSlice";
import { selectUsersAndGroups, selectUsersAndGroupsStatus, fetchUsersAndGroups } from "redux/usersAndGroupsSlice";
import { AlertSnackbar, CustomDataGrid, AssignRoleForm, UserGroupFetchAlert, RolesUsersLinks } from "components";
import { CardActions, Button, Card, CardContent, CardHeader, useMediaQuery, Link, Tooltip, Typography, IconButton, Alert } from "@mui/material";
import { AdminPanelSettings, CancelOutlined, HowToRegOutlined, LaunchOutlined, PreviewOutlined } from "@mui/icons-material";
import { store } from 'redux/store';

const TableActionButton = ({ messageId, onClick, disabled, children }) => {
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));
    const message = <FormattedMessage id={messageId} />;

    return !smallScreen ? <Button disabled={disabled} onClick={onClick} >
        {message}
    </Button> : <Tooltip title={message} arrow>
        <span>
            <IconButton disabled={disabled} color="secondary" onClick={onClick}>
                {children}
            </IconButton>
        </span>
    </Tooltip>;
}

const UsersView = () => {
    const { token } = useSelector(userSelector);
    const usersAndGroups = useSelector(selectUsersAndGroups);
    const status = useSelector(selectUsersAndGroupsStatus);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const intl = useIntl();
    const [openDialog, setOpenDialog] = useState(false);
    const [singleUser, setSingleUser] = useState(null);
    const [viewMode, setViewMode] = useState(false);
    const [alert, setAlert] = useState({ open: false });
    const organizationAdmin = useSelector(organizationAdminSelector);
    const viewUsersPermission = useSelector((state) => permissionSelector(state, 'view-users'));
    const viewRolesPermission = useSelector((state) => permissionSelector(state, 'view-role'));
    const manageUsersPermission = useSelector((state) => permissionSelector(state, 'manage-users'));

    const onAlertClose = () => setAlert({ ...alert, open: false });

    useEffect(() => {
        if (!viewUsersPermission) {
            setUsers([]);
            return;
        }
        else if (status === 'failed') {
            setLoading(true);
            store.dispatch(fetchUsersAndGroups(token)).then(() => setLoading(false)).catch(error => {
                setAlert({ open: true, messageId: error?.data?.id || "ERROR.FETCHING_USERS_DATA", severity: "error" });
                setUsers({ error });
                setLoading(false);
            })
        } else if (status === 'complete') {
            setUsers(usersAndGroups.users);
        } else setUsers([]);
    }, [token, status, usersAndGroups, viewUsersPermission]);

    const headers = [
        {
            field: 'username',
            headerName: intl.formatMessage({ id: 'USERNAME' }),
            renderCell: (row) => organizationAdmin
                ? <Tooltip title={<FormattedMessage id="CONSOLE.USER.DETAILS" />} placement="right" arrow>
                    <Link href={`${process.env.REACT_APP_KEYCLOAK_BASE_URL}admin/${process.env.REACT_APP_KEYCLOAK_REALM}/console#/${process.env.REACT_APP_KEYCLOAK_REALM}/users/${row.row.id}/settings`} target="_blank">
                        <Typography>{row.row.username}<LaunchOutlined fontSize="sm" sx={{ pl: 0.5 }} /></Typography>
                    </Link>
                </Tooltip>
                : <Typography>{row.row.username}</Typography>,
            minWidth: 200,
            flex: 2
        },
        {
            field: 'enabled',
            type: 'boolean',
            flex: 1,
            headerName: intl.formatMessage({ id: 'ENABLED' }),
            minWidth: 100
        },
        {
            field: 'emailVerified',
            type: 'boolean',
            flex: 1,
            headerName: intl.formatMessage({ id: 'EMAIL_VERIFIED' }),
            minWidth: 150
        },
        {
            field: 'actions',
            headerName: intl.formatMessage({ id: 'ACTIONS' }),
            type: 'actions',
            flex: 2,
            getActions: (el) => manageUsersPermission && viewRolesPermission
                ? [
                    <TableActionButton key={el.id + "-form"} messageId="VIEW_ROLES"
                        onClick={() => {
                            setOpenDialog(true);
                            setViewMode(true);
                            setSingleUser(el.row);
                        }}
                    >
                        <PreviewOutlined />
                    </TableActionButton>,
                    <TableActionButton key={el.id + "-view"} messageId="ASSIGN.ROLE" disabled={el.row.userName === process.env.REACT_APP_ADMIN_ROLE}
                        onClick={() => {
                            setOpenDialog(true);
                            setViewMode(false);
                            setSingleUser(el.row);
                        }}
                    >
                        <HowToRegOutlined />
                    </TableActionButton>,
                ]
                : [smallScreen ? <IconButton key="disabled-actions" disabled><CancelOutlined fontSize="small" /></IconButton> : <Button key="disabled-actions" disabled><FormattedMessage id="DISABLED" /></Button>],
            minWidth: 150,
        },
    ]

    return (<>
        <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
        <Card sx={{ width: '100%' }}>
            {singleUser && <AssignRoleForm viewMode={viewMode} onChangeView={() => setViewMode(false)} user={singleUser} open={openDialog} onClose={() => setOpenDialog(false)} setAlert={setAlert} />}
            <CardHeader title={<FormattedMessage id="USERS.TABLE" />}
                action={organizationAdmin &&
                    <Tooltip title={<span><FormattedMessage id="GO_TO_ADMIN_CONSOLE" /></span>} placement="left">
                        <span>
                            <Button size="small" variant="contained" color="info" startIcon={<AdminPanelSettings />} onClick={() => window.open(`${process.env.REACT_APP_KEYCLOAK_BASE_URL}admin/${process.env.REACT_APP_KEYCLOAK_REALM}/console/#/${process.env.REACT_APP_KEYCLOAK_REALM}/users`)}>
                                <FormattedMessage id={smallScreen ? "MANAGE" : "USER_MANAGMENT"} />
                            </Button>
                        </span>
                    </Tooltip>
                }
            />

            <CardActions sx={{ px: 2 }}>
                <RolesUsersLinks users />
            </CardActions>

            {status === 'disabled'
                ? <CardContent><UserGroupFetchAlert /></CardContent>
                : <CardContent>
                    {users?.error
                        ? <Alert severity='error'><FormattedMessage id="ERROR.NO_DATA" /></Alert>
                        : <CustomDataGrid isLoading={loading} rows={users} columns={headers} />
                    }
                </CardContent>
            }
        </Card>
    </>
    )
}

export default UsersView;