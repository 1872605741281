import { AddOutlined, CancelOutlined, ChevronLeftOutlined, EditOutlined, FilterAltOffOutlined, FilterAltOutlined, LowPriority, PlagiarismOutlined, SearchOutlined, SportsScoreOutlined, TableChartOutlined, TroubleshootOutlined, TuneOutlined, ViewAgendaOutlined } from "@mui/icons-material";
import { Autocomplete, Badge, Box, ButtonGroup, Card, Chip, Collapse, Divider, IconButton, InputAdornment, Stack, TextField, Tooltip, styled, useMediaQuery } from "@mui/material";
import { CustomButton } from "components/entityLinksButtons/EntityLinksButtons";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { selectMetricTypesConf } from "redux/configurationSlice";
import { selectEntities } from "redux/entitySlice";
import { permissionSelector } from "redux/userSlice";

const RotatedIcon = styled((props) => {
    const { rotate, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, rotate }) => ({
    transform: !rotate ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

export const CustomChip = ({ smallIcons, ...props }) => {
    if (smallIcons) return <Tooltip title={props.label} arrow>
        <IconButton onClick={props.onClick}>{props.icon}</IconButton>
    </Tooltip>
    else return <Chip {...props} />
}

export default function TopicFilters(props) {
    const { topics, disabled, specialNavigate, searchFilter, setSearchFilter, entityFilter, setEntityFilter, sort, setSort, categoryFilter, setCategoryFilter, newTopicForm, editTopic, setEditTopics, disableActions, onChangeView, tableView, setTableView } = props;
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
    const xsScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const intl = useIntl();
    const navigate = useNavigate();
    const [moreFilters, setMoreFilters] = useState(false);
    const categories = useSelector(selectMetricTypesConf);
    const entities = useSelector(selectEntities);
    const queryId = useParams().entityId;

    const createTopicPermission = useSelector((state) => permissionSelector(state, 'create-topic'));
    const updateTopicPermission = useSelector((state) => permissionSelector(state, 'update-topic'));
    const viewMetricPermission = useSelector(state => permissionSelector(state, "view-metric"));
    const viewGoalPermission = useSelector(state => permissionSelector(state, "view-goal"));

    useEffect(() => {
        document.addEventListener("keydown", (event) => {
            if (event.key === "Escape") {
                if (editTopic) setEditTopics(false);
            }
        }, false);
    }, [editTopic, setEditTopics]);

    useEffect(() => {
        if (topics?.length === 0) setEditTopics(false);
    }, [topics, setEditTopics]);

    return <Card sx={{ width: '100%', p: 0.5, mb: 1 }}>
        <Stack direction={smallScreen ? "column" : "row"} spacing={1} alignItems={smallScreen ? "flex-start" : "center"}>
            {onChangeView !== undefined && typeof onChangeView === 'function' && <Stack direction="row" gap={1} alignItems="center">
                <Chip onClick={() => navigate(specialNavigate ? specialNavigate : ('/topics/' + queryId))} icon={<ChevronLeftOutlined />} label={<FormattedMessage id="BACK" />} />
                <ButtonGroup size="small" variant="outlined">
                    <CustomButton curvedLeft color="primary" variant="contained" icon={<PlagiarismOutlined />} label={<FormattedMessage id="ESG.SUB-TOPICS" />} />
                    {viewMetricPermission && <CustomButton color="primary" variant="outlined" icon={<TroubleshootOutlined />} onClick={() => onChangeView('metrics')} label={<FormattedMessage id="ESG.METRICS" />} />}
                    {viewGoalPermission && <CustomButton curvedRight color="primary" variant="outlined" icon={<SportsScoreOutlined />} onClick={() => onChangeView('goals')} label={<FormattedMessage id="ESG.GOALS" />} />}
                </ButtonGroup>
            </Stack>}
            <Autocomplete
                disabled={disabled}
                id="search-topic"
                freeSolo
                options={[...new Set(topics.map(topic => topic.name))]}
                value={searchFilter}
                onChange={(event, value) => setSearchFilter(value)}
                renderInput={(params) => (
                    <TextField {...params} placeholder={intl.formatMessage({ id: "ESG.TOPICS.SEARCH_PLACEHOLDER" })} InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchOutlined color={disabled ? "disabled" : "primary"} />
                            </InputAdornment>
                        )
                    }} />
                )}
                size="small"
                fullWidth
            />
            <Stack direction="row" gap={1}>
                <Tooltip title={<FormattedMessage id={(tableView ? "TABLE" : "CARDS") + ".SHOW"} />} placement="bottom" arrow>
                    <span>
                        <IconButton disabled={disabled} size="small" onClick={() => setTableView(!tableView)}>
                            {tableView ? <TableChartOutlined color="primary" /> : <ViewAgendaOutlined />}
                        </IconButton>
                    </span>
                </Tooltip>
                <Tooltip title={<FormattedMessage id={"SORT." + (sort ? "LOW" : "HIGH") + "_PRIORITY"} />} placement="bottom" arrow>
                    <span>
                        <RotatedIcon disabled={disabled} rotate={sort} size="small" onClick={() => setSort(!sort)}>
                            <LowPriority color={sort ? "primary" : "text.disabled"} />
                        </RotatedIcon>
                    </span>
                </Tooltip>
                <Tooltip title={<FormattedMessage id={"MORE_FILTERS" + (categoryFilter ? ".ACTIVE" : "")} />} placement="bottom" arrow>
                    <span>
                        <IconButton disabled={disabled} size="small" onClick={() => setMoreFilters(!moreFilters)}>
                            <Badge color="primary" variant="dot" invisible={!categoryFilter && !entityFilter}>
                                <TuneOutlined color={moreFilters ? "primary" : "default"} />
                            </Badge>
                        </IconButton>
                    </span>
                </Tooltip>
                <Divider orientation="vertical" flexItem />
                {!disableActions && createTopicPermission && newTopicForm && <CustomChip smallIcons={xsScreen} disabled={editTopic} variant="outlined" sx={{ px: 1 }} icon={<AddOutlined fontSize="small" color="primary" />} onClick={newTopicForm} label={<FormattedMessage id="ADD.NEW" />} />}
                {!disableActions && updateTopicPermission && topics?.length > 0 && <CustomChip smallIcons={xsScreen} sx={{ px: 1 }} icon={editTopic ? <CancelOutlined fontSize="small" color="error" /> : <EditOutlined fontSize="small" color="primary" />} onClick={() => setEditTopics(!editTopic)} label={<FormattedMessage id={editTopic ? "CANCEL" : "EDIT"} />} />}
            </Stack>
        </Stack>
        <Collapse in={moreFilters}>
            <Box sx={{ mt: 1 }}>
                <Autocomplete
                    id="filter-category"
                    options={categories.map(el => el.key)}
                    value={categoryFilter}
                    onChange={(event, value) => setCategoryFilter(value)}
                    getOptionLabel={(opt) => intl.formatMessage({ id: 'ESG.TYPE.' + opt })}
                    renderInput={(params) => (
                        <TextField {...params} placeholder={intl.formatMessage({ id: "ESG.TOPICS.SEARCH_PLACEHOLDER.STATUS" })} InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <InputAdornment position="start">
                                    {categoryFilter ? <FilterAltOutlined color="primary" /> : <FilterAltOffOutlined color="text.disabled" />}
                                </InputAdornment>
                            )
                        }} />
                    )}
                    size="small"
                    fullWidth
                />
            </Box>
            {(entityFilter || entityFilter === null) && <Box sx={{ mt: 1 }}>
                <Autocomplete
                    id="filter-entity"
                    options={Object.values(entities)}
                    value={entityFilter}
                    onChange={(event, value) => setEntityFilter(value)}
                    getOptionLabel={(opt) => opt.name}
                    renderInput={(params) => (
                        <TextField {...params} placeholder={intl.formatMessage({ id: "ESG.TOPICS.SEARCH_PLACEHOLDER.ENTITY" })} InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <InputAdornment position="start">
                                    {entityFilter ? <FilterAltOutlined color="primary" /> : <FilterAltOffOutlined color="text.disabled" />}
                                </InputAdornment>
                            )
                        }} />
                    )}
                    size="small"
                    fullWidth
                />
            </Box>}
        </Collapse>
    </Card>
}