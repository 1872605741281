import { Box, Chip, ListItemButton, ListItemText, Stack, SvgIcon, Tooltip } from '@mui/material';
import { useNavigate } from "react-router";
import { FormattedMessage } from 'react-intl';
import { ExpandMore, ExpandLess, ArrowUpwardOutlined, KeyboardArrowUp, KeyboardArrowDown, FlipCameraAndroidOutlined } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { selectEntities } from 'redux/entitySlice';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { recursiveParentSearch } from 'utils/helpers';
import { Virtuoso } from 'react-virtuoso';
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';
import Tree from 'react-d3-tree';

const useCenteredTree = (defaultTranslate = { x: 0, y: 0 }) => {
    const [translate, setTranslate] = useState(defaultTranslate);
    const containerRef = useCallback((containerElem) => {
        if (containerElem !== null) {
            const { width, height } = containerElem.getBoundingClientRect();
            setTranslate({ x: width / 2, y: height / 8 });
        }
    }, []);
    return [translate, containerRef];
}

const renderRectSvgNode = ({ nodeDatum, toggleNode, hierarchyPointNode, entityId }) => {
    const hasChildren = hierarchyPointNode.data?.children?.length;
    const isCollapsed = hierarchyPointNode.data?.__rd3t?.collapsed;
    const isRoot = nodeDatum?.id === 'entities'
    const isSelected = (entityId && nodeDatum.id === entityId) || (!entityId && isRoot);

    return <>
        <foreignObject x={isRoot ? "-160" : "-80"} y="-15" width={isRoot ? "320" : "160"} height="40" display='flex'>
            <Chip sx={theme => ({
                justifyContent: 'space-between',
                bgcolor: isSelected ? 'success.main' : (!hasChildren ? theme.palette.grey[(theme.palette.mode === 'light' ? '300' : '600')] : undefined),
                ':hover': !isSelected && !hasChildren ? {
                    bgcolor: theme.palette.grey[(theme.palette.mode === 'light' ? '400' : '700')]
                } : undefined
            })} className={"node-" + nodeDatum.id} color={isSelected ? "success" : (hasChildren ? "primary" : "default")}
                icon={nodeDatum.customAction
                    ? <Tooltip title={<nodeDatum.customAction.Label />} arrow>
                        <SvgIcon fontSize='small' sx={theme => ({ stroke: theme.palette.primary.contrastText, strokeWidth: 0.1 })} onClick={nodeDatum.customAction.onClick}><nodeDatum.customAction.Icon /></SvgIcon>
                    </Tooltip>
                    : <span></span>}
                deleteIcon={hasChildren ? <SvgIcon fontSize='small' sx={theme => ({ stroke: theme.palette.primary.contrastText })}>
                    {isCollapsed ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </SvgIcon> : <span></span>}
                onDelete={(e) => { if (hasChildren) { toggleNode(); e.stopPropagation() } }}
                onClick={(e) => {
                    nodeDatum.onClick();
                    e.stopPropagation();
                }} component="span" label={<Tooltip title={nodeDatum.name} followCursor><span>{nodeDatum.name}</span></Tooltip>}
            />
        </foreignObject>
    </>
};

const EntityTree = ({ isOpen, entityId, graphFlag, navigateTo, rootUrl, search, foundEntities, setFoundEntities }) => {
    const navigate = useNavigate();
    const allEntities = useSelector(selectEntities);
    const allEntitiesArray = useMemo(() => Object.keys(allEntities).map(entity => allEntities[entity]), [allEntities]);
    const getAllChildren = useCallback((childrenIds) => {
        return childrenIds.map((childId) => allEntitiesArray.find(ent => ent._id === childId)).filter(Boolean);
    }, [allEntitiesArray]);
    const [orientation, setOrientation] = useState('vertical');
    const [expanded, setExpanded] = useState(Object.keys(allEntities));
    const [visible, setVisible] = useState(Object.keys(allEntities));
    const ref = useRef(null);
    const [scrolled, setScrolled] = useState(0);
    const listRef = useRef(null);
    const [translate, containerRef] = useCenteredTree();


    useEffect(() => {
        if (search?.length && typeof setFoundEntities === 'function') {
            const foundEntities = Object.values(allEntities).filter(el => el.name?.toLowerCase()?.includes(search?.toLowerCase()));

            const uniqueParentEntities = new Set();

            for (let ent of foundEntities) {
                const parentEntities = recursiveParentSearch(ent, allEntities);
                parentEntities.forEach(parent => uniqueParentEntities.add(parent));
            }

            const parentArray = Array.from(uniqueParentEntities);
            setFoundEntities(foundEntities.map(el => el._id));
            setExpanded([...parentArray.map(el => el._id), ...foundEntities.map(el => el._id)]);
            setVisible([...parentArray.map(el => el._id), ...foundEntities.map(el => el._id)]);
        }
        else {
            if (typeof setFoundEntities === 'function') setFoundEntities([]);
            setVisible([]);
        }
    }, [allEntities, setFoundEntities, search]);

    const renderListItem = useCallback((_item) => {
        const renderItem = (item) => {
            if (!item) return null;
            const style = foundEntities.length ? { color: foundEntities.includes(item.id) ? "secondary.dark" : "text.primary" } : {};

            return <TreeItem sx={{ py: 0 }} key={item.id} itemId={item.id} label={(item.id === 'entities' || !item.children?.length || entityId === item.id)
                ? <ListItemText onClick={item.onClick} sx={{ cursor: 'pointer', ...style }} primary={item.label} secondary={item.id === 'entities' && <FormattedMessage id="ORGANIZATION" />} />
                : <ListItemButton sx={{ p: 0 }} onClick={item.onClick}><ListItemText sx={{ ...style }} primary={item.label} /></ListItemButton>
            }>
                {item.children?.map(item => renderItem(item))}
            </TreeItem>
        }
        return <SimpleTreeView
            expandedItems={expanded}
            selectedItems={entityId || 'entities'}
            onSelectedItemsChange={() => { }}
            onExpandedItemsChange={(e, nodeIds) => setExpanded(nodeIds)}
        >
            {renderItem(_item)}
        </SimpleTreeView>;
    }, [entityId, expanded, foundEntities]);

    const items = useCallback((isList) => {
        const url = navigateTo ? navigateTo : '/entities';
        const root_url = rootUrl ? rootUrl : '/entities';

        const entities = allEntitiesArray.filter(entity => (foundEntities?.length ? visible.includes(entity._id) : true) && (entity.connections.parentId === null || !allEntities[entity.connections.parentId]));
        const render = (entities) => entities.map((entity) => ({
            id: entity._id,
            [isList ? 'label' : 'name']: entity.name,
            children: render(getAllChildren(entity.connections.childrenArray)),
            onClick: (e) => {
                navigate(url + '/' + entity._id);
                e?.stopPropagation();
            }
        }));

        const rootEntity = {
            id: 'entities',
            [isList ? 'label' : 'name']: process.env.REACT_APP_ORG_TITLE,
            customAction: {
                onClick: (e) => { setOrientation(old => old === 'vertical' ? 'horizontal' : 'vertical'); e.stopPropagation() },
                Icon: () => <FlipCameraAndroidOutlined fontSize='small' />,
                Label: () => <FormattedMessage id="TOGGLE_ORIENTATION" />,
            },
            onClick: () => {
                navigate(root_url)
            }
        }

        if (entities?.length) {
            if (isList) return [rootEntity, ...render(entities)];
            else return [{ ...rootEntity, children: render(entities) }];
        }
        else return [rootEntity];
    }, [getAllChildren, navigate, allEntitiesArray, visible, foundEntities?.length, allEntities, navigateTo, rootUrl]);

    useEffect(() => {
        if (ref !== null) ref.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    }, [isOpen, ref, entityId]);

    return <>
        {!graphFlag && <Stack direction="row" gap={1} sx={{ mb: 0.5 }}>
            <Chip size="small" label={expanded.length ? <FormattedMessage id="COLLAPSE_ALL" /> : <FormattedMessage id="EXPAND_ALL" />} icon={expanded.length ? <ExpandLess /> : <ExpandMore />} onClick={() => expanded.length ? setExpanded([]) : setExpanded(Object.keys(allEntities))} />
            {scrolled > 100 && <Chip size="small" label={<FormattedMessage id="SCROLL_TO_TOP" />} icon={<ArrowUpwardOutlined />} onClick={() => {
                if (listRef?.current) listRef.current.scrollToIndex({
                    index: 0,
                    align: 'start',
                    behavior: 'smooth'
                })
            }} />}
        </Stack>}
        <Box sx={{ height: '100%', pb: 1 }} ref={containerRef}>
            {
                graphFlag
                    ? <Tree
                        data={items(false)}
                        pathFunc='step'
                        orientation={orientation}
                        translate={translate}
                        separation={{ siblings: orientation === 'vertical' ? 1.2 : 0.6, nonSiblings: orientation === 'vertical' ? 1.4 : 0.7 }}
                        depthFactor={orientation === 'vertical' ? 80 : 240}
                        zoom={1}
                        scaleExtent={{ min: 0.1, max: 2 }}
                        renderCustomNodeElement={(props) => renderRectSvgNode({ ...props, entityId: entityId })}
                    />
                    : <Virtuoso
                        ref={listRef}
                        data={items(true)}
                        onScroll={(e) => setScrolled(e.target.scrollTop)}
                        itemContent={(_, item) => renderListItem(item)}
                    />
            }
        </Box>
    </>
}

export default EntityTree;