import { Spa, QueryStatsOutlined, EditOutlined, PriorityHigh, ErrorOutline, KeyboardDoubleArrowDown, GavelOutlined, Diversity3, HistoryEduOutlined } from "@mui/icons-material";
import { Alert, Avatar, Card, CardActions, CardContent, CardHeader, Chip, Container, Grid, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { BreadcrumbsAndNavigation, AlertSnackbar, GoalsView, TopicForm, CustomDataGrid, ExpandedGridCell } from "components";
import { EmptyState } from 'layouts';
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { selectPriorityTypes } from "redux/configurationSlice";
import { selectEntities } from "redux/entitySlice";
import { selectTopics } from "redux/topicSlice";
import SingleTopicView from "./SingleTopicView";
import TopicFilters from "./TopicFilters";
import { Virtuoso } from "react-virtuoso";

export const getTopicIcon = (type, props) => {
    switch (type) {
        case "E":
            return <Spa {...props} />;
        case "S":
            return <Diversity3 {...props} />;
        case "G":
            return <GavelOutlined {...props} />;
        default:
            return null;
    }
}

export const getTopicColor = (type, simple) => {
    switch (type) {
        case "E":
            return "success" + (!simple ? ".dark" : "");
        case "S":
            return "secondary" + (!simple ? ".dark" : "");
        case "G":
            return "info" + (!simple ? ".main" : "");
        default:
            return "default";
    }
}

export const getPriorityIcon = (priority, props) => {
    switch (priority) {
        case 0:
            return <KeyboardDoubleArrowDown color="info" fontSize="small" {...props} />;
        case 1:
            return <PriorityHigh color="warning" fontSize="small" {...props} />;
        case 2:
            return <ErrorOutline color="error" fontSize="small" {...props} />;
        default:
            return null;
    }
}

export const TopicCard = ({ topic, suffix, action, priority, onClick }) => {
    const isSelectible = onClick !== undefined;
    return <Card sx={theme => ({ mb: 2, width: '99%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', ...(isSelectible && { cursor: 'pointer', ':hover': { bgcolor: theme.palette.action.hover } }) })} variant={isSelectible ? "elevation" : "outlined"} onClick={onClick} elevation={isSelectible ? 2 : 0}>
        <CardHeader
            title={<Typography variant="h6" color={isSelectible ? undefined : "text.secondary"} component="span">{topic.name} {suffix}</Typography>}
            avatar={<Avatar sx={{ bgcolor: getTopicColor(topic.type) }} aria-label="topic-type">
                {getTopicIcon(topic.type)}
            </Avatar>}
            subheader={<FormattedMessage id={"ESG.TYPE." + topic.type} />}
            action={action}
        />
        <CardActions disableSpacing sx={{ display: 'inline-block' }}>
            {priority && <Chip sx={{ mr: 0.2 }} size="small" icon={getPriorityIcon(priority.key)} label={<FormattedMessage id={"PRIORITY." + priority.value} />} />}
            <Chip sx={{ mr: 0.2 }} size="small" icon={<HistoryEduOutlined color="primary" fontSize="small" />} label={<span style={{ textTransform: 'lowercase' }}>{topic.connections.childrenArray.length} <FormattedMessage id="ESG.SUB-TOPICS" /></span>} />
            <Chip size="small" icon={<QueryStatsOutlined color="primary" fontSize="small" />} label={<span style={{ textTransform: 'lowercase' }}>{topic.metricIds.length} <FormattedMessage id="ESG.METRICS" /></span>} />
        </CardActions>
    </Card>
}

export default function TopicsView(props) {
    const navigate = useNavigate();
    const intl = useIntl();
    const [searchParams] = useSearchParams();
    const navigateState = useLocation().state;
    const topicId = searchParams.get("topic");
    const queryId = useParams().entityId;
    const allEntities = useSelector(selectEntities);
    const priorityTypes = useSelector(selectPriorityTypes);
    const topics = useSelector(selectTopics).filter(el => (queryId ? el.entityId === queryId : true));
    const entityId = allEntities[queryId] ? queryId : null;
    const [topicView, setTopicView] = useState('metrics');
    const [selectedTopic, setSelectedTopic] = useState(null);
    const [searchFilter, setSearchFilter] = useState(null);
    const [categoryFilter, setCategoryFilter] = useState(null);
    const [entityFilter, setEntityFilter] = useState(null);
    const [sort, setSort] = useState(false);
    const [editTopic, setEditTopics] = useState(false);
    const [open, setOpen] = useState(false);
    const [tableView, setTableView] = useState(false);
    const [alert, setAlert] = useState({ open: false });

    const onAlertClose = () => setAlert({ ...alert, open: false });
    useEffect(() => {
        setEditTopics(false);
        setTopicView(navigateState && navigateState.topicView ? navigateState.topicView : 'subtopics');
        if (!queryId) setEntityFilter(null);
    }, [queryId, topicId, navigateState]);

    const foundTopic = topics.find(topic => topic._id === topicId);
    const currentLevelTopics = topics.filter(topic => topicId ? topic.connections.parentId === topicId : topic.connections.parentId === null);

    if ((queryId && !entityId) || (topicId && !foundTopic)) return <EmptyState message={<FormattedMessage id="SPLASH.UNAVAILABLE" />} />;

    const filteredTopics = currentLevelTopics
        .filter(topic => searchFilter && searchFilter.length > 0 ? topic.name.toLowerCase().includes(searchFilter.toLowerCase()) : true)
        .filter(topic => categoryFilter ? topic.type === categoryFilter : true)
        .filter(topic => entityFilter ? topic.entityId === entityFilter._id : true)
        .sort((a, b) => (sort ? a.priority - b.priority : b.priority - a.priority))
        .map(el => ({
            ...el,
            entity: allEntities[el.entityId]?.name,
            priority: priorityTypes.find(p => p.key === el.priority)
        }))
        .filter(topic => Boolean(topic.entity));

    const headers = [
        {
            field: 'name',
            headerName: intl.formatMessage({ id: 'NAME' }),
            flex: 1,
            renderCell: (row) => <ExpandedGridCell value={row.value} width={row.colDef.computedWidth} />,
            minWidth: 100
        },
        !queryId && {
            field: 'entity',
            headerName: intl.formatMessage({ id: 'ENTITY' }),
            flex: 1,
            renderCell: (row) => <ExpandedGridCell value={row.value} width={row.colDef.computedWidth} />,
            minWidth: 100
        },
        {
            field: 'type',
            headerName: intl.formatMessage({ id: 'TYPE' }),
            flex: 1,
            valueGetter: (row) => intl.formatMessage({ id: "ESG.TYPE." + row.value }),
            renderCell: (row) => <ExpandedGridCell value={<Stack direction="row" alignContent="center" gap={1}>{getTopicIcon(row.row.type, { color: getTopicColor(row.row.type, true) })} {row.value}</Stack>} width={row.colDef.computedWidth} />,
            minWidth: 50
        },
        {
            field: 'priority',
            headerName: intl.formatMessage({ id: 'PRIORITY' }),
            flex: 1,
            valueGetter: (row) => intl.formatMessage({ id: "PRIORITY." + row.value.value }),
            renderCell: (row) => <ExpandedGridCell value={<Stack direction="row" alignContent="center" gap={1}>{getPriorityIcon(row.row.priority.key)} {row.value}</Stack>} width={row.colDef.computedWidth} />,
            minWidth: 100
        },
        {
            field: 'subtopicsSum',
            headerName: intl.formatMessage({ id: 'ESG.SUB-TOPICS' }),
            flex: 0,
            valueGetter: ({ row }) => row.connections?.childrenArray?.length,
            renderCell: (row) => <ExpandedGridCell value={row.value || <Typography color="text.disabled"><FormattedMessage id="NONE" /></Typography>} width={row.colDef.computedWidth} />,
            minWidth: 50
        },
        {
            field: 'metricsSum',
            headerName: intl.formatMessage({ id: 'ESG.METRICS' }),
            flex: 0,
            valueGetter: ({ row }) => row.metricIds?.length,
            renderCell: (row) => <ExpandedGridCell value={row.value || <Typography color="text.disabled"><FormattedMessage id="NONE" /></Typography>} width={row.colDef.computedWidth} />,
            minWidth: 50
        },
        editTopic && {
            field: 'actions',
            headerName: intl.formatMessage({ id: 'ACTIONS' }),
            type: 'actions',
            getActions: (el) => [
                <Tooltip title={<FormattedMessage id="EDIT" />} placement="bottom" key={'EDIT'} arrow>
                    <IconButton disabled={!editTopic} component="span" color="primary" onClick={() => { setSelectedTopic(el.row); setOpen(true) }}><EditOutlined fontSize="small" /></IconButton>
                </Tooltip>
            ],
            minWidth: 50,
        }
    ].filter(el => el);

    return <Container maxWidth={false} disableGutters sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
        <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
        <TopicForm open={open} onClose={() => setOpen(false)} topic={selectedTopic} parentId={topicId} setAlert={setAlert} selectedNames={currentLevelTopics.map(el => el.name)} />
        <div style={{ display: 'block' }}>
            <BreadcrumbsAndNavigation entity={allEntities[entityId]} baseNavigate="/topics" rootUrl="/topics" titleSuffix={foundTopic && foundTopic.name} topicMode />
            {(!foundTopic || topicView === 'subtopics') && <Grid container item>
                <TopicFilters
                    disabled={!currentLevelTopics?.length}
                    topics={filteredTopics}
                    specialNavigate={foundTopic && foundTopic.connections.parentId && `?topic=${foundTopic.connections.parentId}`}
                    onChangeView={foundTopic && topicView === 'subtopics' ? (view) => setTopicView(view) : undefined}
                    sort={sort} setSort={setSort}
                    searchFilter={searchFilter} setSearchFilter={setSearchFilter}
                    categoryFilter={categoryFilter} setCategoryFilter={setCategoryFilter}
                    entityFilter={!entityId && entityFilter} setEntityFilter={setEntityFilter}
                    newTopicForm={() => { setSelectedTopic(null); setOpen(true); }}
                    editTopic={editTopic} setEditTopics={setEditTopics}
                    tableView={tableView} setTableView={setTableView}
                />
            </Grid>}
        </div>
        {foundTopic && topicView !== 'subtopics'
            ? [
                topicView === 'goals' && <GoalsView key="goals-view" topic={foundTopic} onChangeView={(view) => setTopicView(view)} />,
                topicView === 'metrics' && <SingleTopicView key="metrics-view" topic={foundTopic} onChangeView={(view) => setTopicView(view)} />
            ]
            : (filteredTopics.length
                ? (tableView
                    ? <Grid container item>
                        <Card sx={{ width: '100%' }}>
                            <CardContent>
                                <CustomDataGrid
                                    columns={headers}
                                    rows={filteredTopics.map(el => ({ ...el, id: el._id }))}
                                    handleData={!editTopic ? (({ row }) => navigate(!queryId ? `/topics/${row.entityId}?topic=${row._id}` : '?topic=' + row._id, { state: queryId && !row.connections.childrenArray.length && { topicView: 'metrics' } })) : undefined}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                    : <Virtuoso
                        style={{ flexGrow: 1 }}
                        data={filteredTopics}
                        itemContent={(_, topic) => <TopicCard
                            topic={topic} suffix={!queryId && <span>({topic.entity})</span>}
                            action={editTopic && <IconButton size="small" onClick={(e) => { setSelectedTopic(topic); setOpen(true); }}><EditOutlined /></IconButton>}
                            priority={topic.priority}
                            onClick={!editTopic ? (() => navigate(!queryId ? `/topics/${topic.entityId}?topic=${topic._id}` : '?topic=' + topic._id, { state: queryId && !topic.connections.childrenArray.length && { topicView: 'metrics' } })) : undefined}
                        />}
                    />)
                : <Card sx={{ width: '100%' }}><Alert severity="warning"><FormattedMessage id={`ESG.${topicId ? "SUB" : ""}TOPIC.NOT_FOUND`} /></Alert></Card>)}
    </Container>;
}