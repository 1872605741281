import { useCallback, useEffect, useState } from "react";
import { InsertChartOutlinedOutlined, PreviewOutlined, HistoryOutlined, InfoOutlined, CloseOutlined, EditOutlined, CheckOutlined, TroubleshootOutlined } from "@mui/icons-material";
import { Avatar, CardHeader, Typography, IconButton, Tooltip, Alert, Stack, Box, Divider, useMediaQuery, Badge, Dialog, DialogContent, DialogActions, TextField } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { userSelector, permissionSelector } from "redux/userSlice";
import { selectMeasurementsTypesConf } from "redux/configurationSlice";
import { selectEntities } from "redux/entitySlice";
import { AlertSnackbar, MetricDetailsView, LoadingData, MetricHistoryReportView, MetricValueFormDialog } from 'components';
import { formatDateLocale } from 'utils';
import API from 'api';

export const ValueCell = ({ value, prefix, suffix, subtitle, variant, color, tableCell, fullWidth, customStringWidth, customTitle, qualitative, onValueChange, lastUpdated }) => {
    const intl = useIntl();
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const [openInfo, setOpenInfo] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [editValue, setEditValue] = useState(value + "");
    const updateMetricPermission = useSelector((state) => permissionSelector(state, 'update-metric'));
    const valueString = value + "";

    const stringWidth = customStringWidth || (smallScreen ? 24 : 30);
    const shortStringFlag = valueString.length < stringWidth;
    const cutAdditionalText = ((prefix ? prefix.length : 0) + (valueString.length) + (suffix ? suffix.length : 0)) < stringWidth;

    const handleSubmit = (newValue) => {
        if (newValue) onValueChange(newValue);
        else if (editValue !== valueString) onValueChange(editValue);
        else return;

        setOpenEdit(false);
        setOpenInfo(false);
    };

    useEffect(() => {
        if (!openEdit) setEditValue(value + "");
    }, [openEdit, value]);

    return <>
        {shortStringFlag && <MetricValueFormDialog
            open={openEdit} onClose={() => setOpenEdit(false)}
            value={valueString}
            onSubmit={(newValue) => handleSubmit(newValue)}
        />}
        <Dialog open={openInfo} onClose={() => setOpenInfo(false)} maxWidth="sm" fullWidth fullScreen={smallScreen}>
            <CardHeader title={customTitle || <FormattedMessage id="ESG.METRIC.VALUE" />} subheader={subtitle} action={[
                Boolean(qualitative) && updateMetricPermission ? <IconButton disabled={openEdit && editValue === valueString} key="edit" color={openEdit ? "success" : "default"} onClick={() => openEdit ? handleSubmit() : setOpenEdit(true)}>{openEdit ? <CheckOutlined /> : <EditOutlined />}</IconButton> : null,
                <IconButton key="close" onClick={() => { setOpenInfo(false); setOpenEdit(false); }}><CloseOutlined /></IconButton>,
            ]} />
            <Divider />
            <DialogContent sx={{ wordWrap: 'break-word', overflowX: 'hidden' }}>
                {prefix && <Typography sx={{ pr: 0.5 }} variant="caption" component="span">{prefix}</Typography>}
                {openEdit ? <TextField fullWidth label={intl.formatMessage({ id: "NEW_VALUE" })} value={editValue} onChange={(e) => setEditValue(e.target.value)} multiline /> : <Typography component="span">{valueString}</Typography>}
                {suffix && <Typography sx={{ pl: 0.5 }} variant="caption" component="span">{suffix}</Typography>}
            </DialogContent>
            <Divider />
            {lastUpdated && <DialogActions><Typography variant="caption" color="text.secondary" component="span">
                {formatDateLocale(lastUpdated)}
            </Typography></DialogActions>}
        </Dialog>
        <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
            <Badge sx={{ alignItems: 'flex-end', width: !fullWidth && (tableCell || shortStringFlag) ? 'auto' : '100%' }} invisible={shortStringFlag} badgeContent={<IconButton onClick={(e) => { e.stopPropagation(); setOpenInfo(true) }} size="small" color="info"><InfoOutlined fontSize="small" /></IconButton>}>
                {prefix && <Typography sx={{ pr: 0.5 }} variant="inherit" component="span">{!cutAdditionalText && tableCell ? "..." : prefix}</Typography>}
                <Typography color={color ? color : "success.dark"} variant={tableCell ? "inherit" : (smallScreen ? "h5" : "h4")} component="span" sx={!variant ? (theme => ({ fontWeight: theme.typography.fontWeightRegular })) : undefined}>{!shortStringFlag ? valueString.slice(0, stringWidth) + '...' : valueString}</Typography>
                {suffix && <Typography sx={{ pl: 0.5 }} variant="inherit" component="span">{!tableCell ? suffix : (shortStringFlag && suffix)}</Typography>}
            </Badge>
            {Boolean(qualitative) && updateMetricPermission && shortStringFlag && <IconButton sx={openEdit ? { display: 'none' } : undefined} size="small" onClick={() => setOpenEdit(true)}><EditOutlined fontSize="small" /></IconButton>}
        </Box>
    </>

}

export const ReportDetails = ({ value, reportUnit, reportData, additionalInfo, calculationMethod, qualitative, onValueChange }) => {
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    return <Stack direction="column" spacing={1}>
        <Divider><Typography color="secondary.main" variant="caption" sx={{ textTransform: 'uppercase' }}><FormattedMessage id="MEASUREMENTS.LAST_VALUE" /></Typography></Divider>
        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 2, mb: 1 }}>
            {value?.value !== undefined && value?.value !== null
                ? <>
                    <ValueCell
                        qualitative={qualitative}
                        prefix={reportUnit?.prefix}
                        value={value.value?.description ? value.value.description : value.value}
                        suffix={reportUnit ? `${reportUnit.unit && calculationMethod !== "count" ? reportUnit?.unit : (value.unit && calculationMethod !== "count" ? value.unit : "")} ${reportUnit.suffix || ""}` : calculationMethod !== "count" ? value.unit : ""}
                        lastUpdated={reportData?.lastUpdated}
                        onValueChange={onValueChange}
                    />
                </>
                : <Typography color="text.disabled" variant={smallScreen ? "h4" : "h2"} display="inline" sx={theme => ({ fontWeight: theme.typography.fontWeightRegular })}>N/A</Typography>}
        </Box>

        <Divider><Typography variant="caption" color="secondary.main" sx={{ textTransform: 'uppercase' }}><FormattedMessage id="CURRENT_PERIOD" /></Typography></Divider>
        <>
            <Typography color="primary" variant={smallScreen ? "subtitle1" : "body2"}><FormattedMessage id="DATE_FROM" /></Typography>
            {formatDateLocale(reportData?.dateFrom) ? <Typography variant={smallScreen ? "body2" : "body1"}>{formatDateLocale(reportData.dateFrom)}</Typography> : <Typography variant="button" color="text.disabled">N/A</Typography>}
        </>
        <>
            <Typography color="primary" variant={smallScreen ? "subtitle1" : "body2"}><FormattedMessage id="DATE_TO" /></Typography>
            {formatDateLocale(reportData?.dateTo) ? <Typography variant={smallScreen ? "body2" : "body1"}>{formatDateLocale(reportData.dateTo)}</Typography> : <Typography variant="button" color="text.disabled">N/A</Typography>}
        </>
        <>
            <Typography color="primary" variant={smallScreen ? "subtitle1" : "body2"}><FormattedMessage id="LAST_UPDATED" /></Typography>
            {formatDateLocale(reportData?.lastUpdated) ? <Typography variant={smallScreen ? "body2" : "body1"}>{formatDateLocale(reportData.lastUpdated)}</Typography> : <Typography variant="button" color="text.disabled">N/A</Typography>}
        </>

        <Divider><Typography color="secondary.main" variant="caption" sx={{ textTransform: 'uppercase' }}><FormattedMessage id="ESG.METRIC.DETAILS" /></Typography></Divider>
        <>
            <Typography color="primary" variant={smallScreen ? "subtitle1" : "body2"}><FormattedMessage id="COLLECTOR" /></Typography>
            {reportData?.lastUpdatedBy ? <Typography variant={smallScreen ? "body2" : "body1"}>{reportData.lastUpdatedBy}</Typography> : <Typography variant="button" color="text.disabled">N/A</Typography>}
        </>
        <>
            <Typography color="primary" variant={smallScreen ? "subtitle1" : "body2"}><FormattedMessage id="ENTITY" /></Typography>
            {reportData?.entity ? <Typography variant={smallScreen ? "body2" : "body1"}>{reportData.entity?.name}</Typography> : <Typography variant="button" color="text.disabled">N/A</Typography>}
        </>
    </Stack>
}

export default function MetricReportDetailCard({ metric, entity, complex, onClose, disableView, noHistoryEdit, onUpdate }) {
    const { token } = useSelector(userSelector);
    const [open, setOpen] = useState(false);
    const [openHistoryList, setOpenHistoryList] = useState(false);
    const [loading, setLoading] = useState(true);
    const measurementTypes = useSelector(selectMeasurementsTypesConf);
    const allEntities = useSelector(selectEntities);

    const [latestReport, setLatestReport] = useState(null);
    const [alert, setAlert] = useState({ open: false });

    const onAlertClose = () => setAlert({ ...alert, open: false });
    useEffect(() => {
        setLoading(true);
        if (metric?.metricId?._id) API.metricReports.getLatestMetricReports(token, [metric.metricId._id]).then(items => {
            if (items.data && items.data.length > 0) {
                const item = items.data[0];
                setLatestReport({ ...item, metricId: item.metricId, ...item.reportData, entity: allEntities[item?.metricId?.entityId] });
            }
            else setLatestReport(null);
            setLoading(false);
        }).catch(error => {
            setLatestReport({ error });
            console.error(error);
            setLoading(false);
        })
        else {
            setLatestReport(null);
            setLoading(false);
        }
    }, [metric, allEntities, token]);

    const onValueChange = useCallback((newValue, id) => {
        API.metricReports.putMetricReport(token, id, { value: newValue }).then(({ data }) => {
            if (data) setLatestReport(old => ({ ...old, ...data, metricId: old.metricId }));
            else setLatestReport(old => ({ ...old, value: newValue }));
            if (typeof onUpdate === 'function') onUpdate({ _id: id, value: newValue })
            setAlert({ open: true, messageId: "SUCCESS.UPDATE", severity: "success" });
        }).catch((error) => {
            console.error("Error updating metric report, reason:", error);
            setAlert({ open: true, messageId: error?.data?.id || "ERROR.FETCHING_DATA", severity: "error" });
        })
    }, [token, onUpdate]);

    const renderView = useCallback(() => {
        if (loading) return <LoadingData />;
        else if (!latestReport) return <Alert severity="warning"><FormattedMessage id="NO_DATA" /></Alert>;
        else if (latestReport?.error) return <Alert severity="error"><FormattedMessage id="ERROR.NO_DATA" /></Alert>;
        else return <ReportDetails qualitative={latestReport.metricId?.category?.type === "Qualitative"} onValueChange={(value) => onValueChange(value, latestReport._id)} value={{ value: latestReport.value, unit: latestReport.metricId.category?.scope?.unit }} calculationMethod={latestReport.metricId.reportData?.calculationMethod} reportUnit={latestReport.metricId.reportData?.reportUnit} reportData={{ ...latestReport, ...latestReport.datetime }} />;
    }, [loading, latestReport, onValueChange]);

    return metric ? <>
        <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
        {!disableView && <MetricDetailsView metricId={metric.metricId?._id} openDialog={open} onClose={() => setOpen(false)} complex={complex} disableReport />}
        <MetricHistoryReportView
            noHistoryEdit={noHistoryEdit}
            metricId={metric.metricId?._id}
            openDialog={openHistoryList}
            onCloseHandler={() => setOpenHistoryList(!openHistoryList)}
            onUpdateValue={(newData) => setLatestReport(old => ({ ...old, value: newData.value, datetime: newData.datetime }))}
            entityName={entity?.name}
            reportName={latestReport?.metricId?.name}
            type={latestReport?.metricId?.type}
            metricModel={latestReport?.metricModel}
            qualitative={latestReport?.metricId?.category?.type === "Qualitative"}
            label={latestReport && measurementTypes.find(el => el.key === latestReport.metricId?.category?.scope?.measurementTypeKey)}
        />
        <CardHeader
            title={<Typography variant={"h6"}>{metric.name}</Typography>}
            subheader={<span><FormattedMessage id="LATEST_REPORT" /> {entity && `(${entity.name})`}</span>}
            avatar={latestReport ? <Avatar sx={{ bgcolor: 'primary.main' }}>{latestReport?.metricId?.category?.type === "Qualitative" ? <TroubleshootOutlined /> : <InsertChartOutlinedOutlined />}</Avatar> : undefined}
            action={[!disableView && <Tooltip title={<FormattedMessage id="ESG.METRIC.VIEW" />} placement="bottom" key={'VIEW'} arrow>
                <span><IconButton color="primary" onClick={() => setOpen(true)}><PreviewOutlined /></IconButton></span>
            </Tooltip>,
            <Tooltip title={<FormattedMessage id="ESG.METRIC.PREVIOUS_REPORTS_VIEW" />} placement="bottom" key={'PREVIOUS_VIEW'} arrow>
                <span><IconButton color="primary" onClick={() => setOpenHistoryList(true)}><HistoryOutlined /></IconButton></span>
            </Tooltip>,
            <IconButton key="close" onClick={onClose}><CloseOutlined /></IconButton>
            ]}
        />
        <DialogContent>
            {renderView()}
        </DialogContent>
    </> : null
}