import React, { useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectUnits } from 'redux/configurationSlice';
import { FormControl, FormLabel, Autocomplete, Stack, TextField, useTheme, useMediaQuery } from '@mui/material';
import { styled, lighten, darken } from '@mui/system';
import { findUnitFamily } from 'utils';

export const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    display: 'flex',
    justifyContent: 'center',
    zIndex: 1,
    color: theme.palette.primary.main,
    backgroundColor:
        theme.palette.mode === 'light'
            ? lighten(theme.palette.primary.light, 0.85)
            : darken(theme.palette.primary.main, 0.8),
}));

export const GroupItems = styled('ul')({
    padding: 0,
    zIndex: 0
});

const UnitSelectorField = ({ setEditMode, unit, setUnit, measurementType, disabled, noLabel, required, selectedSimpleMetrics, hideEmptyInput, helperText }) => {
    const intl = useIntl();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const units = useSelector(selectUnits);
    const [groupedUnits, setGroupedUnits] = useState(null);

    useEffect(() => {
        if (measurementType && measurementType.dataType.measure) setGroupedUnits(units[measurementType.dataType.measure].map(el => ({ name: el, family: measurementType.dataType.measure })))
        else if (measurementType && !measurementType.dataType?.measure) setGroupedUnits([]);
        else if (selectedSimpleMetrics?.length) {
            const unitFamily = selectedSimpleMetrics[0].category.scope.unit ? findUnitFamily(selectedSimpleMetrics[0].category.scope.unit, units) : findUnitFamily(selectedSimpleMetrics[0].reportData?.reportUnit?.unit, units);
            if (!unitFamily) setGroupedUnits([]);
            else setGroupedUnits(units[unitFamily].map(el => ({ name: el, family: unitFamily })));
        }
        else setGroupedUnits(Object.entries(units).flatMap(([key, value]) => value.map(unit => ({ name: unit, family: key }))));
    }, [groupedUnits, units, measurementType, selectedSimpleMetrics]);

    if (groupedUnits === null || (!groupedUnits.length && hideEmptyInput)) return null;

    return <FormControl sx={{ flex: 1 }}>
        {!noLabel && <FormLabel required={!!groupedUnits.length && required}><FormattedMessage id="UNIT" /></FormLabel>}
        <Stack direction={smallScreen ? "column" : "row"} spacing={1}>
            <Autocomplete
                disabled={disabled || !groupedUnits.length}
                id="select-unit"
                options={groupedUnits}
                getOptionLabel={(option) => option?.name ? option.name : ""}
                groupBy={(option) => option.family}
                value={unit}
                onChange={(e, unit, reason) => {
                    setEditMode(true);
                    if (reason === "clear") setUnit("");
                    else setUnit(unit);
                    setEditMode(true)
                }}
                renderInput={(params) => {
                    const unitString = intl.formatMessage({ id: "UNIT" });
                    const placeholder = required ? unitString + " *" : unitString;
                    return <TextField {...params} placeholder={noLabel && placeholder} helperText={!(disabled || !groupedUnits.length) && helperText} />
                }}
                renderGroup={(params) => (
                    <li key={params.key}>
                        <GroupHeader>{intl.formatMessage({ id: params.group })}</GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                    </li>
                )}
                isOptionEqualToValue={(option, value) => value === "" || !value.name || option.name === value.name}
                size="small"
                fullWidth
            />
        </Stack>
    </FormControl>


}

export default UnitSelectorField;