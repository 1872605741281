import { DateRangeSelector } from 'components';
import { FormattedMessage, useIntl } from 'react-intl';
import { Autocomplete, Box, Checkbox, FormControlLabel, TextField, useMediaQuery } from '@mui/material';
import { selectSeverityTypes } from 'redux/configurationSlice';
import { useSelector } from 'react-redux';

const AlarmFilters = ({ severity, active, acknowledged, cleared, setSeverity, setActive, setAcknowledged, setCleared }) => {
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
    const mediumScreen = useMediaQuery(theme => theme.breakpoints.down("xl"));
    const severityTypes = useSelector(selectSeverityTypes);
    const intl = useIntl();

    return (
        <Box>
            <Box sx={{ width: !mediumScreen ? '80%' : '100%' }}>
                <DateRangeSelector direction={smallScreen ? "column" : "row"} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: smallScreen ? "column" : "row", gap: '8px', mt: 2 }}>
                <Autocomplete
                    multiple
                    size="small"
                    limitTags={!smallScreen ? undefined : 3}
                    disableCloseOnSelect
                    id="multiple-limit-tags"
                    sx={{ width: !mediumScreen ? '70%' : '100%' }}
                    options={severityTypes}
                    getOptionLabel={option => intl.formatMessage({ id: "ALARMS.PROFILE_RULES." + option.value })}
                    value={severity}
                    isOptionEqualToValue={(option, value) => option?.key === value?.key}
                    renderInput={(params) => (
                        <TextField {...params} label={<FormattedMessage id={"SEVERITY"} />} />
                    )}
                    onChange={(e, newValue) => setSeverity(newValue.map(el => el.key))}
                />

                <Box sx={{ display: 'flex', flexDirection: smallScreen ? 'column' : 'row', gap: smallScreen ? '4px' : '8px' }}>
                    <FormControlLabel
                        control={<Checkbox size="small" checked={active} onChange={(e, newValue) => {
                            setActive(newValue);
                            if (cleared) {
                                setCleared(false); setAcknowledged(false);
                            }
                            else if (acknowledged) setAcknowledged(false);
                        }} />}
                        label={<FormattedMessage id="ACTIVE" />}
                    />
                    <FormControlLabel
                        control={<Checkbox size="small" checked={acknowledged} onChange={(e, newValue) => {
                            setAcknowledged(newValue);
                            if (active) setActive(false);
                        }} />}
                        label={<FormattedMessage id="ACKNOWLEDGED" />}
                    />
                    <FormControlLabel
                        control={<Checkbox size="small" checked={cleared} onChange={(e, newValue) => {
                            setCleared(newValue); setAcknowledged(newValue);
                            if (active) setActive(false);
                        }} />}
                        label={<FormattedMessage id="CLEARED" />}
                    />
                </Box>
            </Box>
        </Box>
    )

}
export default AlarmFilters;