import { Box, Button, ButtonGroup } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { permissionSelector } from "redux/userSlice";
import { GoToButton } from 'components';
import { selectFeaturesConfiguration } from "redux/configurationSlice";
import { AttachFileOutlined, DriveFileRenameOutlineOutlined, LoupeOutlined } from "@mui/icons-material";


export default function MeasurementEntryLinks({ selected, single, file, metric, customAction }) {
    const navigate = useNavigate();
    const createMeasurementPermission = useSelector((state) => permissionSelector(state, 'create-measurement'));
    const createStagingMeasurementPermission = useSelector((state) => permissionSelector(state, 'create-staging-measurement'));
    const updateMetricPermission = useSelector((state) => permissionSelector(state, 'update-metric'));
    const viewMetricPermission = useSelector((state) => permissionSelector(state, 'view-metric'));
    const createStagingMetricPermission = useSelector((state) => permissionSelector(state, 'create-staging-metricValue'));
    const featuresConfiguration = useSelector(selectFeaturesConfiguration);

    return <Box component="span" sx={{ display: 'flex', mt: 1 }}>
        {(createMeasurementPermission || createStagingMeasurementPermission) && <ButtonGroup size="small" sx={{ mr: 0.5 }}>
            <Button startIcon={<DriveFileRenameOutlineOutlined />} variant={single ? "contained" : "outlined"} onClick={!single ? () => navigate('/measurementsEntry', { ...(!metric && selected && { state: selected }) }) : undefined}>
                <FormattedMessage id="MANUAL" />
            </Button>
            <Button startIcon={<AttachFileOutlined />} variant={file ? "contained" : "outlined"} onClick={!file ? () => navigate('/measurementsEntry/uploadFile', { ...(!metric && selected && { state: selected }) }) : undefined}>
                <FormattedMessage id="FILE" />
            </Button>
        </ButtonGroup>}
        {viewMetricPermission && (updateMetricPermission || (createStagingMetricPermission && featuresConfiguration.stagingData?.enabled === true)) && <Button startIcon={<LoupeOutlined />} size="small" variant={metric ? "contained" : "outlined"} onClick={!metric ? () => navigate('/measurementsEntry/metrics') : undefined}>
            <FormattedMessage id="ESG.METRICS" />
        </Button>}
        {metric && viewMetricPermission && <GoToButton disableConfirmation={!selected} sx={{ ml: 1 }} onClick={() => navigate('/metrics')} label={<FormattedMessage id="ESG.METRIC.MANAGMENT" />} />}
    </Box>
}