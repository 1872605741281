import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomDataGrid, AlertSnackbar, ExpandedGridCell, ConfirmationDialog, LoadingData, MetricReportTable } from 'components';
import { Alert, Autocomplete, Button, Card, CardContent, CardHeader, Dialog, DialogActions, DialogContent, Divider, Grid, IconButton, InputAdornment, List, ListItem, ListItemText, Stack, TextField, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { generateYearsBetween, formatDateLocale } from 'utils';
import { selectEntities } from 'redux/entitySlice';
import { userSelector, permissionSelector } from 'redux/userSlice';
import { selectPriorityTypes } from 'redux/configurationSlice';
import { ArchiveOutlined, CloseOutlined, OpenInNewOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { getPriorityIcon } from 'components/topicsView/TopicsView';
import ESRSReportButton from 'components/ESRSReportButton/ESRSReportButton';
import API from 'api';

const TitleComponent = ({ topic, entity }) => {
  return <Typography component="span" variant="h5">
    {topic?.name}
    <Typography component="span" variant='caption' color="text.secondary" sx={{ pl: 1 }}>
      {entity?.name}
    </Typography>
  </Typography>
}

const ReportsView = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"))
  const { token } = useSelector(userSelector);
  const allEntities = useSelector(selectEntities);
  const priorityTypes = useSelector(selectPriorityTypes);

  const [topicInfo, setTopicInfo] = useState(false);
  const [metricData, setMetricData] = useState([]);
  const [loadingTopic, setLoadingTopic] = useState(true);
  const [archiving, setArchiving] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [loading, setLoading] = useState(true);
  const [reports, setReports] = useState([])
  const [year, setYear] = useState(new Date().getFullYear().toString())
  const CURRENT_YEAR = new Date().getFullYear();
  const [alert, setAlert] = useState({ open: false });

  const exportPermission = useSelector(state => permissionSelector(state, 'export-reports'));
  const viewMetricPermission = useSelector((state) => permissionSelector(state, 'view-metric'));


  useEffect(() => {
    setLoading(true)
    API.reports.getAllReports(token).then(response => {
      if (response.data) {
        setReports(response.data.map(item => ({ ...item, id: item._id, entity: allEntities[item.entityId]?.name, numberOfMetrics: item.metricIds?.length, priority: priorityTypes.find(p => p.key === item.priority) })))
      }
      else setReports([])
      setLoading(false)
    }).catch(error => {
      setReports({ error: error });
      setLoading(false)
    })
  }, [token, allEntities, year, intl, priorityTypes]);

  useEffect(() => {
    if (topicInfo?.metricIds?.length) {
      setLoadingTopic(true);
      API.metricReports.getLatestMetricReports(token, topicInfo.metricIds).then(res => {
        setMetricData(res.data?.map(el => {
          const latest = el || {};
          const isComplex = latest?.metricId.metricId !== undefined;
          return {
            id: latest._id,
            complex: isComplex,
            lastUpdated: formatDateLocale(latest.datetime?.lastUpdated || ""),
            unit: isComplex ? latest?.metricId.metricId[0]?.category.scope.unit : latest?.metricId?.category.scope?.unit,
            ...(latest.metricId?.reportData?.reportUnit?.unit && latest.metricId.reportData.reportUnit),
            ...latest,
            reportName: latest.name,
            name: latest.metricId.name,
          }
        }) || []);
        setLoadingTopic(false);
      }).catch(error => {
        console.error(error);
        setLoadingTopic(false);
      });
    }
    else {
      setMetricData([]);
      setLoadingTopic(false);
    }
  }, [token, topicInfo]);

  const renderTable = useCallback(() => {

    const headers = [
      {
        field: "name",
        headerName: intl.formatMessage({ id: "NAME" }),
        minWidth: 200
      },
      {
        field: "description",
        headerName: intl.formatMessage({ id: "DESCRIPTION" }),
        minWidth: 300,
        flex: 1
      },
      {
        field: "entity",
        headerName: intl.formatMessage({ id: "ENTITY" }),
        minWidth: 200
      },
      {
        field: 'type',
        headerName: intl.formatMessage({ id: 'ESG.METRIC.CATEGORY' }),
        valueGetter: ({ value }) => intl.formatMessage({ id: "ESG.TYPE." + value }),
        minWidth: 150
      },
      {
        field: 'priority',
        headerName: intl.formatMessage({ id: 'PRIORITY' }),
        valueGetter: ({ value }) => intl.formatMessage({ id: "PRIORITY." + value.value }),
        renderCell: (row) => <ExpandedGridCell value={<Stack direction="row" alignContent="center" gap={1}>{getPriorityIcon(row.row.priority.key)} {row.value}</Stack>} width={row.colDef.computedWidth} />,
        flex: 1,
        minWidth: 150
      },
      {
        field: "numberOfMetrics",
        headerName: intl.formatMessage({ id: "ESG.METRICS" }),
        minWidth: 50
      },
      {
        field: 'actions',
        headerName: intl.formatMessage({ id: 'ESRS_REPORT' }),
        type: 'actions',
        minWidth: 200,
        flex: 1,
        getActions: (el) => [
          <ESRSReportButton key="report" disabled={!exportPermission} entity={el.row} type={el.row.type} year={year} setAlert={setAlert} color="primary" />,
        ]
      }
    ]

    if (reports?.length && reports.some(el => el.archivedYears.includes(Number(year)))) headers.splice(0, 0, {
      field: "archived",
      type: 'boolean',
      headerName: intl.formatMessage({ id: "NOTE.ARCHIVED" }),
      valueGetter: (row) => row.row.archivedYears?.length,
    });

    if (reports?.error) return <Alert severity='error'><FormattedMessage id="ERROR.FETCHING_DATA" /></Alert>;
    else return <CustomDataGrid isLoading={loading} rows={reports} columns={headers} handleData={viewMetricPermission ? ({ row }) => setTopicInfo(row) : undefined} />

  }, [intl, reports, loading, year, exportPermission, viewMetricPermission]);


  const archiveReports = () => {
    setArchiving(true);
    API.reports.archiveReports(token, year).then(res => {
      if (res.data) {
        let arhivedReports = res.data.map(el => el.topicId);
        setReports(old => old.map(report => ({
          ...report,
          archivedYears: arhivedReports.includes(report._id) ? [...report.archivedYears, Number(year)] : report.archivedYears
        })));
      }
      setOpenConfirmation(false);
      setArchiving(false);
      setAlert({ open: true, messageId: "SUCCESS.ARCHIVING_DATA", severity: "success" });
    }).catch(error => {
      console.error(error);
      setArchiving(false);
      setAlert({ open: true, messageId: error?.data?.id || "ERROR.ARCHIVING_DATA", severity: "error" });
    })
  };


  return <Grid container direction="column">
    <ConfirmationDialog
      maxWidth="sm" fullWidth
      open={openConfirmation}
      content={<div>
        <Typography color="secondary"><FormattedMessage id="REPORTS_TO_ARCHIVE" /></Typography>
        <List dense>
          {reports?.length > 0 && reports.filter(el => !el.archivedYears.includes(Number(year))).map((el, i) => (
            <ListItem key={el.name + i}>
              <Typography component="span" color="text.secondary" sx={{ pr: 1 }}>{i + 1}.</Typography>
              <ListItemText primary={el.name} secondary={el.entity} />
            </ListItem>
          ))}
        </List>
      </div>}
      disabled={archiving}
      disableClickHandler
      title={<span><FormattedMessage id="NOTE.ARCHIVE" /> <span style={{ textTransform: 'lowercase' }}><FormattedMessage id="YEAR_AKUZATIV" /></span> {year}?</span>}
      customButtonTitle={<FormattedMessage id="YES" />}
      customButtonColor="primary"
      handleCancel={() => setOpenConfirmation(false)}
      handleCustomButton={archiveReports}
    />
    <Dialog open={Boolean(topicInfo)} onClose={() => setTopicInfo(false)} maxWidth="md" fullWidth={true} fullScreen={smallScreen}>
      <CardHeader
        title={<TitleComponent topic={topicInfo} entity={allEntities[topicInfo?.entityId]} />}
        subheader={<FormattedMessage id="ESG.METRIC.OVERVIEW" />}
        avatar={getPriorityIcon(topicInfo?.priority?.key)}
        action={[
          topicInfo ? <Tooltip key="go_to" title={<span><FormattedMessage id="GO_TO" /> {topicInfo.name}</span>} arrow>
            <IconButton component="span" size="small" onClick={() => navigate('/topics/' + topicInfo.entityId + '?topic=' + topicInfo.id)}><OpenInNewOutlined /></IconButton>
          </Tooltip> : null,
          <IconButton key="close" size="small" onClick={() => setTopicInfo(false)}><CloseOutlined /></IconButton>
        ]} />
      <Divider />
      <DialogContent>
        {loadingTopic ? <LoadingData /> : <MetricReportTable data={metricData} complexMetrics />}
      </DialogContent>
      <Divider />
      <DialogActions>{!loadingTopic && topicInfo && <ESRSReportButton variant="button" disabled={!exportPermission} entity={topicInfo} type={topicInfo.type} year={year} setAlert={setAlert} color="primary" />}</DialogActions>
    </Dialog>
    <Card sx={{ width: '100%' }}>
      <CardHeader title={<FormattedMessage id="REPORTS" />} />
      <CardContent>
        <AlertSnackbar open={alert.open} onClose={() => setAlert({ ...alert, open: false })} severity={alert.severity} messageId={alert.messageId} />
        <Stack direction="column" spacing={2}>
          <Stack direction="row" spacing={1}>
            <Autocomplete
              id="set-date-prefix"
              value={year} onChange={(e, newValue) => {
                setYear(newValue || new Date().getFullYear().toString())
              }}
              options={generateYearsBetween(2020, CURRENT_YEAR + 10)}
              size="small"
              fullWidth
              renderInput={(params) => (
                <TextField {...params} sx={smallScreen ? { mt: 0.5 } : undefined} label={smallScreen ? <FormattedMessage id="DATE.TOPIC_REPORT_PREFIX" /> : undefined} InputProps={{ ...params.InputProps, startAdornment: <InputAdornment sx={{ mx: 0, pl: 0.5, ...(smallScreen && { display: 'none' }) }} position="start"><FormattedMessage id="DATE.TOPIC_REPORT_PREFIX" /></InputAdornment> }} />
              )}
            />
            {reports?.length > 0 && <Button onClick={() => setOpenConfirmation(true)} disabled={reports.every(el => el.archivedYears.includes(Number(year)))} startIcon={<ArchiveOutlined />}><FormattedMessage id="NOTE.ARCHIVE" /></Button>}
          </Stack>
          {renderTable()}
        </Stack>
      </CardContent>
    </Card>
  </Grid >
}

export default ReportsView