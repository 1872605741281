import { DefaultLayout } from 'layouts';
import { useSelector } from 'react-redux';
import { userSelector, permissionSelector } from 'redux/userSlice';
import { SplashPage } from "pages";
import { EmptyState } from 'layouts';
import { MetricsEntry } from 'components';
import { selectFeaturesConfiguration } from 'redux/configurationSlice';

const MetricsEntryPage = () => {
    return <EmptyState component={<MetricsEntry />} />;
}

const MetricsEntryPageComponent = () => {
    const { isSuccess: loggedIn } = useSelector(userSelector);
    const updateMetricPermission = useSelector((state) => permissionSelector(state, 'update-metric'));
    const createStagingMetricPermission = useSelector((state) => permissionSelector(state, 'create-staging-metricValue'));
    const viewMetricPermission = useSelector((state) => permissionSelector(state, 'view-metric'));
    const featuresConfiguration = useSelector(selectFeaturesConfiguration);

    if (loggedIn && (updateMetricPermission || (createStagingMetricPermission && featuresConfiguration.stagingData?.enabled === true)) && viewMetricPermission) return <DefaultLayout page={<MetricsEntryPage />} />;
    else return <SplashPage />;
};

export default MetricsEntryPageComponent;