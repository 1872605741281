import { Button, Card, CardContent, CardHeader, Chip, Dialog, DialogContent, Divider, IconButton, List, ListItem, ListItemIcon, ListItemText, Stack, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { AddOutlined, CloseOutlined, DeleteOutlineOutlined, EditOutlined, CancelOutlined } from "@mui/icons-material";
import { CustomDataGrid, ExpandedGridCell, AlertSnackbar, StakeholderForm, ConfirmationDialog, DisplayBox } from "components";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { userSelector, permissionSelector } from "redux/userSlice";
import API from 'api';

export const CompactList = ({ data }) => data ? <List>
    {data.map((el, i) => <ListItem disablePadding key={el}>
        <ListItemIcon sx={{ pl: 2.5 }}>{i + 1}.</ListItemIcon><ListItemText primary={el} />
    </ListItem>)}
</List> : null;

function StakeholderDetails({ data, open, onClose }) {
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));

    return <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth fullScreen={smallScreen}>
        {data && <>
            <CardHeader title={<FormattedMessage id="STAKEHOLDER_DETAILS" />} subheader={data?.name} action={<IconButton size="small" onClick={onClose}><CloseOutlined /></IconButton>} />
            <Divider />
            <DialogContent>
                <Stack direction="column" gap={2}>
                    <DisplayBox label={<FormattedMessage id="ENGAGEMENT" />} value={data?.engagement?.length && <CompactList data={data.engagement} />} />
                    <DisplayBox label={<FormattedMessage id="CONCERNS_AND_TOPICS" />} value={data?.concernsAndTopics?.length && <CompactList data={data.concernsAndTopics} />} />
                </Stack>
            </DialogContent>
        </>}
    </Dialog>
}


export default function StakeholdersView(props) {
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const intl = useIntl();
    const { token } = useSelector(userSelector);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ open: false });
    const [openForm, setOpenForm] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [openDetails, setOpenDetails] = useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const [reset, setReset] = useState(0);

    const createStakeholdersPermission = useSelector((state) => permissionSelector(state, 'create-stakeholders'))
    const updateStakeholdersPermission = useSelector((state) => permissionSelector(state, 'update-stakeholders'))
    const deleteStakeholdersPermission = useSelector((state) => permissionSelector(state, 'delete-stakeholders'))

    useEffect(() => {
        setLoading(true);
        API.stakeholder.getAll(token).then(({ data }) => {
            if (data) setData(data.map(el => ({
                id: el._id,
                engagement: el.engagementExamples || [],
                concernsAndTopics: el.raisedConcerns || [],
                ...el
            })));
            else setData([]);
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setAlert({ open: true, messageId: error?.data?.id || "ERROR.FETCHING_DATA", severity: "error" });
            setData([]);
            setLoading(false);
        })
    }, [token, reset]);

    const headers = [
        {
            field: 'name',
            headerName: intl.formatMessage({ id: 'NAME' }),
            flex: 1,
            renderCell: (row) => <ExpandedGridCell value={row.value} width={row.colDef.computedWidth} />,
            minWidth: 100
        },

        {
            field: 'engagement',
            type: 'array',
            headerName: intl.formatMessage({ id: 'ENGAGEMENT' }),
            flex: 1,
            renderCell: (row) => row.value.length ? <ExpandedGridCell list value={row.value} width={row.colDef.computedWidth} /> : <Typography color="text.disabled"><FormattedMessage id="NO_DATA" /></Typography>,
            minWidth: 100
        },

        {
            field: 'concernsAndTopics',
            type: 'array',
            headerName: intl.formatMessage({ id: 'CONCERNS_AND_TOPICS' }),
            flex: 1,
            renderCell: (row) => row.value.length ? <ExpandedGridCell list value={row.value} width={row.colDef.computedWidth} /> : <Typography color="text.disabled"><FormattedMessage id="NO_DATA" /></Typography>,
            minWidth: 100
        },

        {
            field: 'actions',
            type: 'actions',
            headerName: intl.formatMessage({ id: 'ACTIONS' }),
            flex: 0,
            renderCell: (row) => (updateStakeholdersPermission || deleteStakeholdersPermission) ? [
                <Tooltip key="edit" title={<FormattedMessage id="STAKEHOLDER.EDIT" />} placement="bottom" arrow>
                    <IconButton disabled={!updateStakeholdersPermission} onClick={() => { setSelectedData(row.row); setOpenForm(true); }} size="small" color="secondary" key="edit"><EditOutlined fontSize="small"/></IconButton>
                </Tooltip>,
                <Tooltip key="delete" title={<FormattedMessage id="STAKEHOLDER.DELETE" />} placement="bottom" arrow>
                    <IconButton disabled={!deleteStakeholdersPermission} onClick={() => { setSelectedData(row.row); setOpenConfirmDialog(true); }} size="small" color="error" key="delete"><DeleteOutlineOutlined fontSize="small"/></IconButton>
                </Tooltip>
            ]
                :
                [smallScreen ? <IconButton key="disabled-actions" disabled><CancelOutlined fontSize="small" /></IconButton> : <Button key="disabled-actions" disabled><FormattedMessage id="DISABLED" /></Button>],
            minWidth: 150
        }
    ];

    const onDialogClose = () => { setOpenDetails(false); setOpenConfirmDialog(false); setOpenForm(false); setSelectedData(null); }

    const onDelete = () => {
        if (deleteStakeholdersPermission) {
            API.stakeholder.deleteOne(token, selectedData._id).then(() => {
                setAlert({ open: true, messageId: "SUCCESS.DELETE", severity: "success" });
                setReset(r => r + 1);
                onDialogClose();
            }).catch(error => {
                console.error(error);
                setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_DELETED", severity: "error" });
            })
        }
    }

    return <Card sx={{ width: '100%' }}>
        <ConfirmationDialog
            open={openConfirmDialog}
            title={!selectedData ? null : <span><FormattedMessage id="CONFIRM_DELETE_FOR" /> {selectedData.name}</span>}
            content={<Typography variant="h6" color="error"><FormattedMessage id="DELETE_WARNING" /></Typography>}
            handleCancel={onDialogClose}
            handleCustomButton={onDelete}
            customButtonTitle={<FormattedMessage id="DELETE" />}
            customButtonColor='error'
        />
        <Dialog open={openForm} maxWidth="sm" fullWidth fullScreen={smallScreen}>
            <StakeholderForm stakeholder={selectedData} onCancel={onDialogClose} setReset={() => setReset(r => r + 1)} />
        </Dialog>
        <StakeholderDetails data={selectedData} open={openDetails} onClose={onDialogClose} />
        <AlertSnackbar open={alert.open} onClose={() => setAlert({ ...alert, open: false })} severity={alert.severity} messageId={alert.messageId} />
        <CardHeader title={<FormattedMessage id="STAKEHOLDERS" />} action={<Chip disabled={!createStakeholdersPermission} onClick={() => setOpenForm(true)} label={<FormattedMessage id="STAKEHOLDER.ADD" />} icon={<AddOutlined color="primary" />} variant="outlined" />} />
        <CardContent>
            <CustomDataGrid isLoading={loading} rows={data} columns={headers} handleData={(row) => { setSelectedData(row.row); setOpenDetails(true); }} />
        </CardContent>
    </Card>;
}