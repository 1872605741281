import { Typography } from "@mui/material";
import CustomDataGrid from "components/customDataGrid/CustomdataGrid";
import ExpandedGridCell from "components/customDataGrid/ExpandedGridCell";
import { ValueCell } from "components/metricReportDetailCard/MetricReportDetailCard";
import { FormattedMessage, useIntl } from "react-intl";


const NoDataCell = () => <Typography variant="inherit" color="text.disabled" component="span"><FormattedMessage id="NO_DATA" /></Typography>;

export default function MetricReportTable({ data, handleRowClick, tableActions, complexMetrics }) {
    const intl = useIntl();

    const headers = [
        {
            field: 'name',
            headerName: intl.formatMessage({ id: 'NAME' }),
            renderCell: (row) => <ExpandedGridCell value={row.value} width={row.colDef.computedWidth} />,
            minWidth: 100,
            flex: 2,
        },
        {
            field: 'lastUpdated',
            headerName: intl.formatMessage({ id: 'LATEST_REPORT' }),
            renderCell: (row) => <ExpandedGridCell value={row.value || <NoDataCell />} width={row.colDef.computedWidth} />,
            minWidth: 100,
            flex: 1,
        },
        {
            field: 'value',
            headerName: intl.formatMessage({ id: 'MEASUREMENTS.LAST_VALUE' }),
            valueGetter: (row) => row.value?.description ? row.value.description : row.value,
            renderCell: (row) => row.value !== null && row.value !== undefined ? <ValueCell tableCell variant="inherit" color="inherit" subtitle={row.row.name} prefix={row.row?.prefix} value={row.value} suffix={`${row.row.metricId?.reportData?.calculationMethod !== "count" ? (row.row?.unit || "") : ""} ${row.row?.suffix || ""}`} /> : <NoDataCell />,
            minWidth: 200,
            flex: 1,
        },
    ];

    if (tableActions) headers.push({
        field: 'actions',
        headerName: intl.formatMessage({ id: 'ACTIONS' }),
        type: 'actions',
        getActions: (el) => tableActions(el),
        minWidth: 200
    })

    if (complexMetrics) headers.splice(0, 0, {
        field: 'complex',
        type: 'boolean',
        headerName: intl.formatMessage({ id: 'ESG.METRIC.TYPE.COMPLEX' }),
    });

    return <CustomDataGrid columns={headers} rows={data} handleData={handleRowClick} />;

}