import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardHeader, Button, Chip, List, ListItem, Typography, Tooltip, IconButton, CardActions, Stack } from '@mui/material';
import { DisplayBox, AlarmSeverityIcon, UserList, LoadingData, ConfirmationDialog, AlertSnackbar, AlarmProfileForm } from 'components';
import { ProfileDeleteDialogContent } from './AlarmProfiles';
import { useNavigate } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectEntities } from 'redux/entitySlice';
import { userSelector, permissionSelector } from 'redux/userSlice';
import { ContentCopyOutlined, DeleteOutlineOutlined, DoNotDisturbOffOutlined, DoNotDisturbOnOutlined, EditOutlined, ListAltOutlined } from '@mui/icons-material';
import { NotificationMethodIcon } from 'components/notesView/NotesView';
import { selectNotificationMethodTypes, selectMeasurementsTypesConf } from 'redux/configurationSlice';
import { selectUsersAndGroupsStatus, selectUsersAndGroups, fetchUsersAndGroups } from 'redux/usersAndGroupsSlice';
import { store } from 'redux/store';
import API from 'api';

const getConditionLabel = (condition) => {
    switch (condition) {
        case "<":
            return "LESS_THAN";
        case ">":
            return "GREATER_THAN";
        case "<=":
            return "LESS_THAN_OR_EQUAL";
        case ">=":
            return "GREATER_THAN_OR_EQUAL";
        case "=":
            return "EQUAL";
        case "<>":
            return "NOT_EQUAL";
        default:
            return "undefined";
    }
}

export default function AlarmProfileDetails({ profileId, alarmProfile, openDrawer }) {
    const navigate = useNavigate();
    const entities = useSelector(selectEntities);
    const status = useSelector(selectUsersAndGroupsStatus);
    const notificationMethodTypes = useSelector(selectNotificationMethodTypes);
    const measurementTypes = useSelector(selectMeasurementsTypesConf);
    const usersAndGroups = useSelector(selectUsersAndGroups);
    const { token } = useSelector(userSelector);
    const [openDialog, setOpenDialog] = useState(false);
    const [openForm, setOpenForm] = useState(false);
    const [copy, setCopy] = useState(false);
    const [loading, setLoading] = useState(false);
    const [profile, setProfile] = useState(null);
    const [allUsers, setAllUsers] = useState([]);
    const [allGroups, setAllGroups] = useState([]);
    const [reset, setReset] = useState(0);

    const createAlarmProfile = useSelector((state) => permissionSelector(state, 'create-alarmProfiles'));
    const updateAlarmProfile = useSelector((state) => permissionSelector(state, 'update-alarmProfiles'));
    const deleteAlarmProfile = useSelector((state) => permissionSelector(state, 'delete-alarmProfiles'));
    const viewUsersPermission = useSelector((state) => permissionSelector(state, 'view-users'));

    const [alert, setAlert] = useState({ open: false });
    const onAlertClose = () => setAlert({ ...alert, open: false });

    useEffect(() => {
        if (profileId) {
            setLoading(true);
            API.alarmProfile.getAlarmProfile(profileId, token).then(response => {
                if (response && response.data) setProfile(response.data)
                else setProfile({})
                setLoading(false);
            }).catch(error => {
                setLoading(false);
                console.error(error);
            });
        }
        else if (alarmProfile) setProfile(alarmProfile);
        else return () => { };

    }, [token, profileId, alarmProfile, reset]);

    useEffect(() => {
        if (!viewUsersPermission) {
            setAllUsers([]);
            setAllGroups([]);
            return;
        }
        else if (status === 'failed') {
            store.dispatch(fetchUsersAndGroups(token));
        } else if (status === 'complete') {
            setAllUsers(usersAndGroups.users);
            setAllGroups(usersAndGroups.groups);
        } else {
            setAllUsers([]);
            setAllGroups([]);
        }
    }, [token, status, usersAndGroups, viewUsersPermission]);

    const handleDelete = () => {
        API.alarmProfile.deleteAlarmProfile(profile._id, token).then(() => {
            navigate('/alarmProfiles', { state: { open: true, messageId: "SUCCESS.DELETE", severity: "success" } });
        }).catch(error => {
            setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_DELETED", severity: "success" });
            console.error(error);
            setOpenDialog(false);
        });
    };

    const handleEnableDisable = (flag) => {
        const updateAlarm = API.alarmProfile[flag ? 'disableAlarmProfile' : 'enableAlarmProfile'];
        updateAlarm(profile._id, token).then(({ data }) => {
            setAlert({ open: true, messageId: "SUCCESS." + (flag ? "DISABLE" : "ENABLE"), severity: "success" });
            setProfile(data);
        }).catch(error => {
            setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_UPDATED", severity: "success" });
            console.error(error)
        });
    };

    const handleFormSubmit = () => {
        if (copy) navigate('/alarmProfiles', { state: { open: true, messageId: "SUCCESS.CREATE", severity: "success" } });
        else setReset(reset => reset + 1);
    }

    const renderDetails = () => {
        const rules = Object.keys(profile?.rules || {})
        const details = <Stack direction="column" gap={1}>
            <DisplayBox label={<FormattedMessage id="NAME" />} value={profile?.name} />
            <DisplayBox label={<FormattedMessage id="ENTITY" />} value={entities[profile?.entityId]?.name} />

            <DisplayBox label={<FormattedMessage id="ALARMS.PROFILE_RULES" />} value={rules.length > 0 && rules.map(rule => {
                const profileRule = profile?.rules[rule];
                if (profileRule) return <Card key={rule} sx={{ mb: 0.5 }}>
                    <CardHeader
                        avatar={<AlarmSeverityIcon severity={rule} customStyle={(theme) => ({ width: theme.typography.fontSize * 2, height: theme.typography.fontSize * 2 })} />}
                        title={<Typography variant="h6"><FormattedMessage id={"ALARMS.PROFILE_RULES." + rule} /></Typography>}
                        subheader={profileRule?.notificationMethod && profileRule?.notificationMethod.map((el, index) => {
                            const method = notificationMethodTypes.find(type => type.key === el)?.value
                            return <Chip size="small" key={el + index} component="div" sx={{ mr: 0.5 }} variant='outlined' icon={<NotificationMethodIcon noTooltip type={el} fontSize="small" />} label={<FormattedMessage id={"NOTIFICATIONS.METHOD." + method} />} />
                        })}
                    />
                    <CardContent sx={{ pt: 0 }}>
                        <DisplayBox label={<FormattedMessage id="ALARM.MESSAGE" />} value={profileRule.details.length > 0 ? <span style={{ fontStyle: 'italic' }}>{profileRule.details}</span> : null} />
                        <br />
                        <DisplayBox label={<FormattedMessage id="FILTERS" />} value={<List dense>
                            {profileRule.filters.flatMap(filter => filter.conditions.map((condition, index) => {
                                const measureType = measurementTypes.find(el => el.key === condition.measurementTypeKey);
                                return <React.Fragment key={condition.measurementTypeKey + index}>
                                    <ListItem disableGutters sx={{ display: 'flex', gap: 1, pt: 0 }}>
                                        <Typography display="inline" component="span" variant="h6">{measureType.name || <FormattedMessage id={measureType.key} />}</Typography>
                                        <Typography display="inline" component="span"><FormattedMessage id={getConditionLabel(condition.operator)} /></Typography>
                                        <Typography display="inline" component="span" variant="h6">{condition.value.description || condition.value}</Typography>
                                        <Typography display="inline" component="span" color="text.secondary">{condition.unit}</Typography>
                                    </ListItem>
                                    {index < (filter.conditions?.length - 1) && <ListItem sx={{ py: 0 }}><Typography sx={{ textTransform: 'lowercase' }} display="block" variant="button" component="span" color="secondary.dark"><FormattedMessage id={filter.type} /></Typography></ListItem>}
                                </React.Fragment>
                            }))}
                        </List>} />
                        <DisplayBox label={<FormattedMessage id="NOTE.CONTACTS" />} value={<UserList
                            users={profileRule?.contacts.filter(participant => participant.type === "user").map(participant => allUsers.find(usr => usr.id === participant.id)?.username)}
                            groups={profileRule?.contacts.filter(participant => participant.type === "group").map(group => allGroups.find(grp => grp.id === group.id))}
                        />} />
                    </CardContent>
                </Card>
                else return null;
            })} />
        </Stack>;

        if (openDrawer) return details;

        else return <Card sx={{ mx: 'auto', minWidth: '50%' }}>
            <AlarmProfileForm open={openForm} onClose={() => { setOpenForm(false); setCopy(false); }} profile={profile || {}} copyFlag={copy} setReset={handleFormSubmit} />
            <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
            <CardHeader
                title={<FormattedMessage id="ALARMS.PROFILES.DETAILS" />}
                subheader={<Chip size="small" icon={!profile?.enabled ? <DoNotDisturbOnOutlined fontSize='small' /> : null} color={profile?.enabled ? "primary" : "default"} label={<FormattedMessage id={profile?.enabled ? "ENABLED" : "DISABLED"} />} />}
                action={<Tooltip title={<FormattedMessage id="ALL_ALARM_PROFILES" />} placement="left">
                    <IconButton color="primary" onClick={() => navigate('/alarmProfiles')}><ListAltOutlined /></IconButton>
                </Tooltip>}
            />
            <ConfirmationDialog
                fullWidth
                open={openDialog}
                title={<FormattedMessage id="ALARMS.PROFILES.CONFIRM_DELETE" />}
                content={profile && <ProfileDeleteDialogContent profile={profile} />}
                handleCancel={() => setOpenDialog(false)}
                handleCustomButton={handleDelete}
                customButtonTitle={<FormattedMessage id="DELETE" />}
                customButtonColor='error' />
            <CardContent>
                {details}
            </CardContent>
            <CardActions>
                {profile && <>
                    {createAlarmProfile && <Button size="small" color="primary" startIcon={<ContentCopyOutlined fontSize='small' />} onClick={() => { setCopy(true); setOpenForm(true); }}><FormattedMessage id="COPY" /></Button>}
                    {updateAlarmProfile && <Button size="small" color="secondary" startIcon={<EditOutlined fontSize='small' />} onClick={() => setOpenForm(true)}><FormattedMessage id="EDIT" /></Button>}
                    {updateAlarmProfile && <Button size="small" color={profile.enabled ? "warning" : "success"} startIcon={profile.enabled ? <DoNotDisturbOnOutlined fontSize='small' /> : <DoNotDisturbOffOutlined fontSize='small' />} onClick={() => handleEnableDisable(profile.enabled)}><FormattedMessage id={profile.enabled ? "DISABLE" : "ENABLE"} /></Button>}
                    {deleteAlarmProfile && <Button size="small" color="error" startIcon={<DeleteOutlineOutlined fontSize='small' />} onClick={() => setOpenDialog(true)}><FormattedMessage id="DELETE" /></Button>}
                </>}
            </CardActions>
        </Card>
    }

    return !loading ? renderDetails() : <LoadingData />;
}