import { Box, Paper, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { selectMeasurementsTypesConf } from "redux/configurationSlice";
import { formatDateLocale } from 'utils/helpers';


export default function MeasurementDisplayBlock({ data, error, action, noPaper, detail }) {
    const measurementTypes = useSelector(selectMeasurementsTypesConf);

    const type = measurementTypes.find(el => el.key === data.measurementTypeKey);

    let content = null;
    if (!type) {
        content = <Box display="flex" alignItems="center" justifyContent="space-between" maxWidth={320}>
            <Box><Typography variant="caption" color="error"><FormattedMessage id="ERROR" /> ({data && data.measurementTypeKey})</Typography></Box>
            <Box display="flex" alignItems="center" gap={0.5}>
                {action}
            </Box>
        </Box>
    }
    else {
        content = <><Box display="flex" alignItems="center" justifyContent="space-between" maxWidth={320}>
            <Typography variant="body2" color={data.error ? "text.disabled" : "primary"}>
                {type.name || <FormattedMessage id={type.key} />}
            </Typography>
            <Box display="flex" alignItems="center" gap={0.5}>
                {action}
            </Box>
        </Box>
            <Typography variant="h3" display="inline" sx={{ wordWrap: 'break-word' }}>
                {data.value ?? <Typography color="text.disabled" py={1}><FormattedMessage id="NO_VALUE" /></Typography>}
            </Typography>
            <Typography variant="body2" display="inline" color="text.secondary">
                {data.unit}
            </Typography>
            {data.error && <Typography variant="button" display="block" color="error">
                <FormattedMessage id={data.error} />
            </Typography>}
            {data.timestamp && <Typography variant="button" display="block" color="text.disabled">
                {formatDateLocale(data.timestamp)}
            </Typography>}
            {detail}
        </>

    }
    return <Box>
        {!noPaper ? <Paper sx={theme => ({ p: 2, borderColor: theme.palette.error.main })} variant={error ? "outlined" : "elevation"}>
            {content}
        </Paper> : content}
    </Box>
} 