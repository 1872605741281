import { DefaultLayout } from 'layouts';
import { useSelector } from 'react-redux';
import { userSelector, permissionSelector } from 'redux/userSlice';
import { SplashPage } from "pages";
import { EmptyState } from 'layouts';
import { MeasurementsEntryView, MeasurementsFileEntryView } from 'components';
import { selectFeaturesConfiguration } from 'redux/configurationSlice';

const MeasurementsEntryPage = ({ file }) => {
    return <EmptyState component={file ? <MeasurementsFileEntryView /> : <MeasurementsEntryView />} />;
}

const MeasurementsEntryPageComponent = ({ file }) => {
    const createMeasurementPermission = useSelector((state) => permissionSelector(state, 'create-measurement'));
    const createStagingMeasurementPermission = useSelector((state) => permissionSelector(state, 'create-staging-measurement'));
    const createStagingMetricPermission = useSelector((state) => permissionSelector(state, 'create-staging-metricValue'));
    const updateMetricPermission = useSelector((state) => permissionSelector(state, 'update-metric'));
    const featuresConfiguration = useSelector(selectFeaturesConfiguration);
    const { isSuccess: loggedIn } = useSelector(userSelector);

    if (loggedIn && (createMeasurementPermission || updateMetricPermission || ((createStagingMeasurementPermission || createStagingMetricPermission) && featuresConfiguration.stagingData?.enabled === true))) return <DefaultLayout page={<MeasurementsEntryPage file={file} />} />;
    else return <SplashPage />;
};

export default MeasurementsEntryPageComponent;