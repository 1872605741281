import { FormattedMessage } from "react-intl";
import { CircularProgress, Stack, Typography } from "@mui/material";


export default function LoadingData(props) {
    const { noText, size, color } = props;
    return <Stack direction="row" spacing={1} alignItems='center'>
        <CircularProgress size={size ? size + 'rem' : '1rem'} color={color || "primary"} />
        {!noText
            ? <Typography>
                <FormattedMessage id="LOADING_DATA" />
            </Typography>
            : null
        }
    </Stack>;
}