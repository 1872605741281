import React from 'react';
import { Drawer, CardHeader, CardContent, Box, IconButton, useMediaQuery, Divider, Toolbar } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';

const SideDrawer = (props) => {
    const { state, open, noOverflow, showOverDialog } = props;
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));

    const boxWidth = smallScreen ? '100%' : undefined;

    return (
        <Drawer
            elevation={24}
            anchor={smallScreen ? 'top' : 'right'}
            open={open}
            sx={showOverDialog ? { zIndex: 1300 } : undefined}
            onClose={() => props.toggleDrawer()}
            PaperProps={{
                sx: {
                    backgroundImage: 'none',
                }
            }}
        >
            <Box minWidth={320} width={boxWidth} sx={theme => ({ overflow: 'hidden', height: '100vh', display: 'flex', flexDirection: 'column' })}>
                {!showOverDialog && <Toolbar />}
                <CardHeader
                    sx={{ width: '100%' }}
                    title={state.title}
                    subheader={<Box sx={{ wordBreak: 'break-word', width: boxWidth || 320 }}>{state.subtitle}</Box>}
                    action={
                        <IconButton onClick={() => props.toggleDrawer()}>
                            <CloseOutlined name="cross" />
                        </IconButton>
                    } />
                <Divider flexItem />
                <CardContent sx={{ overflow: noOverflow ? undefined : 'auto', height: '100%', flexGrow: 1, maxWidth: 400 }}>
                    {state.display}
                </CardContent>
            </Box >
        </Drawer >
    );
}

export default SideDrawer;