import { EditOutlined, DeleteOutline, AddOutlined, CancelOutlined, RestorePageOutlined } from "@mui/icons-material";
import { Alert, Box, Card, CardContent, CardHeader, FormLabel, Grid, IconButton, Stack, Switch, Tooltip, Chip, Typography, Button, useMediaQuery } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import GoalFilters from "./GoalFilters";
import { AlertSnackbar, GoalForm, CustomDataGrid, BreadcrumbsAndNavigation, ConfirmationDialog, GoalDetailsView, ExpandedGridCell, SideDrawer, DisplayVersion } from 'components';
import { userSelector, permissionSelector } from "redux/userSlice";
import { selectStatusTypes } from "redux/configurationSlice";
import { selectTopics } from "redux/topicSlice";
import { selectEntities } from "redux/entitySlice";
import { getStatusColor } from "utils";
import { renderDateCell } from "components/dashboardView/TableHeaders";
import API from 'api';


export default function GoalsView({ topic, onChangeView, entityId }) {
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const { token } = useSelector(userSelector);
    const statusTypes = useSelector(selectStatusTypes);
    const intl = useIntl();
    const queryId = useParams().entityId;
    const entities = useSelector(selectEntities);
    const topics = useSelector(selectTopics);
    const [searchFilter, setSearchFilter] = useState(null);
    const [statusFilter, setStatusFilter] = useState(null);
    const [goals, setGoals] = useState([]);
    const [loading, setLoading] = useState(false);
    const [reset, setReset] = useState(0);
    const [alert, setAlert] = useState({ open: false });
    const [deleteChildrenFlag, setDeleteChildrenFlag] = useState(false);
    const [selectedGoal, setSelectedGoal] = useState(null);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [openGoalForm, setOpenGoalForm] = useState(false);
    const [openGoalDetails, setOpenGoalDetails] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [goalLogs, setGoalLogs] = useState({ loading: true });

    const viewTopicPermission = useSelector((state) => permissionSelector(state, 'view-topic'));
    const viewGoalPermission = useSelector((state) => permissionSelector(state, 'view-goal'));
    const createGoalPermission = useSelector((state) => permissionSelector(state, 'create-goal'));
    const updateGoalPermission = useSelector((state) => permissionSelector(state, 'update-goal'));
    const deleteGoalPermission = useSelector((state) => permissionSelector(state, 'delete-goal'));
    const viewLogsPermission = useSelector((state) => permissionSelector(state, 'view-change-logs'));

    const onAlertClose = () => setAlert({ ...alert, open: false });
    const onDialogClose = () => {
        setOpenConfirmDialog(false);
        setSelectedGoal('');
        setDeleteChildrenFlag(false);
    }
    const onFormClose = (resetFlag) => {
        setOpenGoalForm(false);
        setSelectedGoal('');
        setDeleteChildrenFlag(false);
        if (resetFlag) setReset(reset => reset + 1);
    }
    const handleDeleteGoal = () => {
        if (!deleteGoalPermission) return null;

        API.goal.deleteGoal(selectedGoal._id, token).then(response => {
            if (response?.data) {
                setAlert({ open: true, messageId: "ESG.GOALS.DELETE_SUCCESS", severity: "success" });
                setOpenConfirmDialog(false);
            }
            setReset(reset + 1);
        }).catch(error => {
            setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_DELETED", severity: "error" });
            setOpenConfirmDialog(false);
            console.error(error);
        });
        setSelectedGoal('');
    }

    useEffect(() => {
        setSelectedGoal('');
    }, [entityId, topic]);

    useEffect(() => {
        if (viewTopicPermission && viewGoalPermission) {
            setLoading(true);
            API.goal.getAllGoals(token, [topic?._id], [entityId]).then(items => {
                if (items.data) {
                    const goals = items.data
                        .map(el => ({ id: el._id, entityId: topics.find(t => t._id === el.topicId)?.entityId, ...el }))
                        .filter(el => entityId ? el.entityId === entityId : true);
                    setGoals(goals);
                }
                setLoading(false);
            }).catch(error => {
                setLoading(false);
                setGoals({ error });
                setAlert({ open: true, messageId: error?.data?.id || "ERROR.FETCHING_DATA", severity: "error" });
                console.error(error);
            });
        }
    }, [topic, entityId, topics, token, reset, viewTopicPermission, viewGoalPermission]);

    useEffect(() => {
        if (viewLogsPermission && selectedGoal && openDrawer) {
            setGoalLogs({ loading: true });
            API.logs.getLogsById(token, selectedGoal._id).then(response => {
                if (response.data) {
                    setGoalLogs(response.data)
                }
            }).catch(error => {
                setGoalLogs([]);
                console.error(error);
            })
        }
        else return;
    }, [selectedGoal, token, viewLogsPermission, openDrawer])

    const renderTable = useCallback(() => {
        const getSingleGoalData = (id) => {
            API.goal.getGoal(id, token).then((res) => {
                if (res?.data) {
                    setSelectedGoal(res.data);
                }
            }).catch(error => {
                setAlert({ open: true, messageId: error?.data?.id || "ERROR.FETCHING_DATA", severity: "error" });
                console.error(error);
            });
        }

        const headers = [
            {
                field: 'name',
                headerName: intl.formatMessage({ id: 'NAME' }),
                ...(!smallScreen && { flex: 2 }),
                editable: true,
                renderCell: (row) => <ExpandedGridCell value={row.value} width={row.colDef.computedWidth} />,
                minWidth: 300
            },
            {
                field: 'topicId',
                headerName: intl.formatMessage({ id: 'ESG.TOPIC' }),
                ...(!smallScreen && { flex: 1 }),
                valueGetter: (row) => topics.find(el => el._id === row.row.topicId)?.name,
                renderCell: ({ value }) => <Typography>{value}</Typography>,
                minWidth: 150
            },
            {
                field: 'status',
                headerName: intl.formatMessage({ id: 'ESG.GOAL.STATUS' }),
                ...(!smallScreen && { flex: 1 }),
                valueGetter: (row) => {
                    const status = statusTypes.find(status => status.key === row.row.status)
                    return status?.value;
                },
                renderCell: ({ row, value }) => <Typography color={getStatusColor(row.status)}>
                    {value ? <FormattedMessage id={"STATUS." + value} /> : <FormattedMessage id="NONE" />}
                </Typography>,
                minWidth: 150
            },
            !topic && {
                field: 'entityId',
                headerName: intl.formatMessage({ id: 'ENTITY' }),
                ...(!smallScreen && { flex: 1 }),
                valueGetter: (row) => entities[row.row.entityId]?.name,
                renderCell: ({ value }) => <Typography>{value}</Typography>,
                minWidth: 150
            },
            {
                field: 'type',
                headerName: intl.formatMessage({ id: 'ESG.METRIC.CATEGORY' }),
                ...(!smallScreen && { flex: 1 }),
                renderCell: (row) => <Typography><FormattedMessage id={"ESG.TYPE." + row.row.type.toUpperCase()} /></Typography>,
                minWidth: 150
            },
            {
                field: 'startDate',
                type: 'dateTime',
                headerName: intl.formatMessage({ id: 'START_DATE' }),
                ...(!smallScreen && { flex: 1 }),
                valueGetter: (row) => new Date(row.row.dateRange.startDate),
                ...renderDateCell(intl),
                minWidth: 150
            },
            {
                field: 'actions',
                headerName: intl.formatMessage({ id: 'ACTIONS' }),
                type: 'actions',
                getActions: (el) => {
                    const actions = [];
                    if (viewLogsPermission) actions.push(<Tooltip title={<FormattedMessage id="VIEW_LOGS" />} placement="bottom" arrow>
                        <IconButton component="span" color="info" key="edit" onClick={() => { setSelectedGoal(el.row); setOpenDrawer(true) }}> <RestorePageOutlined fontSize="small" /> </IconButton>
                    </Tooltip>);
                    if (queryId && updateGoalPermission) actions.push(<Tooltip title={<FormattedMessage id="ESG.GOALS.EDIT" />} placement="bottom" arrow>
                        <IconButton component="span" color="secondary" key="edit" onClick={() => { setSelectedGoal(el.row); setOpenGoalForm(true) }}> <EditOutlined fontSize="small" /> </IconButton>
                    </Tooltip>);
                    if (queryId && deleteGoalPermission) actions.push(<Tooltip title={<FormattedMessage id="ESG.GOALS.DELETE" />} placement="bottom" arrow>
                        <IconButton component="span" color="error" key="delete" onClick={() => { getSingleGoalData(el.row.id); setOpenConfirmDialog(true); }}><DeleteOutline fontSize="small" /></IconButton>
                    </Tooltip>)

                    if (actions.length) return actions;
                    else return [smallScreen ? <IconButton key="disabled-actions" disabled><CancelOutlined fontSize="small" /></IconButton> : <Button key="disabled-actions" disabled><FormattedMessage id="DISABLED" /></Button>]
                },
                minWidth: 200
            },
        ].filter(el => el);

        if (goals?.error) return <Alert severity="error"><FormattedMessage id='ERROR.NO_DATA' /></Alert>;
        else {
            const rows = goals?.length ? goals
                .filter(el => (searchFilter ? el.name.toLowerCase().includes(searchFilter.toLowerCase()) : true))
                .filter(el => (statusFilter ? statusFilter.key === el.status : true))
                : [];
            return <CustomDataGrid isLoading={loading} rows={rows} columns={headers} disableToolbar={Boolean(topic)} handleData={(el) => { setSelectedGoal(el.row); setOpenGoalDetails(true) }} />;
        }


    }, [goals, intl, loading, entities, statusTypes, smallScreen, searchFilter, topics, topic, queryId, statusFilter, updateGoalPermission, deleteGoalPermission, viewLogsPermission, token]);

    const deleteDialogContent = <Stack spacing={1}>
        {selectedGoal?.targetCount > 0 && <Box>
            <Typography component="span">
                <FormattedMessage id="ESG.TARGET.NUMBER_LABEL.GOAL_PREFIX" />
                <Typography color="error" component="span">
                    {selectedGoal?.targetCount}<FormattedMessage id="ESG.TARGET.NUMBER_LABEL.SUFFIX" />
                </Typography>
            </Typography>
        </Box>}
        <Box><FormattedMessage id="COUNT_CHILDREN.ESTIMATE" /> <FormLabel sx={{ color: 'primary.main', typography: 'body1' }}>{selectedGoal?.connections?.childrenArray?.length ? selectedGoal.connections.childrenArray.length + 1 : 1}</FormLabel></Box>
        {selectedGoal && selectedGoal.connections?.childrenArray?.length > 0 && <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FormattedMessage id="DELETE_ALL_CHILDREN" />
            <FormLabel sx={{ ml: 0.5 }}>
                <Switch size="small" checked={deleteChildrenFlag} onChange={() => setDeleteChildrenFlag(!deleteChildrenFlag)} />
                <Typography sx={{ cursor: 'pointer' }} display="inline" color={deleteChildrenFlag ? "primary" : "text.disabled"}><FormattedMessage id={deleteChildrenFlag ? "YES" : "NO"} /></Typography>
            </FormLabel>
        </Box>}
    </Stack>

    return <>
        <ConfirmationDialog
            open={openConfirmDialog}
            title={!selectedGoal ? null : <span><FormattedMessage id="CONFIRM_DELETE_FOR" /> {selectedGoal.name}</span>}
            content={deleteDialogContent}
            handleCancel={onDialogClose}
            handleCustomButton={handleDeleteGoal}
            customButtonTitle={<FormattedMessage id="DELETE" />}
            customButtonColor='error'
            disabled={selectedGoal && (selectedGoal.connections?.childrenArray?.length > 0) && !deleteChildrenFlag}
        />
        <GoalForm open={openGoalForm} existingGoal={selectedGoal} onClose={onFormClose} topicId={topic?._id} entity={entities[queryId]} setAlert={setAlert} />
        <GoalDetailsView setRefreshData={() => setReset(reset + 1)} goalId={selectedGoal?._id} topicId={topic?._id} openDialog={openGoalDetails} onClose={() => { setSelectedGoal(null); setOpenGoalDetails(false) }} onChangeView={(data) => setSelectedGoal(data)} action={queryId && updateGoalPermission && <IconButton onClick={() => { setOpenGoalDetails(false); setOpenGoalForm(true); }}><EditOutlined /></IconButton>} />
        <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
        <SideDrawer open={openDrawer} toggleDrawer={() => { setOpenDrawer(false); setSelectedGoal(null) }} state={{
            title: <FormattedMessage id="LOGS" />,
            subtitle: selectedGoal?.name,
            display: <DisplayVersion data={goalLogs} />

        }} />
        {(Boolean(topic) && onChangeView)
            ? <>

                <GoalFilters loading={loading} goals={goals} searchFilter={searchFilter} specialNavigate={topic.connections.parentId && `?topic=${topic.connections.parentId}`} setSearchFilter={setSearchFilter} statusFilter={statusFilter} setStatusFilter={setStatusFilter} onChangeView={onChangeView} setOpenGoalForm={setOpenGoalForm} />

                <Card sx={{ width: '100%' }}>
                    {(!viewTopicPermission || !viewGoalPermission)
                        ? <Card><Alert severity="warning"><FormattedMessage id='UNAUTHORIZED.NO_PERMISSION' /></Alert></Card>
                        : <>
                            <CardHeader
                                title={<FormattedMessage id="ESG.GOALS.TABLE" />}
                            />
                            <CardContent>
                                {renderTable()}
                            </CardContent>
                        </>
                    }
                </Card>
            </>
            : <Grid container direction="column">
                <BreadcrumbsAndNavigation entity={entities[queryId]} baseNavigate="/goals" rootUrl="/goals" goalsMode />
                <Card sx={{ width: '100%' }}>
                    <CardHeader
                        title={<FormattedMessage id="ESG.GOALS.TABLE" />}
                        // action={<Typography variant="caption">Add goal to a specific topic</Typography>}
                        action={createGoalPermission && <Chip variant={goals.length ? "outlined" : "contained"} disabled={loading} icon={<AddOutlined fontSize="small" color="primary" />} onClick={() => setOpenGoalForm(true)} label={<FormattedMessage id="ESG.GOALS.CREATE" />} />}
                    />
                    <CardContent>
                        {renderTable()}
                    </CardContent>
                </Card>
            </Grid>
        }
    </>
}