import { useEffect, useState } from "react";
import { Alert, Button, Collapse, Divider, IconButton, List, ListItem, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { FormattedMessage } from "react-intl";
import DisplayValueChange from "./DisplayValueChange";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { LoadingData } from 'components';
import { formatDateLocale } from "utils";

export function DisplayChanges({ method, timestamp, changedValues, user, collapse }) {
    const theme = useTheme();
    const [collapsed, setCollapsed] = useState(false);

    useEffect(() => {
        if (collapse === undefined) setCollapsed(true);
        else setCollapsed(collapse)
    }, [collapse]);

    const getColor = () => {
        switch (method) {
            case "Create": return theme.palette.primary.dark;
            case "Update": return theme.palette.success.dark;
            case "Delete": return theme.palette.error.dark;
            default: return theme.palette.primary.dark;
        }
    }
    return <Stack direction="column" spacing={1} width='100%'>
        <Stack direction="row" justifyContent="space-between" width='100%'>
            <Typography display="block" variant="h6" component="span" color={getColor()}><FormattedMessage id={"METHODS." + method} /></Typography>
            {<Tooltip title={<FormattedMessage id={!collapsed ? "SHOW_CHANGES" : "HIDE_CHANGES"} />} arrow>
                <IconButton component="span" size="small" color="primary" onClick={() => setCollapsed(!collapsed)}>{!collapsed ? <VisibilityOutlined fontSize="small" /> : <VisibilityOffOutlined fontSize="small" />}</IconButton>
            </Tooltip>}
        </Stack>
        <Collapse in={collapsed}>
            {changedValues.map((item, index) => (
                <DisplayValueChange sx={{ py: 0.5 }} key={index} label={item.label} previousValue={item.previousValue} intlPrefix={item.intlPrefix} currentValue={item.currentValue} method={method} />
            ))}
        </Collapse>
        <Typography display="block" variant="subtitle2" component="span" color="text.disabled"> ({user})</Typography>
        <Typography display="block" variant="button" component="span" color="text.disabled">{timestamp}</Typography>
    </Stack>
}

export default function DisplayVersion({ data, expanded }) {
    const [collapse, setCollapse] = useState(expanded ? true : false);
    if (data && data.loading) return <LoadingData />;
    else return <List disablePadding>
        {!expanded && data?.length > 0 && <Button size="small" startIcon={!collapse ? <VisibilityOutlined fontSize="small" /> : <VisibilityOffOutlined fontSize="small" />} onClick={() => setCollapse(!collapse)}><FormattedMessage id={`${!collapse ? "SHOW" : "HIDE"}_CHANGES_ALL`} /></Button>}
        {data?.length > 0 ? data.map((version, index) => (
            <div key={index}> <ListItem >
                <DisplayChanges method={version.type} timestamp={formatDateLocale(version.timestamp)} changedValues={version.changes} user={version.user} collapse={collapse} />
            </ListItem>
                <Divider />
            </div>
        )) : <Alert component="span" severity="warning"><FormattedMessage id="NO_DATA" /></Alert>}
    </List>
}

