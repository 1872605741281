import instance, { setAuthHeader } from "./axiosEnv";
import { localISOString } from "utils";

const getHistory = (token, dateFrom, dateTo, entityId, measurementTypes, collectorTypes) => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();
    queryParams.append('dateFrom', localISOString(dateFrom));
    queryParams.append('dateTo', localISOString(dateTo));
    if (entityId) {
        for (let el in entityId) {
            queryParams.append('entityId', entityId[el]);
        }
    }
    if (measurementTypes) {
        for (let el in measurementTypes) {
            queryParams.append('measurementTypeKey', measurementTypes[el]);
        }
    }
    if (collectorTypes) {
        for (let el in collectorTypes) {
            queryParams.append('collectorType', collectorTypes[el]);
        }
    }
    return instance.get('/staging?' + queryParams.toString(), config);
}

const getSingleMeasurement = (token, measurementId) => {
    const config = setAuthHeader(token);
    return instance.get('/staging/' + measurementId, config);
}

const approveMeasurement = (token, measurementId) => {
    const config = setAuthHeader(token);
    return instance.put('/staging/' + measurementId + '/approve', null, config);
}

const updateMeasurement = (measurementId, newMeasurement, token) => {
    const config = setAuthHeader(token);
    return instance.put('/staging/' + measurementId, newMeasurement, config);
}

const deleteMeasurement = (token, measurementId) => {
    const config = setAuthHeader(token);
    return instance.delete('/staging/' + measurementId, config);
}

const measurementsAPI = {
    getHistory,
    getSingleMeasurement,
    approveMeasurement,
    updateMeasurement,
    deleteMeasurement,
}

export default measurementsAPI;