import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport } from '@mui/x-data-grid';

let defaultRowsPerPageOptions = [25, 50, 75, 100];

export default function CustomDataGrid(props) {
    const { rows, columns, setSelectedRows, handleData, rowsPerPage, pageSize, setPageSize, pageNumber, setPageNumber, enableExport, customCellClass, rowCount, paginationMode, disableToolbar, isLoading } = props;

    const defaultPageSize = rowsPerPage ? rowsPerPage[0] : defaultRowsPerPageOptions[0];
    const [paginationModel, setPaginationModel] = useState({ pageSize: pageSize || defaultPageSize, page: pageNumber || 0 });
    const intl = useIntl();

    const handlePaginationChange = ({ pageSize, page }) => {
        if (typeof setPageSize === 'function') setPageSize(pageSize);
        if (typeof setPageNumber === 'function') setPageNumber(page);
        setPaginationModel({ pageSize: pageSize, page: page });
    }

    const getPageSizeOptions = (num) => {
        const arr = rowsPerPage ? rowsPerPage : defaultRowsPerPageOptions;
        if (isNaN(num)) return arr;

        let result = [];
        for (let i = 0; i < arr.length; i++) {
            if (arr[i] <= num) {
                result.push(arr[i]);
            } else {
                result.push(arr[i]);
                break;
            }
        }
        return result;
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                {enableExport && !!rows.length ? <GridToolbarExport printOptions={{ disableToolbarButton: true }} csvOptions={{ allColumns: true, delimiter: ';', utf8WithBom: true }} /> : null}
            </GridToolbarContainer>
        )
    }

    return (
        <DataGrid
            sx={{
                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                    outline: "none" // removes outline on row cell click
                },
                cursor: handleData ? 'pointer' : 'default',
                filter: 'blur(0)',
            }}
            getCellClassName={customCellClass}
            disableRowSelectionOnClick={!setSelectedRows}
            autoHeight
            pagination
            loading={isLoading}
            paginationMode={paginationMode ? paginationMode : 'client'}
            rowCount={rowCount}
            rows={rows}
            columns={columns}
            getRowId={(row) => row.id || row._id}
            paginationModel={paginationModel}
            onPaginationModelChange={handlePaginationChange}
            pageSizeOptions={getPageSizeOptions(rowCount)}
            checkboxSelection={!!setSelectedRows}
            disableColumnMenu
            editMode="row"
            onSelectionModelChange={(ids) => {
                if (!setSelectedRows) return
                const selectedIds = new Set(ids);
                const selectedRows = rows.filter((row) =>
                    selectedIds.has(row.id),
                );
                setSelectedRows(selectedRows);
            }}
            experimentalFeatures={{ newEditingApi: true }}
            slots={!disableToolbar ? {
                toolbar: CustomToolbar
            } : null}
            onRowClick={(row, event) => {
                if (!handleData) return
                handleData(row)
            }}
            localeText={{
                //root
                noRowsLabel: intl.formatMessage({ id: 'ROW.NO_ROWS' }),
                noResultsOverlayLabel: intl.formatMessage({ id: 'NO_RESULTS' }),
                errorOverlayDefaultLabel: intl.formatMessage({ id: 'ROW.ERROR' }),
                //columns5
                toolbarColumns: intl.formatMessage({ id: 'COLUMNS' }),
                columnsPanelTextFieldLabel: intl.formatMessage({ id: 'COLUMNS_FIND' }),
                columnsPanelTextFieldPlaceholder: intl.formatMessage({ id: 'COLUMNS_TITLE' }),
                columnsPanelDragIconLabel: intl.formatMessage({ id: 'REORDER' }),
                columnsPanelShowAllButton: intl.formatMessage({ id: 'SHOW_ALL' }),
                columnsPanelHideAllButton: intl.formatMessage({ id: 'HIDE_ALL' }),
                checkboxSelectionHeaderName: intl.formatMessage({ id: 'CHECKBOX_SELECTION' }),
                footerRowSelected: (count) =>
                    count !== 1
                        ? `${intl.formatMessage({ id: 'ROW.SELECTED_ROWS' })} : ${count.toLocaleString()} `
                        : `${intl.formatMessage({ id: 'ROW.SELECTED_ROWS' })} : ${count.toLocaleString()} `,
                //filter
                toolbarFilters: intl.formatMessage({ id: 'FILTER' }),
                toolbarFiltersLabel: intl.formatMessage({ id: 'FILTER_SHOW' }),
                toolbarFiltersTooltipHide: intl.formatMessage({ id: 'FILTER_HIDE' }),
                toolbarFiltersTooltipShow: intl.formatMessage({ id: 'FILTER_SHOW' }),
                filterPanelAddFilter: intl.formatMessage({ id: 'FILTER_ADD' }),
                filterPanelDeleteIconLabel: intl.formatMessage({ id: 'DELETE' }),
                filterPanelColumns: intl.formatMessage({ id: 'COLUMNS' }),
                filterPanelInputLabel: intl.formatMessage({ id: 'VALUE' }),
                filterPanelInputPlaceholder: intl.formatMessage({ id: 'FILTER_VALUE' }),
                filterOperatorContains: intl.formatMessage({ id: 'OPERATOR_CONTAINS' }),
                filterOperatorEquals: intl.formatMessage({ id: 'EQUALS' }),
                filterOperatorStartsWith: intl.formatMessage({ id: 'STARTS_WITH' }),
                filterOperatorEndsWith: intl.formatMessage({ id: 'ENDS_WITH' }),
                filterOperatorIs: intl.formatMessage({ id: 'IS' }),
                filterOperatorNot: intl.formatMessage({ id: 'IS_NOT' }),
                filterOperatorAfter: intl.formatMessage({ id: 'IS_AFTER' }),
                filterOperatorOnOrAfter: intl.formatMessage({ id: 'IS_ON_OR_AFTER' }),
                filterOperatorBefore: intl.formatMessage({ id: 'IS_BEFORE' }),
                filterOperatorOnOrBefore: intl.formatMessage({ id: 'IS_ON_OR_BEFORE' }),
                filterOperatorIsEmpty: intl.formatMessage({ id: 'IS_EMPTY' }),
                filterOperatorIsNotEmpty: intl.formatMessage({ id: 'IS_NOT_EMPTY' }),
                filterOperatorIsAnyOf: intl.formatMessage({ id: 'IS_ANY_OF' }),
                toolbarFiltersTooltipActive: (count) =>
                    count !== 1 ? `${intl.formatMessage({ id: 'FILTER_ACTIVE' })} ${count}` : `${intl.formatMessage({ id: 'FILTER_ACTIVE' })} ${count}`,
                //density
                toolbarDensity: intl.formatMessage({ id: 'DENSITY' }),
                toolbarDensityLabel: intl.formatMessage({ id: 'DENSITY' }),
                toolbarDensityCompact: intl.formatMessage({ id: 'COMPACT' }),
                toolbarDensityStandard: intl.formatMessage({ id: 'STANDARD' }),
                toolbarDensityComfortable: intl.formatMessage({ id: 'COMFORTABLE' }),
                //export
                toolbarExport: intl.formatMessage({ id: 'EXPORT' }),
                toolbarExportLabel: intl.formatMessage({ id: 'EXPORT' }),
                toolbarExportCSV: intl.formatMessage({ id: 'DOWNLOAD_CSV' }),
                toolbarExportPrint: intl.formatMessage({ id: 'PRINT' }),
                //pagination
                MuiTablePagination: {
                    labelRowsPerPage: intl.formatMessage({ id: 'ROWS_PER_PAGE' })
                },
            }}
        />

    );
}