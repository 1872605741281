import { FileDownloadOutlined } from "@mui/icons-material";
import { Button, IconButton, Tooltip } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { userSelector } from "redux/userSlice";
import { AlertSnackbar, LoadingData } from "components";
import { FormattedMessage } from "react-intl";
import API from "api";

export default function ESRSReportButton({ entity, variant, color, disabled, year, type, setAlert }) {
    const { token } = useSelector(userSelector);
    const [customAlert, setCustomAlert] = useState({ open: false });
    const [disableAction, setDisableAction] = useState(false);

    const changeAlert = typeof setAlert === 'function' ? setAlert : setCustomAlert;
    const onAlertClose = () => setCustomAlert({ ...customAlert, open: false });

    const onClick = () => {
        setDisableAction(true);
        changeAlert({ open: true, messageId: "DOWNLOAD_STARTED", severity: "info" });
        API.metricReports.getReport(token, entity._id, year || new Date().getFullYear(), type).then((response) => {
            const href = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }));
            const anchorElement = document.createElement('a');
            anchorElement.href = href;
            anchorElement.setAttribute('download', `${entity?.name}_report_${year || new Date().getFullYear()}.xlsx`);
            document.body.appendChild(anchorElement);
            anchorElement.click();
            document.body.removeChild(anchorElement);
            window.URL.revokeObjectURL(href);
            setDisableAction(false);
        }).catch(error => {
            console.error(error);
            changeAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_DOWNLOADED", severity: "error" });
            setDisableAction(false);
        })
    };

    const icon = disableAction ? <LoadingData noText /> : <FileDownloadOutlined />

    if (!type || !entity) {
        console.error("ESRSReportButton component requires additional mandatory properties: ", [!type && "type", !entity && "entity"].filter(Boolean).toString());
        return null;
    }
    else return <>
        <AlertSnackbar open={customAlert.open} onClose={onAlertClose} severity={customAlert.severity} messageId={customAlert.messageId} />
        {variant === "button"
            ? <Button color={color} key="download-report" size="small" disabled={disabled || disableAction} onClick={onClick} startIcon={icon}><FormattedMessage id="ESRS_REPORT" /></Button>
            : <Tooltip title={<FormattedMessage id="DOWNLOAD_ESRS_REPORT" />} arrow>
                <IconButton color={color} component="span" disabled={disabled || disableAction} key="download-report" size="small" onClick={onClick}>{icon}</IconButton>
            </Tooltip>
        }
    </>
}