import { EditOutlined, RestorePageOutlined } from "@mui/icons-material";
import { Card, CardActions, CardHeader, CardMedia, Divider, IconButton, Stack, Tooltip, Typography, useTheme, CardContent, Box } from "@mui/material";
import { EntityIconLinks } from 'components/entitiesView/EntitiesView';
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { selectEntities } from "redux/entitySlice";
import { permissionSelector } from "redux/userSlice";

export default function EntityCard(props) {
    const { entityId, disableActions, customAction, showDescription, setOpenForm, setEntityLogs } = props;
    const entity = useSelector(selectEntities)[entityId];
    const themeMode = useTheme().palette.mode;
    const navigate = useNavigate();

    const updateEntityPermission = useSelector((state) => permissionSelector(state, 'update-entity'));
    const viewLogsPermission = useSelector((state) => permissionSelector(state, 'view-change-logs'));

    const getImage = () => {
        if (entity.attributes && entity.attributes.picture) return `${process.env.REACT_APP_API_BASE_URL}/${entity.attributes.picture}`
        else return `${process.env.PUBLIC_URL}/assets/images/default/default.${themeMode}.png`
    }

    if (!entity) return null;

    return <Card sx={{ width: '100%', minWidth: '200px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <div>
            <CardMedia
                component="img"
                height="200"
                image={getImage()}
                alt={entity.name + " - image"}
            // onClick={!disableActions ? () => navigate('/entities/' + entityId) : null}
            // sx={!disableActions ? { cursor: 'pointer' } : undefined}
            />
            <CardHeader
                title={<Stack direction="row" gap={1} alignItems="flex-start">
                    <Tooltip title={!disableActions ? <FormattedMessage id="VIEW_ENTITIES" /> : null} placement='right' arrow>
                        <span>
                            <Typography variant='h5' onClick={!disableActions ? () => navigate('/entities/' + entityId) : null} sx={!disableActions ? (theme => ({ ':hover': { color: theme.palette.primary.main }, cursor: 'pointer' })) : undefined}>{entity.name}</Typography>
                        </span>
                    </Tooltip>
                </Stack>}
                subheader={entity.geoProperties.address}
                action={!disableActions && updateEntityPermission && [
                    <Tooltip key="edit-icon" title={<FormattedMessage id="EDIT" />} placement="bottom" arrow>
                        <IconButton component="span" key="edit-icon" onClick={() => setOpenForm(entity)}><EditOutlined fontSize='small' /></IconButton>
                    </Tooltip>,
                    viewLogsPermission ? <Tooltip key="view-logs" title={<FormattedMessage id="VIEW_LOGS" />} placement="bottom" arrow>
                        <IconButton component="span" onClick={() => setEntityLogs(entity)}><RestorePageOutlined fontSize='small' /></IconButton>
                    </Tooltip> : null
                ]}
            />
        </div>
        {!disableActions && <div>
            <Divider />
            <Box sx={{ py: 0.5, px: 1 }} display='flex' gap={0.5}>
                <EntityIconLinks entity={{ _id: entityId }} />
            </Box>
        </div>}
        {showDescription && entity.description && <CardContent>
            <Typography fontStyle='italic'>
                {entity.description}
            </Typography>
        </CardContent>}
        {
            customAction && <>
                {!disableActions && <Divider />}
                <CardActions>
                    {customAction}
                </CardActions>
            </>
        }
    </Card >;
}