import { Alert, Box, Collapse, Divider, IconButton, List, ListItemButton, ListItemText, Menu, MenuItem, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { formatDateLocale } from "utils";
import { LoadingData } from 'components';
import MeasurementDetails from "./MeasurementDetails";
import { useSelector } from "react-redux";
import { userSelector } from "redux/userSlice";
import API from "api";
import { FormattedMessage } from "react-intl";
import { ArrowDownward, ArrowUpward, SwapVertOutlined } from "@mui/icons-material";


function ListItem({ label, data, id, setAlert, onReset }) {
    const { token } = useSelector(userSelector);
    const [loading, setLoading] = useState(false);
    const [openMore, setOpenMore] = useState(false);
    const [measurement, setMeasurement] = useState(null);

    useEffect(() => {
        if (measurement === null && openMore === true) {
            setLoading(true);
            API.measurements.getSingleMeasurement(token, id).then(item => {
                if (item.data) {
                    setMeasurement(item.data)
                } else setMeasurement(null);
                setLoading(false)
            }).catch(error => {
                console.error(error);
                setLoading(false)
            });
        }
    }, [token, measurement, openMore, id]);

    return [
        <ListItemButton key='item' sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} onClick={() => setOpenMore(!openMore)}>
            <Typography variant="caption" component="span" color={openMore ? 'primary' : undefined}>{label}</Typography>
            <ListItemText primary={<span><Typography component="span" variant="h6">{data.y}</Typography> {data.unit}</span>} secondary={formatDateLocale(data.x)} />
        </ListItemButton>,
        <Collapse in={openMore} key='details'>
            <Box m={1} px={1}>
                {loading
                    ? <LoadingData />
                    : (measurement !== null ? <MeasurementDetails sideDrawer measurement={measurement} lessDetail setAlert={setAlert} onReset={onReset} /> : <Alert severity="error"><FormattedMessage id="ERROR.FETCHING_DATA" /></Alert>)
                }
            </Box>
        </Collapse>
    ]
}


export default function MeasurementListDetails({ measurementsList, setAlert, onReset }) {
    const [orderBy, setOrderBy] = useState({ type: 'timestamp', order: 'desc' });
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleChange = (type) => {
        setOrderBy(old => ({ type: old.type, order: type }));
        setAnchorEl(null);
    }

    return <>
        <Stack direction="row" gap={1} alignItems='flex-start'>
            <TextField
                select
                size="small"
                value={orderBy.type}
                fullWidth
                label={<FormattedMessage id="ORDER_BY" />}
                onChange={(e) => {
                    const type = e.target.value;
                    setOrderBy(old => ({ type: type, order: old.order }));
                }}
                helperText={<FormattedMessage id={(orderBy.order === 'asc' ? "ASCENDING" : "DESCENDING") + "_ORDER"} />}
            >
                {['timestamp', 'value', 'label'].map(type => <MenuItem key={type} value={type}><FormattedMessage id={"ORDER_BY." + type} /></MenuItem>)}
            </TextField>
            <IconButton component="span" size="small" onClick={handleClick}><SwapVertOutlined /></IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'sorting-button',
                }}
            >
                <MenuItem selected={orderBy.order === 'asc'} onClick={() => handleChange('asc')}><ArrowUpward fontSize="small" color="text.secondary" sx={{ mr: 0.5 }} /><FormattedMessage id="ASCENDING" /></MenuItem>
                <MenuItem selected={orderBy.order === 'desc'} onClick={() => handleChange('desc')}><ArrowDownward fontSize="small" color="text.secondary" sx={{ mr: 0.5 }} /><FormattedMessage id="DESCENDING" /></MenuItem>
            </Menu>
        </Stack>
        <List>
            {measurementsList.sort((a, b) => {
                const compareStr = (first, second) => {
                    if (first < second) return -1;
                    else if (first > second) return 1;
                    else return 0;
                }

                switch (orderBy.type) {
                    case 'timestamp':
                        return orderBy.order === 'asc' ? compareStr(new Date(a.data.x).getTime(), new Date(b.data.x).getTime()) : compareStr(new Date(b.data.x).getTime(), new Date(a.data.x).getTime());
                    case 'value':
                        return orderBy.order === 'asc' ? a.data.y - b.data.y : b.data.y - a.data.y;
                    case 'label':
                    default:
                        return orderBy.order === 'asc' ? compareStr(a.label, b.label) : compareStr(b.label, a.label);
                }
            }).map((el, index) => <li key={el.label + index} >
                <ListItem label={el.label} data={el.data} id={el.data.id} setAlert={setAlert} onReset={onReset} />
                <Divider />
            </li>)}
        </List>
    </>
}