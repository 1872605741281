import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { selectCollectorTypesConf } from 'redux/configurationSlice';
import { MeasurementDisplayBlock, EditMeasurementForm, ConfirmationDialog, LoadingData, DisplayVersion } from "components";
import { Card, CardContent, CardHeader, Stack, Typography, IconButton, Chip, Tooltip, Box, Button, Collapse } from '@mui/material';
import { userSelector, permissionSelector } from 'redux/userSlice';
import { DeleteOutlineOutlined, EditOutlined, ThumbDownOutlined, ThumbUpOutlined, RestorePageOutlined } from '@mui/icons-material';
import API from 'api';

const CustomText = (props) => {
    return <Stack direction='column' sx={{ mb: 2 }}>
        <Typography variant='body2' color='primary.main'><FormattedMessage id={props.messageId} /></Typography>
        {props.component ? props.component : <Typography variant='body1'>{props.text}</Typography>}
    </Stack>
};


const SideDrawerComponent = ({ measurement, onClose, onReset, setAlert, stagingData, lessDetail }) => {
    const collectorTypes = useSelector(selectCollectorTypesConf);
    const intl = useIntl();
    const [openEditForm, setOpenEditForm] = useState(false);
    const [disabledDeleteButton, setDisabledDeleteButton] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState("");
    const [showChanges, setShowChanges] = useState(false);
    const { token } = useSelector(userSelector);

    const updateStagingMeasurementPermission = useSelector((state) => permissionSelector(state, 'update-staging-measurement'));
    const deleteStagingMeasurementPermission = useSelector((state) => permissionSelector(state, 'delete-staging-measurement'));
    const updateMeasurementPermission = useSelector((state) => permissionSelector(state, 'update-measurement'));
    const deleteMeasurementPermission = useSelector((state) => permissionSelector(state, 'delete-measurement'));
    const viewLogsPermission = useSelector((state) => permissionSelector(state, 'view-change-logs'));
    const [measurementLogs, setMeasurementLogs] = useState({ loading: true })

    const handleDelete = () => {
        setDisabledDeleteButton(true);
        API[stagingData ? "stagingMeasurements" : "measurements"].deleteMeasurement(token, measurement._id).then(response => {
            if (response.data) {
                if (onClose) onClose();
                if (onReset) onReset({ ...response.data, actionType: 'delete' });
                if (setAlert) setAlert({ open: true, messageId: "SUCCESS.DELETE", severity: "success" });
            }
            setDisabledDeleteButton(false);
        }).catch(error => {
            console.error(error);
            if (setAlert) setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_DELETED", severity: "error" });
            setDisabledDeleteButton(false);
        })
    }
    const handleSubmit = () => {
        setDisabledDeleteButton(true);
        API.stagingMeasurements.approveMeasurement(token, measurement._id).then(() => {
            if (onClose) onClose();
            if (onReset) onReset({ ...measurement.data, actionType: 'approve' });
            if (setAlert) setAlert({ open: true, messageId: "SUCCESS.POST_MEASUREMENTS", severity: "success" });
            setDisabledDeleteButton(false);
        }).catch(error => {
            console.error(error);
            if (setAlert) setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_APPROVED", severity: "error" });
            setDisabledDeleteButton(false);
        })
    }

    useEffect(() => {
        if (!lessDetail && !stagingData && token && measurement && viewLogsPermission) {
            setMeasurementLogs({ loading: true });
            API.logs.getLogsById(token, measurement._id).then(response => {
                if (response.data) {
                    setMeasurementLogs(response.data)
                }
            }).catch(error => {
                setMeasurementLogs([]);
                console.error(error)
                if (setAlert) setAlert({ open: true, messageId: error?.data?.id || "ERROR.FETCHING_DATA", severity: "error" });
            })
        }
        else return;
    }, [stagingData, token, measurement, viewLogsPermission, lessDetail, setAlert]);

    const measurementActions = [
        ((updateMeasurementPermission && !stagingData) || (stagingData && updateStagingMeasurementPermission)) && <Tooltip key="edit" title={<FormattedMessage id="EDIT" />} arrow><span>
            <IconButton size="small" disabled={disabledDeleteButton} onClick={() => setOpenEditForm(true)}><EditOutlined fontSize='small' /></IconButton>
        </span></Tooltip>,
        stagingData && updateStagingMeasurementPermission && <Tooltip key="approve" title={<FormattedMessage id="APPROVE" />} arrow><span>
            <IconButton size="small" color="success" disabled={disabledDeleteButton} onClick={() => setOpenConfirmDialog("submit")}><ThumbUpOutlined fontSize='small' /></IconButton>
        </span></Tooltip>,
        (deleteMeasurementPermission || (stagingData && deleteStagingMeasurementPermission)) && <Tooltip key="delete" title={<FormattedMessage id={stagingData ? "REJECT" : "DELETE"} />} arrow><span>
            <IconButton size="small" color="error" disabled={disabledDeleteButton} onClick={() => setOpenConfirmDialog("delete")}>{(stagingData && deleteStagingMeasurementPermission) ? <ThumbDownOutlined fontSize='small' /> : <DeleteOutlineOutlined fontSize='small' />}</IconButton>
        </span></Tooltip>
    ].filter(Boolean);

    return <>
        <ConfirmationDialog
            open={openConfirmDialog !== ""}
            content={<MeasurementDisplayBlock noPaper data={measurement} detail={stagingData && <Chip size="small" color="warning" label={<FormattedMessage id="MEASUREMENTS.STAGING_PENDING" />} />} />}
            title={<Stack direction="column" spacing={1} alignItems="flex-start">
                <FormattedMessage id={`CONFIRM_${openConfirmDialog === "submit" ? "APPROVAL" : (stagingData ? "REJECTION" : "DELETE")}`} />
                {openConfirmDialog === "delete" && <Typography variant="caption" color="warning.main" component="div">*<FormattedMessage id="STAGING_DATA_REJECT_HELPER_TEXT" /></Typography>}
            </Stack>
            }
            customButtonTitle={<FormattedMessage id={openConfirmDialog === "submit" ? "APPROVE" : (stagingData ? "REJECT" : "DELETE")} />}
            customButtonColor={openConfirmDialog === "submit" ? "success" : "error"}
            handleCancel={() => setOpenConfirmDialog("")}
            handleCustomButton={() => {
                if (openConfirmDialog === "delete") handleDelete();
                else if (openConfirmDialog === "submit") handleSubmit();
                else return;
            }}
            maxWidth="xs"
            fullWidth
        />
        <EditMeasurementForm stagingData={stagingData && updateStagingMeasurementPermission} measurement={measurement} open={openEditForm} onClose={() => setOpenEditForm(false)} closeDetails={onClose} setAlert={setAlert} onReset={onReset} />
        <CustomText messageId="ENTITY" text={measurement.entityId.name} />
        <CustomText messageId="COLLECTOR" text={measurement.source.name} />
        <CustomText messageId="COLLECTOR.TYPE" text={intl.formatMessage({ id: `${collectorTypes.find(type => type.key === measurement.source.type)?.value}_TYPE` })} />
        {!lessDetail ? <>
            {measurement.unit.length > 0 && <CustomText messageId="UNIT" text={measurement.unit} />}
            <MeasurementDisplayBlock stagingData={stagingData} data={measurement} action={measurementActions} />
            {!stagingData && viewLogsPermission && <>
                <Box component="div" pt={0.5}>
                    {measurementLogs.loading ? <LoadingData /> : null}
                    {measurementLogs?.length > 0
                        ? <Button size="small" onClick={() => setShowChanges(!showChanges)} startIcon={<RestorePageOutlined fontSize='small' />}><FormattedMessage id={!showChanges ? "SHOW_CHANGES" : "HIDE_CHANGES"} /></Button>
                        : (!measurementLogs.loading && <Typography color="text.secondary" variant="caption"><FormattedMessage id="NO_LOGS_AVAILABLE" /></Typography>)
                    }
                </Box>
                <Collapse in={showChanges}>
                    <DisplayVersion data={measurementLogs} expanded />
                </Collapse>
            </>}
        </> : measurementActions}
    </>
}

const PageComponent = ({ measurement, stagingData, lessDetail }) => {
    return <Card sx={{ width: '100%' }}>
        <CardHeader title={<FormattedMessage id="MEASUREMENT_DETAILS" />} />
        <CardContent>
            <SideDrawerComponent measurement={measurement} stagingData={stagingData} lessDetail={lessDetail} />
        </CardContent>
    </Card>
}

const MeasurementDetails = ({ measurement, sideDrawer, lessDetail, onClose, onReset, setAlert, stagingData }) => {
    return sideDrawer ? <SideDrawerComponent stagingData={stagingData} measurement={measurement} onClose={onClose} onReset={onReset} setAlert={setAlert} lessDetail={lessDetail} /> : <PageComponent measurement={measurement} onClose={onClose} stagingData={stagingData} lessDetail={lessDetail} />
}

export default MeasurementDetails;