import { useCallback, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import { Navigate, Route, Routes } from 'react-router';
import { useSelector } from 'react-redux';
import { selectInitDone, selectLanguage, selectThemeMode } from 'redux/appSlice';
import {
  MapPage,
  SplashPage,
  AdministrationPage,
  EntitiesPage,
  MeasureEntitiesPage,
  MeasurementsEntryPage,
  MetricsPage,
  NotesPage,
  TopicsPage,
  GoalsPage,
  MeasurementTypesPage,
  MetricsEntryPage,
  AlarmsPage,
  DashboardPage,
  ActivitiesPage,
  EntityActivitiesPage,
  StagingPage,
  StakeholdersPage,
  ReportsPage,
} from 'pages';
import { PageError, PageLoading, AlertSnackbar } from 'components';
import { languages } from 'lang';
import { selectLocale } from 'utils';
import { selectFeaturesConfiguration } from 'redux/configurationSlice';


const App = () => {
  const language = useSelector(selectLanguage);
  const initDone = useSelector(selectInitDone);
  const themeMode = useSelector(selectThemeMode);
  const featuresConfiguration = useSelector(selectFeaturesConfiguration);
  const [alert, setAlert] = useState({ open: false });
  const [isOnline, setIsOnline] = useState(true);

  window.addEventListener('offline', function (e) {
    setAlert({ open: true, messageId: "OFFLINE.ALERT", severity: "error" });
    setIsOnline(false);
  });
  window.addEventListener('online', function (e) {
    setAlert({ open: true, messageId: "ONLINE.ALERT", severity: "success" });
    setIsOnline(true);
  });

  useEffect(() => {
    if (themeMode === 'dark') {
      document.body.classList.remove("light");
      document.body.classList.add("dark");
    }
    else if (themeMode === 'light') {
      document.body.classList.add("light");
      document.body.classList.remove("dark");
    }
    else return () => {
      document.body.classList.remove("light");
      document.body.classList.remove("dark");
    }
  }, [themeMode]);

  const renderApp = useCallback(() => {
    if (initDone === 'failed') return <PageError messageId="ERROR.FETCHING_DATA" />;
    else if (initDone) return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="dashboard" />} />
          <Route path="dashboard" element={<DashboardPage />} />
          <Route path="map" element={<MapPage />} />
          <Route path="administration/users" element={<AdministrationPage users />} />
          <Route path="administration/roles" element={<AdministrationPage roles />} />
          <Route path="administration/userGroups" element={<AdministrationPage userGroups />} />
          <Route path="entities" element={<EntitiesPage />} />
          <Route path="entities/:entityId" element={<EntitiesPage />} />
          <Route path="notes" element={<NotesPage />} />
          <Route path="measurements" element={<MeasureEntitiesPage measurements />} />
          <Route path="measureEntities/:entityId" element={<MeasureEntitiesPage />} />
          <Route path="topics" element={<TopicsPage />} />
          <Route path="topics/:entityId" element={<TopicsPage />} />
          <Route path="metrics" element={<MetricsPage />} />
          <Route path="metrics/:entityId" element={<MetricsPage />} />
          <Route path="goals" element={<GoalsPage />} />
          <Route path="goals/:entityId" element={<GoalsPage />} />
          {featuresConfiguration.taxonomy?.enabled === true && <Route path="taxonomy" element={<ActivitiesPage />} />}
          {featuresConfiguration.taxonomy?.enabled === true && <Route path="activities" element={<EntityActivitiesPage assigned />} />}
          {featuresConfiguration.taxonomy?.enabled === true && <Route path="activities/:entityId" element={<EntityActivitiesPage />} />}
          {featuresConfiguration.stagingData?.enabled === true && <Route path="staging" element={<StagingPage />} />}
          {featuresConfiguration.stagingData?.enabled === true && <Route path="staging/:entityId" element={<StagingPage />} />}
          <Route path="stakeholders" element={<StakeholdersPage />} />
          

          <Route path="measurementsEntry" element={<MeasurementsEntryPage />} />
          <Route path="measurementsEntry/uploadFile" element={<MeasurementsEntryPage file />} />
          <Route path="measurementsEntry/metrics" element={<MetricsEntryPage />} />
          <Route path="measurementTypes" element={<MeasurementTypesPage />} />

          <Route path="alarmProfiles" element={<AlarmsPage profiles />} />
          <Route path="alarmProfiles/details/:id" element={<AlarmsPage profiles />} />
          <Route path="alarms" element={<AlarmsPage />} />
          <Route path="alarms/details/:id" element={<AlarmsPage />} />

          <Route path="reports" element={<ReportsPage />} />

          <Route path="/*" element={<SplashPage />} />
        </Routes>
      </BrowserRouter>
    )
    else return <PageLoading messageId="SPLASH.WAIT" />;

  }, [initDone, featuresConfiguration]);


  const onIntlError = (error) => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      console.error(error);
    }
  }

  return (
    <IntlProvider locale={selectLocale(language, true)} messages={languages[language]} onError={onIntlError}>
      <AlertSnackbar open={alert.open} onClose={isOnline ? () => setAlert({ ...alert, open: false }) : undefined} keepMounted={!isOnline} severity={alert.severity} messageId={alert.messageId} />
      {renderApp()}
    </IntlProvider>

  );
}
export default App;