import { WidthProvider, Responsive } from "react-grid-layout";
import { Alert, Box, Card, CardContent, CardHeader, Chip, IconButton, Stack, TextField, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { AlertSnackbar, MeasurementsTrendGraph, CollectorTypeDistributionPie, DashboardItemBarChart, SimpleCardView, TableDashboardItem, LoadingData, ConfirmationDialog } from "components";
import { useSelector } from "react-redux";
import { userSelector, permissionSelector } from "redux/userSlice";
import DashboardForm from "./DashboardForm";
import API from 'api';
import "react-grid-layout/css/styles.css";
import { AddOutlined, CancelOutlined, CropFreeOutlined, DeleteOutlined, EditOutlined, LockOpenOutlined, LockOutlined, SaveOutlined } from "@mui/icons-material";
import { FormattedMessage, useIntl } from "react-intl";
import TargetPieChart from "./TargetPieChart";


const ResponsiveGridLayout = WidthProvider(Responsive);

const DashboardView = () => {
    const { token } = useSelector(userSelector);
    const [dashboardName, setDashboardName] = useState("");
    const [dashboardId, setDashboardId] = useState(null);
    const [dashboardItems, setDashboardItems] = useState([]);
    const [layout, setLayout] = useState([]);
    const [openForm, setOpenForm] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [alert, setAlert] = useState({ open: false });
    const [editMode, setEditMode] = useState(false);
    const [reset, setReset] = useState(0);
    const theme = useTheme();
    const [disabledSaveButton, setDisabledSaveButton] = useState(true);
    const intl = useIntl();
    const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [currentElementEditing, setCurrentElementEditing] = useState(null);
    const [loading, setLoading] = useState(false);
    const onAlertClose = () => setAlert({ ...alert, open: false });
    const updateDashboardPermission = useSelector((state) => permissionSelector(state, 'update-dashboard'));
    const xs = useMediaQuery(theme.breakpoints.only('xs'));
    const sm = useMediaQuery(theme.breakpoints.only('sm'));
    const md = useMediaQuery(theme.breakpoints.only('md'));
    const lg = useMediaQuery(theme.breakpoints.only('lg'));
    const xl = useMediaQuery(theme.breakpoints.only('xl'));
    const getBreakpoint = () => {
        if (xs) return "xs";
        if (sm) return "sm";
        if (md) return "md";
        if (lg) return "lg";
        if (xl) return "xl";
    }
    const [currentBreakpoint, setCurrentBreakpoint] = useState(getBreakpoint());

    const getWidget = (dashboardItem) => {
        const source = dashboardItem.config.source;
        const sourceConfig = source[source.dataType];
        switch (dashboardItem.config.type) {
            case "chart-line": return <MeasurementsTrendGraph disableInteraction={!!editMode} entityIdArray={sourceConfig.entity}
                fixedDateFrom={dashboardItem.config.time.dateFrom} fixedDateTo={dashboardItem.config.time.dateTo}
                fixedMeasurementTypes={sourceConfig?.measurementType} fixedCollectorTypes={sourceConfig?.collector}
                fixedMetricIds={sourceConfig?.metric} itemWidth={layout.find(item => item.i === dashboardItem._id)?.w} />;
            case "chart-pie": return source.measurement ? <CollectorTypeDistributionPie entityIdArray={source.measurement.entity}
                fixedDateFrom={dashboardItem.config.time.dateFrom} fixedDateTo={dashboardItem.config.time.dateTo} /> :
                <TargetPieChart source={sourceConfig} />
            case "chart-bar": return <DashboardItemBarChart metricIds={sourceConfig.metric} dateFrom={dashboardItem.config.time.dateFrom}
                dateTo={dashboardItem.config.time.dateTo} dataType={source.dataType} category={sourceConfig.category} type={sourceConfig.type}
                status={sourceConfig.status} priority={sourceConfig.priority} goalForTarget={sourceConfig.goal} topicId={sourceConfig.topic} display={sourceConfig.display} entityId={sourceConfig.entity}
                profileIds={sourceConfig.profile} severities={sourceConfig.severity} />
            case "simple-card": return <SimpleCardView properties={source[source.dataType].properties} width={dashboardItem.position.width} itemId={source[source.dataType].id} dataType={source.dataType} entityId={source[source.dataType].entityId} />;
            case "table": return <TableDashboardItem item={sourceConfig} dataType={source.dataType} dateFrom={dashboardItem.config.time.dateFrom}
                dateTo={dashboardItem.config.time.dateTo} />
            default: return null;
        }
    }
    const onLayoutChange = (newLayout) => {
        if (JSON.stringify({ ...layout, i: newLayout.i, h: newLayout.h, w: newLayout.w, x: newLayout.x, y: newLayout.y }) !== JSON.stringify(layout)) {
            setLayout(newLayout);
            setDisabledSaveButton(false);
        }
    };

    const onBreakpointChange = (newBreakpoint, columns) => {
        if (newBreakpoint === "sm" || newBreakpoint === "xs" || newBreakpoint === "xxs") {
            setLayout(layout.map(el => ({ ...el, w: columns })))
        }
        else if (currentBreakpoint === "sm") setLayout(dashboardItems.map((item) => ({ i: layout.find(el => el.i === item._id)?.i || item._id, x: item.position.x, y: item.position.y, w: item.position.width, h: item.position.height, minW: 2, minH: 2, static: !!item.position.lock })));
        setCurrentBreakpoint(newBreakpoint);
    }

    const handleEdit = () => {
        setEditMode(true);
        setDisabledSaveButton(true);
        setCurrentElementEditing(null);
    }

    const handleCancel = () => {
        if (editMode && !disabledSaveButton) setReset(reset + 1);
        setOpenConfirm(false);
        setEditMode(false);
        setDisabledSaveButton(true);
        setCurrentElementEditing(null);
    }

    const handleRemove = (itemId) => {
        const updatedDashboardItems = dashboardItems?.filter(item => item._id !== itemId);
        const updatedLayout = layout.filter(ly => ly.i !== itemId);
        setDashboardItems(updatedDashboardItems);
        setLayout(updatedLayout);
    }

    const changeItemLock = (itemId, previousStateLock) => {
        const changedLock = !previousStateLock;
        setDashboardItems(dashboardItems.map(item => {
            if (item._id === itemId) {
                return { ...item, position: { ...item.position, lock: changedLock } }
            }
            else return item;
        }))
        setLayout(layout.map(el => {
            if (el.i === itemId) {
                return { ...el, static: changedLock }
            }
            else return el;
        }))
        setDisabledSaveButton(false);
    }

    const handleAddSpacer = () => {
        const newId = Math.floor(Math.random() * 10000000) + 1;
        const newSpacer = {
            _id: newId.toString(),
            title: "Spacer",
            showTitle: false,
            config: {
                type: "spacer",
                source: {
                    dataType: "spacer",
                    spacer: "",
                },
            },
            position: {
                x: 0,
                y: Number.MAX_SAFE_INTEGER,
                w: 2,
                h: 2,
                locked: false
            }
        }
        setDashboardItems(dashboardItems => [...dashboardItems, newSpacer]);
        setLayout(layout => [...layout, { i: newId.toString(), x: 0, y: Number.MAX_SAFE_INTEGER, w: 2, h: 2, minW: 2, minH: 2 }]);
    }

    const handleSave = () => {
        const updatedDashboardItems = layout.map(item => {
            const foundDashboardItem = dashboardItems?.find(dbItem => dbItem._id === item.i);
            const { _id, ...itemWithoudId } = foundDashboardItem;
            return {
                ...itemWithoudId,
                position: {
                    x: item.x,
                    y: item.y,
                    width: item.w,
                    height: item.h,
                    lock: item.static,
                }
            }
        })

        const newDashboardObject = {
            name: dashboardName,
            items: updatedDashboardItems,
        }

        API.dashboard.updateDashboard(token, dashboardId, newDashboardObject).then(response => {
            if (response.data) {
                setAlert({ open: true, messageId: "SUCCESS.UPDATE", severity: "success" });
                setEditMode(false);
                setDisabledSaveButton(true);
            }
        }).catch(error => {
            setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_UPDATED", severity: "error" });
            console.error(error);
            setEditMode(true);
        })
    }

    useEffect(() => {
        setLoading(true);
        API.dashboard.getDashboard(token).then(response => {
            if (response.data) {
                setDashboardId(response.data[0]?._id);
                setDashboardName(response.data[0]?.name);
                setDashboardItems(response.data[0]?.items);
                setLayout((response.data[0]?.items).map((item) => ({ i: item._id, x: item.position.x, y: item.position.y, w: item.position.width, h: item.position.height, minW: 2, minH: 2, static: !!item.position.lock })));
                setLoading(false);
            }
        }).catch(error => {
            console.error(error);
            setAlert({ open: true, messageId: error?.data?.id || "ERROR.FETCHING_DATA", severity: "error" });
            setDashboardId(null);
            setDashboardName(<Alert severity="error"><FormattedMessage id="ROW.ERROR" /></Alert>);
            setDashboardItems([]);
            setLoading(false);
        });
    }, [token, reset])

    return (
        <Box sx={{ width: "100%" }}>
            <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
            <ConfirmationDialog
                open={openConfirm}
                content={<Typography component="span" variant="h6" color="error"><FormattedMessage id="PROGRESS_WARNING" /></Typography>}
                title={<Typography component="span" variant="h6"><FormattedMessage id="CONFIRM_CANCEL" />?</Typography>}
                customButtonColor="error"
                customButtonTitle={<FormattedMessage id="YES" />}
                customCancelTitle={<FormattedMessage id="NO" />}
                handleCancel={() => setOpenConfirm(false)}
                handleCustomButton={handleCancel}
            />
            <Card sx={{ mx: 1 }} variant={editMode ? 'outlined' : 'elevation'}>
                <CardHeader sx={{ width: '100%', p: 2 }}
                    title={loading ? <LoadingData />
                        : <Box >{editMode ? <Box sx={{ width: smallScreen ? "100%" : "40%" }}>
                            <TextField
                                required
                                fullWidth
                                variant="standard"
                                id="DASHBOARD.NAME"
                                error={!dashboardName.length}
                                placeholder={intl.formatMessage({ id: "DASHBOARD.NAME" })}
                                type="text"
                                size="small"
                                value={dashboardName}
                                inputProps={{ maxLength: process.env.REACT_APP_ESG_INPUT_RESTRICTION_SMALL }}
                                onChange={(e) => { setDashboardName(e.target.value); setDisabledSaveButton(false) }}
                            />
                        </Box> : <Typography variant="h5" sx={theme => ({ fontWeight: theme.typography.fontWeightBold })} color="primary.dark">{dashboardName}</Typography>}</Box>
                    }
                    action={dashboardId !== null && updateDashboardPermission && !smallScreen && <Stack direction="row" spacing={1}>
                        {editMode && <>
                            <Chip size="small" variant="outlined"
                                icon={<CropFreeOutlined sx={{ pl: 0.5 }} color="primary" />} onClick={handleAddSpacer} label={<FormattedMessage id="ADD.SPACER" />} />
                            <Chip size="small" variant="outlined"
                                icon={<AddOutlined sx={{ pl: 0.5 }} color="primary" />} onClick={() => { setCurrentElementEditing(null); setOpenForm(true) }} label={<FormattedMessage id="ADD.NEW.WIDGET" />} />
                            <Chip size="small" disabled={disabledSaveButton} variant="outlined"
                                icon={<SaveOutlined sx={{ pl: 0.5 }} color="primary" />} onClick={handleSave} label={<FormattedMessage id="SAVE" />} />
                        </>}
                        <Chip size="small" onClick={() => {
                            if (!editMode) handleEdit();
                            else disabledSaveButton ? handleCancel() : setOpenConfirm(true);
                        }} variant="outlined"
                            icon={editMode ? <CancelOutlined sx={{ pl: 0.5 }} color="error" /> : <EditOutlined sx={{ pl: 0.5 }} color="primary" />} label={<FormattedMessage id={editMode ? "CANCEL" : "EDIT"} />} />
                    </Stack>}
                />
            </Card>
            <DashboardForm open={openForm} onClose={() => { setOpenForm(false); setCurrentElementEditing(null) }} setDashboardItems={setDashboardItems} setLayout={setLayout} existingWidget={currentElementEditing} handleDelete={() => { setOpenForm(false); handleRemove(currentElementEditing._id) }}
                setDisabledSaveButton={setDisabledSaveButton} />

            <ResponsiveGridLayout className="layout" style={{ padding: 0 }}
                useCSSTransforms={false}
                layouts={{ xl: layout, lg: layout, md: layout, sm: layout, xs: layout, xxs: layout }}
                cols={{ xl: 10, lg: 10, md: 10, sm: 5, xs: 3, xxs: 3 }}
                breakpoints={{ ...theme.breakpoints.values, xxs: 0 }}
                onLayoutChange={onLayoutChange}
                onBreakpointChange={onBreakpointChange}
                isResizable={editMode}
                isDraggable={editMode}
                isDroppable={editMode}
                resizeHandles={['s', 'w', 'e', 'n', 'sw', 'nw', 'se', 'ne']}
            >
                {dashboardItems?.map((item) => (
                    <span key={item._id}>
                        {item.config.type === "spacer" && !editMode ? <></> : <Card key={item._id} variant={editMode ? 'outlined' : 'elevation'} sx={{ overflow: 'auto', height: '100%', width: '100%', ...(item.config.type === "spacer" && { backgroundColor: 'transparent' }), cursor: (editMode && !item.position.lock) ? 'move' : 'default' }}>
                            <CardHeader title={item.showTitle || editMode ? <Box component="span" color={"text." + (item.showTitle ? "primary" : "disabled")}>{item.config.type === "spacer" ? intl.formatMessage({ id: "DASHBOARD.SPACER" }) : item.title}</Box> : null}
                                action={editMode && <>
                                    {item.config?.type !== "spacer" ? <Tooltip title={<FormattedMessage id="EDIT" />} placement="bottom" arrow>
                                        <IconButton onClick={() => { setCurrentElementEditing(item); setOpenForm(true) }}><EditOutlined /></IconButton>
                                    </Tooltip> :
                                        <Tooltip title={<FormattedMessage id="DELETE" />} placement="bottom" arrow>
                                            <IconButton onClick={() => handleRemove(item._id)}><DeleteOutlined /></IconButton>
                                        </Tooltip>}
                                    <Tooltip title={<FormattedMessage id={!item.position.lock ? "LOCK" : "UNLOCK"} />} placement="bottom" arrow>
                                        <IconButton onClick={() => changeItemLock(item._id, !!item.position.lock)}>{item.position.lock ? <LockOutlined color="primary" /> : <LockOpenOutlined />}</IconButton>
                                    </Tooltip></>}>
                            </CardHeader>
                            <CardContent>
                                {getWidget(item)}
                            </CardContent>
                        </Card>}
                    </span>
                ))}
            </ResponsiveGridLayout>
        </Box >
    );
};

export default DashboardView;