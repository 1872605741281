import { DefaultLayout } from 'layouts';
import { useSelector } from 'react-redux';
import { userSelector, permissionSelector } from 'redux/userSlice';
import { SplashPage } from "pages";
import { EmptyState } from 'layouts';
import { StagingDataView } from 'components';

const StagingPage = (props) => {

    return <EmptyState component={<StagingDataView />} />;
}

const StagingPageComponent = (props) => {
    const { isSuccess: loggedIn } = useSelector(userSelector);
    const viewStagingMeasurementPermission = useSelector((state) => permissionSelector(state, 'view-staging-measurement'));
    const viewStagingMetricsPermission = useSelector((state) => permissionSelector(state, 'view-staging-metricValue'));

    if (loggedIn && (viewStagingMeasurementPermission || viewStagingMetricsPermission)) return <DefaultLayout page={<StagingPage />} />;
    else return <SplashPage />;
};

export default StagingPageComponent;