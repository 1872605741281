import { useNavigate } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { userSelector, permissionSelector } from 'redux/userSlice';
import { selectFeaturesConfiguration } from 'redux/configurationSlice';
import { ListItemButton, ListItemIcon, ListItemText, List, Tooltip, Badge, ListSubheader, Collapse, IconButton, Divider } from '@mui/material';
import { AddchartOutlined, ViewQuiltOutlined, LanOutlined, MapOutlined, ManageAccountsOutlined, ScienceOutlined, MessageOutlined, NotificationImportantOutlined, BusinessCenterOutlined, FolderSharedOutlined, ReceiptLongOutlined, WorkspacePremiumOutlined, PendingActionsOutlined, HistoryEduOutlined, OutlinedFlag, QueryStatsOutlined, ExpandMore, ExpandLess, InsertChartOutlined, PlaceOutlined, CasesOutlined } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import API from 'api';


const MenuListItem = ({ item, selectedItem, count, open }) => {
    const [expand, setExpand] = useState(false);

    useEffect(() => {
        if (!open) setExpand(false);
    }, [open]);

    const childSelected = item.additionalItems && item.additionalItems.map(el => el.item).includes(selectedItem);

    if (childSelected && !expand && open) setExpand(true);

    return <>
        <ListItemButton key={item.item} selected={item.item === selectedItem || childSelected} onClick={() => item.onClick()}>
            <ListItemIcon>
                <Tooltip title={open ? "" : item.textElement} placement="right" arrow>
                    <Badge badgeContent={count ? count[item.badgeContent] || "" : ""} invisible={!item.badgeContent || !count || (count && !count[item.badgeContent])} color="primary" max={9}>
                        {item.iconElement}
                    </Badge>
                </Tooltip>
            </ListItemIcon>
            <ListItemText primary={item.textElement} />
            {item.additionalItems?.length && !childSelected && <IconButton onClick={e => { setExpand(!expand); e.stopPropagation(); }}>{expand ? <ExpandLess /> : <ExpandMore />}</IconButton>}
        </ListItemButton>
        {item.additionalItems?.length && <Collapse in={expand}>
            {item.additionalItems.filter(el => el.permission).map(el => {
                if (el.divider) return <Divider sx={{ ml: 2 }} key={el.divider} />
                else return <ListItemButton color="info" sx={{ ml: 2 }} alignItems='flex-start' key={el.item} onClick={() => el.onClick()}>
                    <ListItemIcon sx={{ color: el.item === selectedItem ? 'primary.main' : 'text.secondary' }}>
                        {el.iconElement}
                    </ListItemIcon>
                    <ListItemText primary={el.textElement} />
                </ListItemButton>
            })}
        </Collapse >}
    </>
}

const MenuItems = (props) => {
    const { selectedItem, open, setAlert } = props;
    const { token } = useSelector(userSelector);
    const [count, setCount] = useState(null);
    const navigate = useNavigate();
    const viewDashboardPermission = useSelector((state) => permissionSelector(state, 'view-dashboard'));
    const viewNotesPermission = useSelector((state) => permissionSelector(state, 'view-notes'));
    const createMeasurementPermission = useSelector((state) => permissionSelector(state, 'create-measurement'));
    const viewStagingMeasurementPermission = useSelector((state) => permissionSelector(state, 'view-staging-measurement'));
    const viewStagingMetricPermission = useSelector((state) => permissionSelector(state, 'view-staging-metricValue'));
    const createStagingMeasurementPermission = useSelector((state) => permissionSelector(state, 'create-staging-measurement'));
    const createStagingMetricPermission = useSelector((state) => permissionSelector(state, 'create-staging-metricValue'));
    const viewMetricPermission = useSelector((state) => permissionSelector(state, 'view-metric'));
    const updateMetricPermission = useSelector((state) => permissionSelector(state, 'update-metric'));
    const viewTopicPermission = useSelector((state) => permissionSelector(state, 'view-topic'));
    const viewGoalPermission = useSelector((state) => permissionSelector(state, 'view-goal'));
    const viewMeasurementPermission = useSelector((state) => permissionSelector(state, 'view-measurement'));
    const viewReportPermission = useSelector((state) => permissionSelector(state, 'view-reports'));
    const viewMeasurementTypesPermission = useSelector((state) => permissionSelector(state, 'view-measurementType'));
    const viewActivityPermission = useSelector((state) => permissionSelector(state, 'view-activity'));
    const viewAlarmsPermission = useSelector((state) => permissionSelector(state, 'view-alarms'));
    const viewAlarmProfilesPermission = useSelector((state) => permissionSelector(state, 'view-alarmProfiles'));
    const viewStakeholdersPermission = useSelector((state) => permissionSelector(state, 'view-stakeholders'));
    const viewUsersPermission = useSelector((state) => permissionSelector(state, 'view-users'));
    const viewRolesPermission = useSelector((state) => permissionSelector(state, 'view-role'));
    const featuresConfiguration = useSelector(selectFeaturesConfiguration);

    const navArray = [
        {

            subheader: <FormattedMessage id="MANAGEMENT" />,
            permission: true,
        },

        {
            item: 'DashboardPage',
            onClick: () => navigate("/dashboard"),
            iconElement: <ViewQuiltOutlined />,
            textElement: <FormattedMessage id="DASHBOARD" />,
            permission: viewDashboardPermission,
        },
        {
            item: 'EntitiesPage',
            onClick: () => navigate("/entities"),
            iconElement: <LanOutlined />,
            textElement: <FormattedMessage id="ORGANIZATION" />,
            permission: true,
            additionalItems: [{
                item: 'EntitiesPage',
                onClick: () => navigate("/entities"),
                iconElement: <PlaceOutlined fontSize='small' />,
                textElement: <FormattedMessage id="ENTITIES" />,
                permission: true,
            },
            {
                item: 'TopicsPage',
                onClick: () => navigate("/topics"),
                iconElement: <HistoryEduOutlined fontSize='small' />,
                textElement: <FormattedMessage id="ESG.TOPICS" />,
                permission: viewTopicPermission,
            },
            {
                item: 'GoalsPage',
                onClick: () => navigate("/goals"),
                iconElement: <OutlinedFlag fontSize='small' />,
                textElement: <FormattedMessage id="ESG.GOALS" />,
                permission: viewGoalPermission,
            },
            {
                item: 'MetricsPage',
                onClick: () => navigate("/metrics"),
                iconElement: <QueryStatsOutlined fontSize='small' />,
                textElement: <FormattedMessage id="ESG.METRICS" />,
                permission: viewMetricPermission,
            },
            (viewActivityPermission || viewMeasurementPermission) && { divider: 1 },
            {
                item: 'EntityActivitiesPage',
                onClick: () => navigate("/activities"),
                iconElement: <BusinessCenterOutlined fontSize='small' />,
                textElement: <FormattedMessage id="ACTIVITIES" />,
                permission: viewActivityPermission,
            },
            {
                item: 'MeasureEntitiesPage',
                onClick: () => navigate("/measurements"),
                iconElement: <InsertChartOutlined fontSize='small' />,
                textElement: <FormattedMessage id="MEASUREMENTS" />,
                permission: viewMeasurementPermission,
            },].filter(Boolean)
        },
        {
            item: 'MapPage',
            onClick: () => navigate("/map"),
            iconElement: <MapOutlined />,
            textElement: <FormattedMessage id="MAP" />,
            permission: true,
        },

        {
            subheader: <FormattedMessage id="DATAS" />,
            permission: viewNotesPermission
                || viewAlarmsPermission
                || viewAlarmProfilesPermission
                || createStagingMeasurementPermission
                || updateMetricPermission
                || viewStagingMeasurementPermission
                || viewStagingMetricPermission
        },

        {
            item: 'MeasurementsEntryPage',
            onClick: () => navigate("/measurementsEntry"),
            iconElement: <AddchartOutlined />,
            textElement: <FormattedMessage id="MEASUREMENTS_ENTRY" />,
            permission: createMeasurementPermission || updateMetricPermission || (viewMetricPermission && (createStagingMeasurementPermission || createStagingMetricPermission) && featuresConfiguration.stagingData?.enabled === true)
        },

        {
            item: 'StagingPage',
            onClick: () => navigate("/staging"),
            iconElement: <PendingActionsOutlined />,
            textElement: <FormattedMessage id="MEASUREMENTS.STAGING" />,
            badgeContent: 'staging',
            permission: (viewStagingMeasurementPermission || viewStagingMetricPermission) && featuresConfiguration.stagingData?.enabled === true
        },

        {
            item: 'NotesPage',
            onClick: () => navigate("/notes"),
            iconElement: <MessageOutlined />,
            textElement: <FormattedMessage id="NOTES" />,
            badgeContent: 'notes',
            permission: viewNotesPermission,
        },

        {
            item: 'AlarmsPage',
            onClick: () => navigate("/alarms"),
            iconElement: <NotificationImportantOutlined />,
            textElement: <FormattedMessage id="ALARMS" />,
            badgeContent: 'alarms',
            permission: viewAlarmsPermission || viewAlarmProfilesPermission,
        },

        {
            item: 'ReportsPage',
            onClick: () => navigate("/reports"),
            iconElement: <ReceiptLongOutlined />,
            textElement: <FormattedMessage id="REPORTS" />,
            permission: viewReportPermission
        },

        {
            subheader: <FormattedMessage id="INFORMATION" />,
            permission: viewMeasurementTypesPermission || viewActivityPermission || viewStakeholdersPermission,
        },

        {
            item: 'MeasurementTypesPage',
            onClick: () => navigate("/measurementTypes"),
            iconElement: <ScienceOutlined />,
            textElement: <FormattedMessage id="MEASUREMENT_TYPES" />,
            permission: viewMeasurementTypesPermission,
        },

        {
            item: 'ActivitiesPage',
            onClick: () => navigate("/taxonomy"),
            iconElement: <CasesOutlined />,
            textElement: <FormattedMessage id="TAXONOMY" />,
            permission: viewActivityPermission,
        },

        {
            item: 'StakeholdersPage',
            onClick: () => navigate("/stakeholders"),
            iconElement: <FolderSharedOutlined />,
            textElement: <FormattedMessage id="STAKEHOLDERS" />,
            permission: viewStakeholdersPermission
        },
    ];

    const usersArray = [
        {
            item: 'AdministrationPage',
            iconElement: viewUsersPermission ? <ManageAccountsOutlined /> : <WorkspacePremiumOutlined />,
            textElement: <FormattedMessage id={viewUsersPermission ? "USERS" : "ROLES"} />
        },

    ];

    useEffect(() => {
        if (token) {
            API.configuration.getNotifications(token).then((items) => {
                if (items.data) setCount({
                    ...items.data,
                    staging: items.data.stagingData + items.data.stagingMetricData
                });
                else setCount(null);
            }).catch((error) => {
                setCount({});
                console.error(error);
                setAlert({ open: true, messageId: error?.data?.id || "ERROR.FETCHING_NOTIFICATIONS", severity: "error" });
            })
        }
    }, [token, open, setAlert]);

    return <>
        <List component="nav" style={{ height: 1 }}>
            {navArray.map((item, index) => {
                if (!item.permission) return null;
                else if (item.subheader) return <Collapse key={index} component="li" in={open}>
                    <ListSubheader component="span">{item.subheader}</ListSubheader>
                </Collapse>;
                else return <MenuListItem key={index} item={item} selectedItem={selectedItem} count={count} open={open} />;
            })}
            {(viewUsersPermission || viewRolesPermission) && <Collapse component="li" in={open}>
                <ListSubheader component="span"><FormattedMessage id="ADMIN" /></ListSubheader>
            </Collapse>}
            {(viewUsersPermission || viewRolesPermission) && usersArray.map((item) => (
                <ListItemButton key={item.item} selected={item.item === selectedItem} onClick={() => navigate('/administration/' + (viewUsersPermission ? "users" : "roles"))}>
                    <ListItemIcon>
                        <Tooltip title={open ? "" : item.textElement} placement="right" arrow>
                            {item.iconElement}
                        </Tooltip>
                    </ListItemIcon>
                    <ListItemText primary={item.textElement} />
                </ListItemButton>
            ))}
            <ListItemButton disabled />
        </List>
    </>
};

export default MenuItems;