
const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode?.querySelector('div');

    if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.style.background = 'rgba(99, 99, 99, 0.95)';
        tooltipEl.style.borderRadius = '3px';
        tooltipEl.style.color = 'white';
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.width = '250px';
        tooltipEl.style.left = 0;
        tooltipEl.style.top = 0;
        tooltipEl.style.zIndex = Number.MAX_SAFE_INTEGER.toFixed();
        tooltipEl.style.transition = 'all .1s ease';

        const table = document.createElement('table');
        table.style.margin = '0px';

        tooltipEl?.appendChild(table);
        chart.canvas.parentNode?.appendChild(tooltipEl);
    }

    return tooltipEl;
};

export default function externalTooltipHandler(context, specialCase, smallScreen) {
    // Tooltip Element
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart, smallScreen);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
    }

    // Set Text
    if (tooltip.body) {
        const titleLines = tooltip.title || [];
        const bodyLines = tooltip.body.map((b, index) => b.lines + (tooltip.dataPoints[index]?.raw?.unit || ""));

        const tableHead = document.createElement('thead');

        titleLines.forEach(title => {
            const tr = document.createElement('tr');
            tr.style.borderWidth = 0;

            const th = document.createElement('th');
            th.style.borderWidth = 0;
            const text = document.createTextNode(title);

            th.appendChild(text);
            tr.appendChild(th);
            tableHead.appendChild(tr);
        });

        const tableBody = document.createElement('tbody');
        bodyLines.forEach((body, i) => {

            if (i === 4) {
                const td = document.createElement('td');
                const tr = document.createElement('tr');
                const text = document.createTextNode(`+ ${bodyLines.length - 4} ...`);
                td.appendChild(text);
                tr.appendChild(td);
                tableBody.appendChild(tr);
                return;
            }
            else if (i > 4) {
                return
            }
            else {
                const colors = tooltip.labelColors[i];
                const span = document.createElement('span');
                span.style.background = colors.borderColor;
                span.style.borderColor = colors.borderColor;
                span.style.borderWidth = colors.borderWidth;
                span.style.borderRadius = '50px';
                span.style.borderWidth = '2px';
                span.style.opacity = 1;
                span.style.marginRight = '10px';
                span.style.height = '14px';
                span.style.width = '14px';
                span.style.display = 'inline-block';

                const tr = document.createElement('tr');
                tr.style.backgroundColor = 'inherit';
                tr.style.borderWidth = 0;

                const td = document.createElement('td');
                td.style.borderWidth = 0;

                const bodyTextArray = body.split(":");

                const textLabel = bodyTextArray.splice(0, bodyTextArray.length - 1);
                const textValue = bodyTextArray.splice(-1, 1);

                const text = document.createTextNode(textLabel);
                const value = document.createTextNode(textValue);
                const labelSpan = document.createElement('div');
                const valueSpan = document.createElement('div');

                valueSpan.classList.add('MuiTypography-body1');
                valueSpan.style.paddingLeft = '24px';

                labelSpan.classList.add('MuiTypography-caption');
                labelSpan.style.whiteSpace = 'nowrap';
                labelSpan.style.overflow = 'hidden';
                labelSpan.style.textOverflow = 'ellipsis';
                labelSpan.style.maxWidth = '200px';
                labelSpan.style.display = 'inline-block';
                labelSpan.style.textAlign = 'center';
                labelSpan.style.transform = 'translateY(25%)';


                // color circle
                td.appendChild(span);
                // label
                labelSpan.append(text);
                td.appendChild(labelSpan);
                // value
                valueSpan.append(value);
                td.appendChild(valueSpan);

                tr.appendChild(td);
                tableBody.appendChild(tr);
            }
        });

        const tableRoot = tooltipEl?.querySelector('table');

        if (!tableRoot) return;

        // Remove old children
        while (tableRoot.firstChild) {
            tableRoot.firstChild.remove();
        }

        // Add new children
        tableRoot.appendChild(tableHead);
        tableRoot.appendChild(tableBody);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
    const position = chart.canvas.getBoundingClientRect();

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = (positionX + tooltip.caretX) + 'px';
    tooltipEl.style.top = ((specialCase ? position.top : positionY) + tooltip.caretY) + 'px';
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
};
