import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from "react-redux";
import { setFontSize, setFontWeight, setFontFamily } from "redux/appSlice";
import { Box, Button, ButtonGroup, Divider, ListItemIcon, ListItemText, Menu, MenuItem, Slider, Stack, Typography } from '@mui/material';
import { RestartAltOutlined, TextDecrease, TextIncrease, TableRows, FontDownloadOutlined } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';


const FormatSelect = (props) => {
    const { anchorEl, format, setFormat, setChange } = props;
    const app = useSelector((state) => state.app);
    const dispatch = useDispatch();
    const [fSize, setFSize] = useState(app.fontSize);
    const [fWeight, setFWeight] = useState(app.fontWeight);
    const [fFamily, setFFamily] = useState(app.fontFamily);

    const handleClear = () => {
        setFWeight(0)
        dispatch(setFontWeight(0))
        setFSize(14)
        dispatch(setFontSize(14))
        setFFamily('Roboto');
        dispatch(setFontFamily('Roboto'))
    };

    useEffect(() => {
        // default values: Size = 14, Weight = 0
        if (typeof setChange !== 'function') return;
        else if (app.fontSize === 14 && app.fontWeight === 0 && app.fontFamily === 'Roboto') setChange(false);
        else setChange(true);
    }, [app.fontSize, app.fontWeight, app.fontFamily, setChange])

    const handleFontChange = (defaultFlag) => {
        if (defaultFlag) {
            setFFamily('Roboto')
            dispatch(setFontFamily('Roboto'))
        }
        else {
            setFFamily('Lexend')
            dispatch(setFontFamily('Lexend'))
        }
    }

    return (
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={format}
            onClose={() => setFormat(false)}
        >
            <MenuItem>
                <Stack direction="column" width='100%'>
                    <Typography gutterBottom><FormattedMessage id="FONT_SIZE" /></Typography>
                    <Stack spacing={2} direction="row" alignItems="center">
                        <TextDecrease />
                        <Slider aria-label="font-size"
                            size='small'
                            sx={{ width: '100%' }}
                            defaultValue={fSize}
                            value={fSize}
                            onChange={(event, value) => {
                                setFSize(value)
                            }}
                            onChangeCommitted={(event, value) => {
                                dispatch(setFontSize(value))
                            }}
                            min={10}
                            max={20}
                            step={2}
                            marks
                            valueLabelDisplay='auto' />
                        <TextIncrease />
                    </Stack>
                </Stack>
            </MenuItem>
            <Divider />
            <MenuItem>
                <Stack direction="column" width='100%'>
                    <Typography gutterBottom><FormattedMessage id="FONT_WEIGHT" /></Typography>
                    <Stack spacing={2} direction="row" alignItems="center">
                        <MenuIcon />
                        <Slider aria-label="font-weight"
                            size='small'
                            sx={{ width: '100%' }}
                            defaultValue={fWeight}
                            value={fWeight}
                            onChange={(event, value) => {
                                setFWeight(value)
                            }}
                            onChangeCommitted={(event, value) => {
                                dispatch(setFontWeight(value))
                            }}
                            min={-100}
                            max={200}
                            step={50}
                            marks
                            valueLabelDisplay='auto' />
                        <TableRows />
                    </Stack>
                </Stack>
            </MenuItem>
            <Divider />
            <Box p={1} mx={1} display="flex" justifyContent="space-between" alignItems='center' gap={1}>
                <FontDownloadOutlined />
                <ButtonGroup size="small">
                    <Button onClick={() => handleFontChange(true)} variant={fFamily === 'Roboto' ? "contained" : "outlined"}><FormattedMessage id="FONT.DEFAULT" /></Button>
                    <Button onClick={() => handleFontChange(false)} variant={fFamily === 'Lexend' ? "contained" : "outlined"}><FormattedMessage id="FONT.DYSLEXIC" /></Button>
                </ButtonGroup>
            </Box>
            <Divider sx={{ my: 1 }} />
            <MenuItem onClick={handleClear} sx={{ backgroundColor: 'transparent' }} disabled={fSize === 14 && fWeight === 0 && fFamily === 'Roboto'}>
                <ListItemIcon>
                    <RestartAltOutlined color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText disableTypography>
                    <Typography variant="button" color="primary">
                        <FormattedMessage id="RESET" />
                    </Typography>
                </ListItemText>
            </MenuItem>
        </Menu>
    );
};

export default FormatSelect;