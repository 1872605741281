import { useState } from 'react';
import { useSelector } from "react-redux";
import { selectThemeMode, selectFontSize, selectFontWeight } from 'redux/appSlice';
import { AppBar, Footer, NavBar } from 'components';
import themes from "../themes/Themes";
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, Box, Container, useTheme, useMediaQuery } from '@mui/material';
import { PublicOutlined } from '@mui/icons-material';

const APP_TITLE = process.env.REACT_APP_TITLE;
const SHORT_APP_TITLE = process.env.REACT_APP_TITLE_ACRONYM;

const PublicLayout = (props) => {
    const theme = useTheme();
    const smallMediaScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const mode = useSelector(selectThemeMode);
    const fontSize = useSelector(selectFontSize);
    const fontWeight = useSelector(selectFontWeight);
    const { page } = props;
    const [open, setOpen] = useState(false);
    const toggleDrawer = () => {
        setOpen(!open);
    };

    const TOOLBAR_HEIGHT = `${theme.mixins.toolbar.minHeight + 5}px`;
    return (
        <ThemeProvider theme={themes.light(fontSize, fontWeight)}>
            <AppBar open={open} login title={<span>{smallMediaScreen ? APP_TITLE : SHORT_APP_TITLE} <PublicOutlined fontSize='small' /> </span>} toggleDrawer={toggleDrawer} />
            <Box className={mode} sx={{ display: 'flex', mt: TOOLBAR_HEIGHT }}>
                <CssBaseline />
                <NavBar open={open} selectedItem={page.type.name} />
                <Box
                    component="main"
                    sx={{
                        backgroundColor: theme.palette.grey[100],
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Container maxWidth={false} sx={{ flexGrow: 1 }}>
                        {page}
                    </Container>
                    <Footer />
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default PublicLayout;