import React, { useState } from 'react';
import { Button, CardHeader, Dialog, DialogActions, DialogContent, Divider, useMediaQuery } from '@mui/material';
import { MeasurementInputField } from 'components';
import { useSelector } from 'react-redux';
import { selectMeasurementsTypesConf } from 'redux/configurationSlice';
import { FormattedMessage, useIntl } from 'react-intl';
import API from 'api';
import { userSelector } from 'redux/userSlice';

const EditMeasurementForm = ({ open, onClose, measurement, setAlert, onReset, closeDetails, stagingData }) => {
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const measurementTypesConf = useSelector(selectMeasurementsTypesConf);
    const [measurementValue, setMeasurementValue] = useState(measurement?.value);
    const [unit, setUnit] = useState(measurement?.unit);
    const measurementType = measurementTypesConf.find(el => el.key === measurement?.measurementTypeKey);
    const intl = useIntl();
    const { token } = useSelector(userSelector);
    const [disabledSubmitButton, setDisabledSubmitButton] = useState(false);
    const [error, setError] = useState(false);

    const handleClose = () => {
        onClose();
        setMeasurementValue(measurement?.value);
        setUnit(measurement?.unit);
    }

    const onChange = (item) => {
        setMeasurementValue(item.value);
        setUnit(item.unit)
    }

    const changeDetected = () => {
        return unit !== measurement?.unit || (measurementValue?.toString() && measurementValue?.toString() !== measurement?.value.toString());
    }

    const handleSubmit = () => {
        setDisabledSubmitButton(true);
        API[stagingData ? "stagingMeasurements" : "measurements"].updateMeasurement(measurement._id, { ...measurement, unit: unit, value: measurementValue }, token).then(response => {
            if (response.data) {
                setAlert({ open: true, messageId: "SUCCESS.UPDATE", severity: "success" })
                onReset({ ...response.data, actionType: 'edit' });
                onClose();
                if (typeof closeDetails === 'function') closeDetails();
            }
            setDisabledSubmitButton(false);
        }).catch(error => {
            console.error(error);
            setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_UPDATED", severity: "error" });
            setDisabledSubmitButton(false);
        })
    }

    return <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm" fullScreen={smallScreen}>
        <CardHeader
            title={intl.formatMessage({ id: "MEASUREMENTS.EDIT" })}
            subheader={stagingData && <FormattedMessage id="MEASUREMENTS.STAGING_PENDING" />}
        />
        <Divider />
        <DialogContent >
            <MeasurementInputField noPadding variant="outlined" defaultValue={measurementValue} onChange={onChange} type={measurementType} fromEdit={true} selectedUnit={unit} onError={setError} />
        </DialogContent>
        <Divider />
        <DialogActions>
            <Button color="warning" onClick={handleClose}><FormattedMessage id="CANCEL" /></Button>
            <Button disabled={error || disabledSubmitButton || !changeDetected()} onClick={handleSubmit}><FormattedMessage id={stagingData ? "EDIT_SUBMIT" : "EDIT"} /></Button>
        </DialogActions>

    </Dialog>
}

export default EditMeasurementForm