import { FastRewindOutlined, AddOutlined } from "@mui/icons-material";
import { Button, CardActions, CardContent, Chip, Grid, Typography } from "@mui/material";
import { MeasurementDisplayBlock } from 'components';
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router";

export default function MeasurementsReportCard({ entityName, userName, measurements, errors, onReset, skipErrors, metricReport }) {
    const navigate = useNavigate();

    return <>
        <CardContent>
            <Typography variant="caption" color="primary"><FormattedMessage id="ENTITY" /></Typography>
            <Typography variant="h6" >{entityName}</Typography>
            <br />
            <Typography variant="caption" color="primary"><FormattedMessage id="MEASURED_BY" /></Typography>
            <Typography variant="h6" >{userName}</Typography>
            {errors?.array && errors.array.length > 0 && <>
                <br />
                <Typography variant="subtitle2" color="error"><FormattedMessage id="ERRORS" /></Typography>
                {skipErrors && <Chip size="small" color="warning" label={<FormattedMessage id="WARNING.SKIPPED" />} sx={{ ml: 0.5, mb: 0.5 }} />}
                <Grid container direction="row" spacing={2}>
                    {errors.array.map((measurement, index) => <Grid key={"errors-" + index} item>
                        <MeasurementDisplayBlock error key={"measurement-" + index} data={measurement} />
                    </Grid>)}
                </Grid>
            </>}
            <br />
            <Typography variant="caption" color="primary"><FormattedMessage id="MEASUREMENTS" /></Typography>
            {!skipErrors && errors?.array && errors.array.length > 0 && <Chip size="small" color="warning" label={<FormattedMessage id="WARNING.NOT_SAVED" />} sx={{ ml: 0.5, mb: 0.5 }} />}
            <Grid container direction="row" spacing={2}>
                {measurements.map((measurement, index) => <Grid key={"measurement-" + index} item>
                    <MeasurementDisplayBlock data={measurement} />
                </Grid>)}
            </Grid>
        </CardContent>
        <CardActions>
            <Button startIcon={<AddOutlined />} onClick={onReset}><FormattedMessage id="NEW_ENTRY" /></Button>
            {Boolean(metricReport) && <Button endIcon={<FastRewindOutlined />} color="warning" onClick={() => navigate(-1)}><FormattedMessage id="GO_BACK" /></Button>}
        </CardActions>
    </>
} 