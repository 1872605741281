import { useEffect, useState } from "react";
import { ConfirmationDialog, DialogFormTitle } from "components";
import { GroupHeader, GroupItems } from "components/unitSelectorField/UnitSelectorField";
import { DeleteOutlineOutlined, DescriptionOutlined } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, FormControl, FormLabel, TextField, Stack, MenuItem, useMediaQuery, Typography, IconButton, Divider, Box, Switch, Autocomplete, RadioGroup, Radio, FormControlLabel, FormHelperText, Chip, ListItem, ListItemText, Tooltip } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { userSelector, permissionSelector } from "redux/userSlice";
import { updateTopicState, addTopic, removeTopic, selectTopics } from "redux/topicSlice";
import { selectPriorityTypes, selectMetricTypesConf, selectTopicTemplates, selectTopicReportTemplates, selectDataFrequencyInterval, selectReportIntervalTypesConf } from "redux/configurationSlice";
import { selectEntities } from "redux/entitySlice";
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { enGB, hr } from "date-fns/locale";
import API from 'api';

export default function TopicForm({ open, onClose, topic, parentId, setAlert, selectedNames }) {
    const dispatch = useDispatch();
    const intl = useIntl();
    const entityId = useParams().entityId;
    const { token } = useSelector(userSelector);
    const entities = useSelector(selectEntities);
    const topics = useSelector(selectTopics);
    const [entity, setEntity] = useState(null);
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const priorityTypes = useSelector(selectPriorityTypes);
    const metricTypes = useSelector(selectMetricTypesConf);
    const templates = useSelector(selectTopicTemplates);
    const reportTemplates = useSelector(selectTopicReportTemplates);
    const [name, setName] = useState("");
    const [type, setType] = useState("");
    const [priority, setPriority] = useState("");
    const [confirmation, setConfirmation] = useState(false);
    const [disabledSubmitButton, setDisabledSubmitButton] = useState(false);
    const [deleteChildrenFlag, setDeleteChildrenFlag] = useState(false);
    const [mode, setMode] = useState("custom");
    const [template, setTemplate] = useState([]);
    const [dateFrom, setDateFrom] = useState(Date.now());
    const [reportInterval, setReportInterval] = useState("");
    const [dataFrequencyInterval, setDataFrequencyInterval] = useState("");

    const reportIntervalTypes = useSelector(selectReportIntervalTypesConf);
    const dataFrequencyIntervalTypes = useSelector(selectDataFrequencyInterval);

    const deleteTopicPermission = useSelector((state) => permissionSelector(state, 'delete-topic'));
    const createMetricPermission = useSelector((state) => permissionSelector(state, 'create-metric'));

    useEffect(() => {
        if (topic && open) {
            setName(topic.name);
            setType(topic.type);
            setPriority(topic.priority.key);
            setEntity(entities[topic.entityId]);
        }
    }, [topic, entities, open]);

    const handleClose = () => {
        setTemplate([]);
        setName("");
        setType("");
        setPriority("");
        setEntity(null);
        setDisabledSubmitButton(false);
        setDeleteChildrenFlag(false);
        setMode("custom");
        setDateFrom(Date.now());
        setReportInterval("");
        setDataFrequencyInterval("");
        onClose();
    }

    const handleError = (error, errorDetail) => {
        console.error(error);
        if (setAlert) setAlert({ open: true, messageId: error?.data?.id || "ERROR." + errorDetail, severity: "error" });
        setDisabledSubmitButton(false);
    }

    const onSubmit = () => {
        setDisabledSubmitButton(true);
        const reqObject = {
            ...(entityId && { entityId: entityId, entityIdModel: 'entity' }),
            ...(entity && { entityId: entity._id, entityIdModel: 'entity' }),
            ...topic,
            ...(!topic && parentId && { parentId: parentId }),
            ...(mode === "template" && template !== null && {
                templates: template,
                dateFrom: new Date(dateFrom),
                interval: reportInterval,
                intervalFrequency: dataFrequencyInterval
            }),
            name: name,
            type: type,
            priority: priority,
        }
        if (topic) API.topic.updateTopic(topic._id, reqObject, token)
            .then((items) => {
                if (setAlert) setAlert({ open: true, messageId: "SUCCESS.UPDATE", severity: "success" });
                dispatch(updateTopicState(items.data))
                handleClose();
                setDisabledSubmitButton(false);
            }).catch((e) => handleError(e, "NOT_UPDATED"));

        else API.topic.createTopic(reqObject, token, mode === "template")
            .then((items) => {
                if (setAlert) setAlert({ open: true, messageId: "SUCCESS.CREATE", severity: "success" });
                dispatch(addTopic(items.data));
                handleClose();
                setDisabledSubmitButton(false);
            }).catch((e) => handleError(e, "NOT_CREATED"));
    }

    const onDelete = () => {
        if (topic) API.topic.deleteTopic(topic._id, token, deleteChildrenFlag)
            .then((items) => {
                setConfirmation(false);
                if (setAlert) setAlert({ open: true, messageId: "SUCCESS.DELETE", severity: "success" });
                dispatch(removeTopic(items.data))
                handleClose();
                setDisabledSubmitButton(false);
            }).catch((e) => handleError(e, "NOT_DELETED"))
    }

    const invalidName = (entityId && selectedNames && !disabledSubmitButton) ? (!topic && selectedNames.includes(name)) : false;

    const deleteDialogContent = <Stack spacing={1}>
        <Box><Typography component="span" variant="h6" color="error.dark">{topic?.name}</Typography></Box>
        {topic && topic.connections?.childrenArray?.length > 0 && <Box><FormattedMessage id="COUNT_CHILDREN.ESTIMATE" /> <FormLabel sx={{ color: 'primary.main', typography: 'body1' }}>{topic.connections.childrenArray.length + 1}</FormLabel></Box>}
        {topic && topic.connections?.childrenArray?.length > 0 && <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FormattedMessage id="DELETE_ALL_CHILDREN" />
            <FormLabel sx={{ ml: 0.5 }}>
                <Switch size="small" checked={deleteChildrenFlag} onChange={() => setDeleteChildrenFlag(!deleteChildrenFlag)} />
                <Typography sx={{ cursor: 'pointer' }} display="inline" color={deleteChildrenFlag ? "primary" : "text.disabled"}><FormattedMessage id={deleteChildrenFlag ? "YES" : "NO"} /></Typography>
            </FormLabel>
        </Box>}
    </Stack>

    useEffect(() => {
        if (topic) {
            setDisabledSubmitButton(topic.priority.key === priority && topic.type === type);
        }
    }, [topic, priority, type]);

    const isDisabled = () => {
        const isEntity = !entityId ? entity === null : false
        if (disabledSubmitButton) return true;
        else if (mode === "template") return template.length === 0 || reportInterval === "" || dataFrequencyInterval === "" || isEntity;
        else return (name === "" || type === "" || priority === "" || invalidName || isEntity)
    };

    const findIndex = () => {
        const foundReportInterval = reportIntervalTypes.find(type => type.key === reportInterval)
        if (foundReportInterval) return reportIntervalTypes.indexOf(foundReportInterval);
        else return -1;
    }

    const disableTemplates = entityId && templates.every(el => selectedNames.includes(el));

    if (!createMetricPermission && mode === "template") setMode("custom");

    return <>
        <ConfirmationDialog
            open={confirmation}
            title={<FormattedMessage id="ESG.TOPIC.CONFIRM_DELETE" />}
            content={deleteDialogContent}
            customButtonTitle={<FormattedMessage id="DELETE" />}
            customButtonColor="error"
            handleCancel={() => { setConfirmation(false); setDeleteChildrenFlag(false); }}
            handleCustomButton={onDelete}
            disabled={topic && (topic.connections?.childrenArray?.length > 0) && !deleteChildrenFlag}
        />
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth fullScreen={smallScreen}>
            <DialogFormTitle
                mainTitle={<FormattedMessage id={topic ? "ESG.TOPIC.EDIT_FORM" : "ESG.TOPIC.FORM"} />}
                action={deleteTopicPermission && Boolean(topic) && <IconButton color="error" size="small" onClick={() => setConfirmation(true)}><DeleteOutlineOutlined /></IconButton>}
                disableHelpText={topic}
            />
            <Divider />
            <DialogContent>
                <Stack direction="column" spacing={1}>
                    {!entityId && <FormControl>
                        <FormLabel required={!topic}><FormattedMessage id="ENTITY" /></FormLabel>
                        {!topic
                            ? <Autocomplete
                                id="select-entity"
                                options={Object.values(entities)}
                                getOptionLabel={(entity) => entity ? entity.name : ""}
                                isOptionEqualToValue={(option, value) => value === "" || option.id === value.id}
                                value={entity}
                                onChange={(e, entity) => {
                                    setEntity(entity);
                                    if (template) setTemplate([]);
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} key={Object.values(entities).map(entity => entity._id)} />
                                )}
                                size="small"
                                fullWidth
                            />
                            : <Typography variant="h6" color="primary">{entities[topic.entityId]?.name}</Typography>
                        }
                    </FormControl>}
                    {!topic && !parentId && createMetricPermission && <FormControl>
                        <RadioGroup
                            row
                            aria-labelledby="select-form-mode"
                            name="form-mode"
                            value={mode}
                            onChange={(e, val) => {
                                setTemplate([]);
                                setName(""); setType(""); setPriority("");
                                setMode(val);
                            }}
                        >
                            <FormControlLabel value="custom" control={<Radio />} label={<FormattedMessage id="CUSTOM" />} />
                            <FormControlLabel disabled={disableTemplates} value="template" control={<Radio />} label={<span>
                                <FormattedMessage id="FROM_TEMPLATE" /> {disableTemplates && <Typography component="span" variant="caption">(<FormattedMessage id="NO_OPTIONS_SELECT" />)</Typography>}
                            </span>} />
                        </RadioGroup>
                    </FormControl>}
                    {mode === "custom" && <Stack direction="column" spacing={2}>
                        <FormControl>
                            <FormLabel error={invalidName} required={!topic}><FormattedMessage id="NAME" /></FormLabel>
                            {topic ? <Typography variant="h6" color="primary">{topic.name}</Typography>
                                : <TextField
                                    required
                                    fullWidth
                                    variant="outlined"
                                    id="NAME"
                                    error={invalidName}
                                    helperText={invalidName && <FormattedMessage id="ESG.TOPIC.NAME_TAKEN" />}
                                    type="text"
                                    size="small"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    inputProps={{ maxLength: process.env.REACT_APP_ESG_INPUT_RESTRICTION_SMALL }}
                                />}
                        </FormControl>
                        <FormControl>
                            <FormLabel required={!topic}><FormattedMessage id="ESG.TOPIC.TYPE" /></FormLabel>
                            <TextField
                                select
                                size="small"
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                            >
                                {metricTypes.map(type => {
                                    return <MenuItem key={type.key} value={type.key}><FormattedMessage id={"ESG.TYPE." + type.key} /></MenuItem>
                                })}
                            </TextField>
                        </FormControl>
                        <FormControl>
                            <FormLabel required={!topic}><FormattedMessage id="PRIORITY" /></FormLabel>
                            <TextField
                                select
                                size="small"
                                value={priority}
                                onChange={(e) => setPriority(e.target.value)}
                            >
                                {priorityTypes.map(type => {
                                    return <MenuItem key={type.key} value={type.key}><FormattedMessage id={"PRIORITY." + type.value} /></MenuItem>
                                })}
                            </TextField>
                        </FormControl>
                    </Stack>}
                    {mode === "template" && <Stack gap={1} direction="column">
                        <Autocomplete
                            multiple
                            id="select-template"
                            options={[...templates, ...reportTemplates]}
                            disableCloseOnSelect
                            value={template}
                            onChange={(e, val) => setTemplate(val)}
                            getOptionDisabled={(option) => (entityId && selectedNames.includes(option)) || (entity && topics.some(topic => topic.entityId === entity._id && topic.name === option))}
                            renderInput={(params) => (
                                <TextField {...params} required label={<FormattedMessage id="TEMPLATE" />} size="small" />
                            )}
                            renderOption={(props, option, { selected }) => (
                                <ListItem {...props}>
                                    {reportTemplates.includes(option) && <DescriptionOutlined sx={{ mr: 1 }} color="secondary" />}
                                    <ListItemText primary={option} />
                                </ListItem>
                            )}
                            renderTags={(values, getTagProps) => {
                                return values.map((option, index) => (
                                    <Chip size="small" {...getTagProps({ index })} label={option} icon={reportTemplates.includes(option) ? <Tooltip title={<FormattedMessage id="ESRS_REPORT.COMPATIBLE" />} arrow><DescriptionOutlined /></Tooltip> : undefined} />
                                ));
                            }}
                            groupBy={(option) => reportTemplates.includes(option)}
                            renderGroup={(params) => (<li key={params.key}>
                                {params.group ? <GroupHeader><FormattedMessage id="ESRS_TEMPLATE.REPORT" /></GroupHeader> : null}
                                <GroupItems>{params.children}</GroupItems>
                            </li>
                            )}
                            size="small"
                            fullWidth
                        />

                        <FormHelperText><FormattedMessage id="ESG.TOPIC.TEMPLATE_HELPER_TEXT" /></FormHelperText>
                        <FormHelperText><FormattedMessage id="ESG.TOPIC.TEMPLATE_HELPER_TEXT.METRIC_OPTIONS" /></FormHelperText>

                        <FormControl >
                            <FormLabel required ><FormattedMessage id="DATE_FROM" /></FormLabel>
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={intl.locale === "hr" ? hr : enGB}>
                                <DateTimePicker
                                    value={new Date(dateFrom)}
                                    onChange={(date) => { setDateFrom(date); }}
                                    slotProps={{ textField: { size: 'small' } }}
                                />
                            </LocalizationProvider>
                            {new Date(dateFrom).toString() === "Invalid Date" && <FormHelperText error><FormattedMessage id="INVALID_DATE" /></FormHelperText>}
                        </FormControl>
                        <FormControl >
                            <FormLabel required ><FormattedMessage id="REPORT_INTERVAL" /></FormLabel>
                            <TextField
                                select
                                size="small"
                                value={reportInterval}
                                onChange={(e) => { setDataFrequencyInterval(""); setReportInterval(e.target.value); }}
                            >
                                {reportIntervalTypes.map(type => <MenuItem key={type.key} value={type.key}><FormattedMessage id={'GROUPING.' + type.value} /></MenuItem>)}
                            </TextField>
                        </FormControl>
                        <FormControl >
                            <FormLabel required ><FormattedMessage id="DATA_FREQUENCY_INTERVAL" /></FormLabel>
                            <TextField
                                select
                                size="small"
                                value={dataFrequencyInterval}
                                onChange={(e) => { setDataFrequencyInterval(e.target.value); }}
                                disabled={reportInterval === ""}
                            >
                                {dataFrequencyIntervalTypes.filter((type, index) => index <= findIndex()).map(type => <MenuItem key={type.key} value={type.key}><FormattedMessage id={'GROUPING.' + type.value} /></MenuItem>)}
                            </TextField>
                        </FormControl>
                    </Stack>}
                </Stack>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button onClick={() => handleClose()} color="warning"><FormattedMessage id="CANCEL" /></Button>
                <Button disabled={isDisabled()} onClick={onSubmit}><FormattedMessage id={topic ? "EDIT" : "ADD"} /></Button>
            </DialogActions>
        </Dialog >
    </>;
}