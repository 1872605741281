import { Alert, Box, FormHelperText, Typography } from '@mui/material';
import { CustomDataGrid, LoadingData, AlertSnackbar } from 'components';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/userSlice';
import API from 'api';
import { MultiAxisLineChart } from 'charts';


export default function EntityKpiHistory({ entityId, graphView }) {
    const intl = useIntl();
    const { token } = useSelector(userSelector);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ open: false });
    const chartRef = useRef(null);

    const convertData = useCallback((apiData) => {
        apiData.forEach(dataSerie => {
            dataSerie.label = intl.formatMessage({ id: dataSerie.key });
            dataSerie.values.forEach(value => {
                const temp = value.x;
                value.x = value.y;
                value.y = temp;
                value.unit = "€";
            })
            dataSerie.data = dataSerie.values;
        });
        setData({ datasets: apiData });
    }, [intl]);

    useEffect(() => {
        setLoading(true);
        if (graphView) {
            API.entities.getEntityKPIValuesHistory(token, entityId).then(({ data }) => {
                if (data?.length) convertData(data);
                else setData(null);
                setLoading(false);
            }).catch(error => {
                console.error(error);
                setData({ error: error });
                setLoading(false);
                setAlert({ open: true, messageId: error?.data?.id || "ERROR.FETCHING_DATA", severity: "error" });
            });
        }
        else {
            API.entities.getEntityKPI(token, entityId).then(({ data }) => {
                if (data?.length) setData(data.map(el => ({ id: el._id, dateYear: el.dateYear, ...el.KPI })));
                else setData(null);
                setLoading(false);
            }).catch(error => {
                console.error(error);
                setData({ error: error });
                setLoading(false);
                setAlert({ open: true, messageId: error?.data?.id || "ERROR.FETCHING_DATA", severity: "error" });
            });
        }

    }, [token, entityId, graphView, convertData]);



    const render = useCallback(() => {
        const headers = [
            {
                field: 'dateYear',
                headerName: intl.formatMessage({ id: 'YEAR' }),
                renderCell: (row) => <Typography component="span">{row.value}</Typography>,
            },
            {
                field: 'turnover',
                type: 'number',
                headerName: intl.formatMessage({ id: 'turnover' }),
                renderCell: (row) => <Typography component="span">{row.value} €</Typography>,
                minWidth: 150,
                flex: 1
            },
            {
                field: 'capEx',
                type: 'number',
                headerName: intl.formatMessage({ id: 'capEx' }),
                renderCell: (row) => <Typography component="span">{row.value} €</Typography>,
                minWidth: 150,
                flex: 1
            },
            {
                field: 'opEx',
                type: 'number',
                headerName: intl.formatMessage({ id: 'opEx' }),
                renderCell: (row) => <Typography component="span">{row.value} €</Typography>,
                minWidth: 150,
                flex: 1
            },
        ];

        if (!data && !loading) return <Alert severity="warning"><FormattedMessage id='NO_DATA' /></Alert>;
        else if (data?.error && !loading) return <Alert severity="error"><FormattedMessage id='ERROR.FETCHING_DATA' /></Alert>;
        else if (graphView) return <>
            <Box sx={{ minHeight: 300 }}>
                {loading ? <LoadingData /> : (Boolean(data?.datasets) && <MultiAxisLineChart name={"KPIHistory" + entityId} data={data} chartRef={chartRef} aggregation="y" KPI={true} />)}
            </Box>
            <FormHelperText><FormattedMessage id="DOUBLE_CLICK_CHART_HELPER_TEXT" /></FormHelperText>
        </>;
        else if (!graphView) return <CustomDataGrid isLoading={loading} rows={data?.length ? data : []} columns={headers} disableToolbar />;
    }, [data, entityId, graphView, loading, intl]);

    return <>
        <AlertSnackbar open={alert.open} onClose={() => setAlert({ ...alert, open: false })} severity={alert.severity} messageId={alert.messageId} />
        {render()}
    </>;
}