import instance from "./axiosEnv";
import { setAuthHeader } from "./axiosEnv";

const getAllActivities = (token, sector) => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();

    if (sector !== undefined) queryParams.append('sectorKey', sector);

    return instance.get('/activity?' + queryParams.toString(), config);
}

const getEntityActivities = (token, entityId, date) => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();

    if (!date) queryParams.append('year', new Date().getFullYear());
    else queryParams.append('year', date);

    return instance.get('/entityActivity/' + entityId + '?' + queryParams.toString(), config);
}

const getEntityActivity = (activityId, token, entityId) => {
    const config = setAuthHeader(token);
    return instance.get('/entityActivity/' + entityId + '/' + activityId, config);
}

const getAssignedActivities = (token, date) => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();

    if (!date) queryParams.append('year', new Date().getFullYear());
    else queryParams.append('year', date);

    return instance.get('/entityActivity?'+ queryParams.toString(), config);
}

const postEntityActivity = (token, entityId, activityId, dateYear) => {
    const config = setAuthHeader(token);
    const body = {
        entityId,
        activityId,
        dateYear,
    }

    return instance.post('/entityActivity/', body, config);
}

const putEntityActivity = (token, activityId, newActivity) => {
    const config = setAuthHeader(token);
    return instance.put('/entityActivity/' + activityId, newActivity, config);
}

const deleteEntityActivity = (token, activityId) => {
    const config = setAuthHeader(token);
    return instance.delete('/entityActivity/' + activityId, config);
}

const getEntityActivityReport = (token, entityId, year, lang, types) => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();

    queryParams.append('year', year);
    queryParams.append('reportLanguage', lang);
    for (let el in types) {
        queryParams.append('type', types[el]);
    }
    return instance({ url: '/activityReport/' + entityId + '?' + queryParams.toString(), method: 'GET', responseType: 'blob', ...config });
}


const activityAPI = {
    getAllActivities,
    getEntityActivities,
    getEntityActivity,
    getAssignedActivities,
    postEntityActivity,
    putEntityActivity,
    deleteEntityActivity,
    getEntityActivityReport,
};

export default activityAPI;