
import { useState, useEffect, useCallback } from 'react';
import { Dialog, CardHeader, DialogContent, DialogActions, Divider, Switch, Button, Typography, Avatar, useMediaQuery, Alert, Stack, IconButton, Tooltip, CircularProgress } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { CustomDataGrid, DateRangeSelector, MetricHistoryChartView, ExpandedGridCell, AlertSnackbar, MetricValueFormDialog, SideDrawer, DisplayVersion } from 'components';
import { EditOutlined, RestorePageOutlined, Timeline, ViewList, CalculateOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { userSelector, permissionSelector } from "redux/userSlice";
import { selectDateRange } from "redux/dateRangeSlice";
import { localISOString } from 'utils';
import { renderDateCell } from 'components/dashboardView/TableHeaders';
import API from 'api';

const SwitchIcon = ({ graphView }) => {
    return <Avatar sx={{ width: 24, height: 24, bgcolor: 'primary.main' }}>
        {graphView ? <Timeline fontSize="small" /> : <ViewList fontSize="small" />}
    </Avatar>;
}

const MetricHistoryReportView = ({ metricId, openDialog, onCloseHandler, entityName, reportName, type, label, onUpdateValue, qualitative, noHistoryEdit, metricModel }) => {

    const [allReports, setAllReports] = useState([]);
    const [graphView, setGraphView] = useState(false);

    const [initialValue, setInitialValue] = useState("");
    const [selectedId, setSelectedId] = useState(null);

    const intl = useIntl();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [logsData, setLogsData] = useState({ loading: true });
    const [loading, setLoading] = useState(false);
    const { token } = useSelector(userSelector);
    const selectedDateRange = useSelector(selectDateRange);
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [updatedData, setUpdatedData] = useState(null);
    const updateMetricPermission = useSelector((state) => permissionSelector(state, 'update-metric'));
    const recalculateMetricPermission = useSelector((state) => permissionSelector(state, 'recalculate-metric'));
    const viewLogsPermission = useSelector((state) => permissionSelector(state, 'view-change-logs'));
    const [alert, setAlert] = useState({ open: false });
    const [disableButton, setDisableButton] = useState(false);
    const [recalculateReports, setRecalculateReports] = useState(0);

    const onAlertClose = () => setAlert({ ...alert, open: false });

    useEffect(() => {
        setLoading(true);
        if (metricId && openDialog === true && !graphView) API.metricReports.getAllMetricReports(token, [metricId], localISOString(selectedDateRange.dateFrom), localISOString(selectedDateRange.dateTo)).then(items => {
            if (items.data) {
                const reports = items.data.map((item) => {
                    return {
                        id: item._id,
                        name: item.metricId?.name,
                        value: item.value,
                        unit: item.metricId?.reportData?.reportUnit?.unit || item.metricId?.category?.scope?.unit,
                        calculationMethod: item.metricId?.reportData?.calculationMethod,
                        startDate: item.datetime?.dateFrom,
                        endDate: item.datetime?.dateTo,
                        lastUpdated: item.datetime?.lastUpdated,
                        reportInterval: item.metricId?.reportData?.reportInterval,
                        measurementTypeKey: item.metricId?.category?.scope?.measurementTypeKey
                    }
                })
                setAllReports(reports);
            }
            setDisableButton(false);
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
            setDisableButton(false);
            setAllReports({ error });
        })
        return (() => {
            setAllReports([]);
            setLoading(false);
            setDisableButton(false);
        })

    }, [metricId, openDialog, token, selectedDateRange, recalculateReports, graphView]);

    useEffect(() => {
        if (openDrawer && token && selectedId) {
            setLogsData({ loading: true })
            API.logs.getLogsById(token, selectedId).then(({ data }) => {
                if (data) setLogsData(data);
                else setLogsData([]);
            }).catch(error => {
                console.error(error);
                setLogsData({ error: error });
            })
        }
    }, [openDrawer, token, selectedId]);

    const renderTable = useCallback(() => {
        const headers = [
            {
                field: 'reportInterval',
                headerName: intl.formatMessage({ id: "REPORT_INTERVAL" }),
                ...(!smallScreen && { flex: 1 }),
                renderCell: (row) => <Typography> {intl.formatMessage({ id: "GROUPING." + row.row.reportInterval?.toUpperCase() })} </Typography>,
                minWidth: 150
            },
            {
                field: 'startDate',
                type: 'dateTime',
                headerName: intl.formatMessage({ id: "DATE_FROM" }),
                ...(!smallScreen && { flex: 1 }),
                valueGetter: ({ row }) => new Date(row.startDate ? row.startDate : undefined),
                ...renderDateCell(intl),
                minWidth: 150
            },
            {
                field: 'endDate',
                type: 'dateTime',
                headerName: intl.formatMessage({ id: "DATE_TO" }),
                ...(!smallScreen && { flex: 1 }),
                valueGetter: ({ row }) => new Date(row.endDate ? row.endDate : undefined),
                ...renderDateCell(intl),
                minWidth: 150
            },
            {
                field: 'lastUpdated',
                type: 'dateTime',
                headerName: intl.formatMessage({ id: "LAST_UPDATED" }),
                ...(!smallScreen && { flex: 1 }),
                valueGetter: ({ row }) => new Date(row.lastUpdated ? row.lastUpdated : undefined),
                ...renderDateCell(intl),
                minWidth: 150
            },
            {
                field: 'lastValue',
                headerName: intl.formatMessage({ id: "MEASUREMENTS.LAST_VALUE" }),
                ...(!smallScreen && { flex: 1 }),
                valueGetter: (row) => row.row.value !== null ? `${row.row.value} ${row.row.unit !== undefined && row.row.calculationMethod !== "count" ? row.row.unit : ''}` : '',
                renderCell: (row) => row.value ? <ExpandedGridCell value={row.value} width={row.colDef.computedWidth} /> : <Typography variant="inherit" color="text.disabled"><FormattedMessage id="NONE" /></Typography>,
                minWidth: 200
            },
            qualitative && ((updateMetricPermission && !noHistoryEdit) || viewLogsPermission) && {
                field: 'actions',
                headerName: intl.formatMessage({ id: 'ACTION' }),
                type: 'actions',
                getActions: (el) => [
                    viewLogsPermission && <Tooltip title={<FormattedMessage id="VIEW_LOGS" />} arrow>
                        <IconButton component="span" onClick={() => {
                            setSelectedId(el.id);
                            setOpenDrawer(true);
                        }} color="info" key="edit" ><RestorePageOutlined fontSize="small" /></IconButton>
                    </Tooltip>,
                    !noHistoryEdit && updateMetricPermission && <Tooltip title={<FormattedMessage id="EDIT" />} arrow>
                        <IconButton disabled={!el.row.value} component="span" onClick={() => {
                            if (el.row.value) {
                                setSelectedId(el.id);
                                setInitialValue(el.row.value + "");
                            }
                        }} color="secondary" key="logs" ><EditOutlined fontSize="small" /></IconButton>
                    </Tooltip>,
                ].filter(Boolean),
                minWidth: 200
            }
        ].filter(Boolean)

        if (allReports?.error) return <Alert severity='error'><FormattedMessage id="ERROR.NO_DATA" /></Alert>;
        else return <CustomDataGrid isLoading={loading} rows={allReports} columns={headers} />

    }, [intl, loading, allReports, qualitative, updateMetricPermission, viewLogsPermission, noHistoryEdit, smallScreen]);

    const handleSubmit = (newValue) => {
        const updateData = (array, newData) => {
            let newArray = Array.from(array);
            let index = array.findIndex(el => el.id === newData.id);
            if (index >= 0) {
                newArray[index].value = newData.value;
                newArray[index].lastUpdated = newData.lastUpdated;
            }
            return newArray;
        }
        API.metricReports.putMetricReport(token, selectedId, { value: newValue }).then(({ data }) => {
            if (data) {
                setAllReports(old => updateData(old, { id: data._id, value: data.value, lastUpdated: data.datetime?.lastUpdated }));
                if (typeof onUpdateValue === 'function') onUpdateValue(data);
                setUpdatedData(data)
            }
            else {
                console.warn("No data received from the API on update to the metric report", { selectedId, newValue });
                setAllReports(old => updateData(old, { id: selectedId, value: newValue, lastUpdated: Date.now() }));
                if (typeof onUpdateValue === 'function') onUpdateValue({ _id: selectedId, value: newValue });
                setUpdatedData({ _id: selectedId, value: newValue })
            }
            restartEdit();
            setAlert({ open: true, messageId: "SUCCESS.UPDATE", severity: "success" });
        }).catch((error) => {
            console.error("Error updating metric report, reason:", error);
            restartEdit();
            setAlert({ open: true, messageId: error?.data?.id || "ERROR.FETCHING_DATA", severity: "error" });
        })
    }

    const closeDrawer = () => {
        setSelectedId(null);
        setOpenDrawer(false);
        setLogsData({ loading: true });
    }
    const restartEdit = () => {
        setSelectedId(null);
        setInitialValue("");
    }
    const onClose = () => { onCloseHandler(updatedData); setUpdatedData(null); setGraphView(false); closeDrawer(); restartEdit(); }


    const handleRecalculateReports = () => {
        if (!qualitative && metricModel === 'metric') {
            setLoading(true);
            setDisableButton(true);
            API.metricReports.recalculateMetricReport(token, { metricId: metricId, metricModel: metricModel }).then(() => {
                setRecalculateReports(recalc => recalc + 1);
                setLoading(false);
                setAlert({ open: true, messageId: "SUCCESS.CALCULATING", severity: "success" });
            }).catch((error) => {
                setLoading(false);
                setDisableButton(false);
                setAlert({ open: true, messageId: error?.data?.id || "ERROR.CALCULATING", severity: "error" });
            })
        }
    }

    return <>
        <SideDrawer showOverDialog open={openDrawer} toggleDrawer={closeDrawer} state={{
            title: <FormattedMessage id="LOGS" />,
            subtitle: <FormattedMessage id="DASHBOARD.OPTIONS.metricReport" />,
            display: logsData.error ? <Alert severity='error'><FormattedMessage id="ERROR.FETCHING_DATA" /></Alert> : <DisplayVersion data={logsData} />
        }} />
        <MetricValueFormDialog
            open={!openDrawer && Boolean(selectedId)} onClose={restartEdit}
            value={initialValue} onSubmit={(newValue) => handleSubmit(newValue)}
        />
        <Dialog
            open={openDialog}
            maxWidth={'lg'}
            fullWidth
            fullScreen={smallScreen}
            onClose={onClose}
            sx={{ position: 'fixed' }}
        >
            <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
            <CardHeader
                avatar={type && <Avatar sx={{ bgcolor: 'primary.main' }}>{type}</Avatar>}
                title={<Typography variant="h6"><FormattedMessage id="ESG.METRIC.PREVIOUS_REPORTS" /></Typography>}
                action={!qualitative && <Switch icon={<SwitchIcon graphView={graphView} />} checkedIcon={<SwitchIcon graphView={graphView} />}
                    checked={graphView} onChange={() => setGraphView(!graphView)} sx={{ minWidth: 50, mb: 3 }} />}
                subheader={<Stack direction="column" gap={0.5}>
                    <Typography color="primary" width='100%' >{reportName} </Typography>
                    <Typography> {entityName} </Typography>
                </Stack>
                }
            />
            <Divider />
            <DialogContent>
                <DateRangeSelector column />
                <br />
                {(graphView && !qualitative) ? <MetricHistoryChartView key={recalculateReports} metricId={metricId} label={label} reportName={reportName} setLoading={setLoading} /> : renderTable()}
            </DialogContent>
            <Divider />
            <DialogActions sx={{ justifyContent: 'space-between' }}>
                {!qualitative && metricModel === 'metric' && updateMetricPermission && recalculateMetricPermission
                    ? <Button disabled={disableButton || loading} onClick={handleRecalculateReports} startIcon={disableButton ? <CircularProgress size="1rem" /> : <CalculateOutlined fontSize="small" />} >
                        <FormattedMessage id={disableButton ? "RECALCULATING" : "ESG.METRICS.RECALCULATE_REPORT"} />
                    </Button>
                    : <span />
                }
                <Button color="warning" onClick={onClose}><FormattedMessage id="CLOSE" /></Button>
            </DialogActions>
        </Dialog>
    </>
}
export default MetricHistoryReportView;