import { useSelector } from 'react-redux';
import { userSelector } from 'redux/userSlice';
import { DateRangeSelector, StagingMeasurementsTable, AlertSnackbar } from 'components';
import { useEffect, useState } from 'react';
import { Card, CardContent, Box, useMediaQuery, CardHeader, TextField, Autocomplete, Stack, Button, Typography, Badge } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { selectDateRange } from 'redux/dateRangeSlice';
import { selectEntities, selectMeasureEntities } from 'redux/entitySlice';
import { selectMeasurementsTypesConf } from 'redux/configurationSlice';
import { useLocation } from 'react-router';
import API from 'api';
import StagingMetricsTable from './StagingMetricsTable';
import { permissionSelector } from 'redux/userSlice';
import { InsertChartOutlinedOutlined, TroubleshootOutlined } from '@mui/icons-material';



export default function StagingDataView(props) {
    const intl = useIntl();
    const navigateState = useLocation().state;
    const { token } = useSelector(userSelector);
    const allEntities = useSelector(selectEntities);
    const measureEntities = useSelector(selectMeasureEntities);
    const measureTypes = useSelector(selectMeasurementsTypesConf);
    const selectedDateRange = useSelector(selectDateRange);
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const [metricFlag, setMetricFlag] = useState(false);
    const [data, setData] = useState([]);
    const [disableLinks, setDisableLinks] = useState(false);
    const [loading, setLoading] = useState(false);
    const [types, setTypes] = useState([]);
    const [selected, setSelected] = useState(navigateState ? [navigateState] : []);
    const [count, setCount] = useState({});
    const [alert, setAlert] = useState({ open: false });

    const onAlertClose = () => setAlert({ ...alert, open: false });

    const viewMeasurementsPermission = useSelector((state) => permissionSelector(state, 'view-staging-measurement'));
    const viewMetricsPermission = useSelector((state) => permissionSelector(state, 'view-staging-metricValue'));

    useEffect(() => {
        const onError = error => {
            console.error(error);
            setData({ error: error });
            setLoading(false);
            setAlert({ open: true, messageId: error?.data?.id || "ERROR.FETCHING_DATA", severity: "error" });
            setDisableLinks(false);
        };

        setDisableLinks(true);
        setLoading(true);
        if (metricFlag && viewMetricsPermission) {
            API.stagingMetrics.getHistory(token, selectedDateRange.dateFrom, selectedDateRange.dateTo, selected.length ? selected.map(el => el.entityId) : undefined).then(({ data }) => {
                if (data) setData(data.map((el, i) => ({ ...el, id: i, entity: el.metricId?.entityIdModel === "entity" && allEntities[el.metricId?.entityId], name: el.metricId?.name, dateFrom: new Date(el.datetime.dateFrom), dateTo: new Date(el.datetime.dateTo), lastUpdated: new Date(el.datetime.lastUpdated) })));
                else setData([]);
                setLoading(false);
                setDisableLinks(false);
            }).catch(onError);
        }
        else if (viewMeasurementsPermission) {
            API.stagingMeasurements.getHistory(token, selectedDateRange.dateFrom, selectedDateRange.dateTo, selected.length ? selected.map(el => el.entityId) : undefined).then(({ data }) => {
                if (data?.data?.length) setData(data.data.map((el, i) => ({ ...el, id: i, entity: allEntities[el.entityId], date: new Date(el.timestamp), dataType: measureTypes.find(type => type.key === el.measurementTypeKey) })));
                else setData([]);
                setLoading(false);
                setDisableLinks(false);
            }).catch(onError);
        }
        else return () => { };
    }, [token, selectedDateRange, allEntities, measureTypes, metricFlag, selected, viewMeasurementsPermission, viewMetricsPermission]);

    useEffect(() => {
        if (!viewMeasurementsPermission && viewMetricsPermission) setMetricFlag(true);
        else setMetricFlag(false);
    }, [viewMeasurementsPermission, viewMetricsPermission]);

    const changeView = (flag) => {
        setData([]);
        setSelected([]);
        setTypes([])
        setMetricFlag(flag);
    }

    useEffect(() => {
        API.configuration.getNotifications(token).then((items) => {
            if (items.data) setCount(items.data);
            else setCount(null);
        }).catch((error) => {
            setCount({});
            console.error(error);
        })
    }, [token, data, selectedDateRange]);

    const renderLinks = () => {
        if (viewMeasurementsPermission && !viewMetricsPermission) return <Typography color="text.secondary" variant="button"><FormattedMessage id="MANUAL" /></Typography>
        else if (!viewMeasurementsPermission && viewMetricsPermission) return <Typography color="text.secondary" variant="button"><FormattedMessage id="ESG.METRICS" /></Typography>
        else return <Stack direction="row" gap={count.stagingData ? 1.5 : 0.5} mt={0.5}>
            <Badge badgeContent={count.stagingData || ""} invisible={!count.stagingData} color="secondary" max={9}>
                <Button startIcon={<InsertChartOutlinedOutlined />} disabled={metricFlag && disableLinks} size="small" variant={!metricFlag ? "contained" : "outlined"} onClick={metricFlag ? () => changeView(false) : undefined}>
                    <FormattedMessage id="DIRECT_DATA" />
                </Button>
            </Badge>
            <Badge badgeContent={count.stagingMetricData || ""} invisible={!count.stagingMetricData} color="secondary" max={9}>
                <Button startIcon={<TroubleshootOutlined />} disabled={!metricFlag && disableLinks} size="small" variant={metricFlag ? "contained" : "outlined"} onClick={!metricFlag ? () => changeView(true) : undefined}>
                    <FormattedMessage id="ESG.METRICS_DATA" />
                </Button>
            </Badge>
        </Stack>
    }

    const availableTypes = data?.length ? Array.from(new Set(data.map(el => el.measurementTypeKey))) : undefined;
    return <Box width='100%'>
        <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
        <Card sx={{ width: '100%', mb: 2 }}>
            <CardHeader title={<FormattedMessage id="MEASUREMENTS.STAGING" />} subheader={renderLinks()} />
            <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <DateRangeSelector direction={smallScreen ? "column" : "row"} />
                {!metricFlag && <>
                    <Autocomplete
                        multiple
                        id="selectEntities"
                        options={Object.values(measureEntities)}
                        getOptionLabel={(option) => allEntities[option.entityId].name}
                        value={selected}
                        onChange={(e, option) => setSelected(option)}
                        isOptionEqualToValue={(option, value) => option._id === value._id}
                        renderInput={(params) => (
                            <TextField {...params} label={<FormattedMessage id="ENTITY" />} />
                        )}
                        size="small"
                        fullWidth
                    />
                    <Autocomplete
                        multiple
                        id="selectEntities"
                        options={availableTypes?.length ? measureTypes.filter(el => availableTypes.includes(el.key)) : measureTypes}
                        getOptionLabel={(option) => option?.name ? option.name : intl.formatMessage({ id: option?.key || "undefined" })}
                        value={types}
                        onChange={(e, options) => setTypes(options)}
                        isOptionEqualToValue={(option, value) => option.key === value.key}
                        renderInput={(params) => (
                            <TextField {...params} label={<FormattedMessage id="MEASUREMENT_TYPES" />} />
                        )}
                        size="small"
                        fullWidth
                    />
                </>}
            </CardContent>
        </Card>
        <Card sx={{ width: '100%' }}>
            <CardContent>
                {metricFlag ?
                    <StagingMetricsTable data={data} setData={setData} loading={loading} setAlert={setAlert} />
                    : <StagingMeasurementsTable data={!data.error ? data.filter(el => types.length ? types.map(el => el.key).includes(el.measurementTypeKey) : true) : data} setData={setData} loading={loading} setAlert={setAlert} />}
            </CardContent>
        </Card>
    </Box>;
}