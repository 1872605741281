import { AddOutlined, CloseOutlined, EditOutlined, InfoOutlined, FileDownloadOutlined, RestoreOutlined, SaveOutlined } from "@mui/icons-material";
import { Card, CardContent, CardHeader, Chip, Typography, Stack, Button, IconButton, Tooltip, TextField, InputAdornment, Autocomplete, CardActions, Dialog, DialogContent, Divider, Switch, useMediaQuery } from "@mui/material";
import { BreadcrumbsAndNavigation, LoadingData, AlertSnackbar, EntityKpiHistory, EntityActivityExport, CustomDataGrid, ExpandedGridCell, ActivityDetails, EntityActivityDetails, ActivityAssignForm, ActivityUpdateForm } from 'components';
import { SwitchIcon } from "components/measurementsCharts/MeasurementsCharts";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { selectEntities, updateEntity } from "redux/entitySlice";
import { userSelector, permissionSelector } from "redux/userSlice";
import { selectSectorsConfiguration } from "redux/configurationSlice";
import { generateYearsBetween } from "utils";
import API from 'api';

const Text = ({ children, ...props }) => <Typography component="span" display="inline" {...props}>{children}</Typography>
const EuroLabel = () => <Typography component="span" variant="h6" color="text.secondary" pl={1} display="inline">€</Typography>;
const NoValueCell = () => <Typography component="span" color="text.disabled"><FormattedMessage id="NONE" /></Typography>;

const KpiCard = ({ loading, title, kpi, entityId, dateYear, setKPI, isNew, changeNew }) => {
    const { token } = useSelector(userSelector)
    const entities = useSelector(selectEntities);
    const [edit, setEdit] = useState(false);
    const [value, setValue] = useState(kpi ?? "");
    const [alert, setAlert] = useState({ open: false });
    const dispatch = useDispatch();

    useEffect(() => {
        setEdit(false);
        setValue(kpi ?? "");
    }, [kpi, entityId, dateYear])

    const updateKPIPermission = useSelector((state) => permissionSelector(state, 'update-entity'));

    const onSubmit = () => {
        const newValue = value === "" ? null : value;
        API.entities[isNew ? "createEntityKPI" : "updateEntityKPI"](token, entityId, { [title]: newValue }, dateYear).then((res) => {
            if (res.data) {
                if (dateYear === CURRENT_YEAR.toString()) dispatch(updateEntity({ ...entities[entityId], KPI: res.data.KPI }));
                else setKPI(res.data.KPI);
                setAlert({ open: true, messageId: "SUCCESS.UPDATE", severity: "success" });
                setEdit(false);
                changeNew(false);
            }
        }).catch(error => {
            console.error(error);
            setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_CREATED", severity: "error" });
        })
    };

    return <Card sx={{ width: '100%' }} variant={isNew ? "outlined" : "elevation"}>
        <AlertSnackbar open={alert.open} onClose={() => setAlert({ ...alert, open: false })} severity={alert.severity} messageId={alert.messageId} />
        <CardHeader title={<Text variant="h6"><FormattedMessage id={title + "_total"} /></Text>} sx={{ pb: 0 }} action={[
            updateKPIPermission
                ? <IconButton disabled={loading || (edit && value === (kpi ?? ""))} key="edit" size="small" onClick={() => edit ? onSubmit() : setEdit(true)}>{edit ? (!isNew && <SaveOutlined fontSize="small" />) : (isNew ? <AddOutlined color="warning" /> : <EditOutlined fontSize="small" />)}</IconButton>
                : null,
            edit
                ? <IconButton disabled={loading} key="close" size="small" onClick={() => { setEdit(false); setValue(kpi || "") }}><CloseOutlined fontSize="small" color="error" /></IconButton>
                : null,
        ]} />
        <CardContent>
            {loading ? <LoadingData /> : (edit
                ? <TextField fullWidth value={value} onChange={(e) => {
                    const value = e.target.value;
                    if (!isNaN(value) && value !== " " && value >= 0) setValue(value);
                    else if (!alert.open) setAlert({ open: true, messageId: "ERROR.INVALID_DATA_TYPE.NUMBER_REQ", severity: "warning" });
                }} variant="standard" InputProps={{ endAdornment: <InputAdornment position="end">€</InputAdornment> }} />
                : <span>
                    <Text variant="h3" color={kpi !== null ? "primary" : "text.disabled"}>{kpi ?? "N/A"}</Text><EuroLabel />
                </span>
            )}
        </CardContent>
        {isNew && edit && <CardActions sx={{ justifyContent: 'space-between' }}>
            <Button disabled={value === (kpi ?? "")} onClick={() => onSubmit()} size="small"><FormattedMessage id="ADD.NEW" /> <FormattedMessage id={title} /> {dateYear}</Button>
        </CardActions>}
    </Card >
}

const CURRENT_YEAR = new Date().getFullYear();
const defaultKPI = {
    turnover: null,
    capEx: null,
    opEx: null
}
export default function ActivitiesView({ entityId, assigned }) {
    const mediumScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const intl = useIntl();
    const entities = useSelector(selectEntities);
    const entity = entities[entityId];
    const [open, setOpen] = useState(false);
    const [openInfo, setOpenInfo] = useState(false);
    const [openHistory, setOpenHistory] = useState(false);
    const [openAssign, setOpenAssign] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openExport, setOpenExport] = useState(false);
    const [infoSelected, setInfoSelected] = useState(null);
    const [data, setData] = useState(null);
    const [KPI, setKPI] = useState(defaultKPI);
    const { token } = useSelector(userSelector);
    const sectorConf = useSelector(selectSectorsConfiguration);
    const [date, setDate] = useState(new Date().getFullYear().toString())
    const [activities, setActivities] = useState([]);
    const [alert, setAlert] = useState({ open: false });
    const [loading, setLoading] = useState(false);
    const [loadingKPI, setLoadingKPI] = useState(false);
    const [reset, setReset] = useState(0);
    const [newKPI, setNewKPI] = useState(false);
    const [graphView, setGraphView] = useState(false);

    const viewActivityPermission = useSelector((state) => permissionSelector(state, 'view-activity'));
    const assignActivityPermission = useSelector((state) => permissionSelector(state, 'create-activity'));
    const updateActivityPermission = useSelector((state) => permissionSelector(state, 'update-activity'));
    const viewKPIPermission = useSelector((state) => permissionSelector(state, 'view-KPI'));
    const getReportPermission = viewActivityPermission && viewKPIPermission;

    useEffect(() => {
        const apiCall = entityId ? "getEntityActivities" : (assigned ? "getAssignedActivities" : "getAllActivities");
        setLoading(true);
        API.activity[apiCall](token, entityId, date).then(res => {
            if (res.data) {
                if (entityId || assigned) {
                    setActivities(res.data.map(el => {
                        const activity = el.activityId;
                        const sector = sectorConf.find(sector => sector.key === activity?.sectorKey);
                        return {
                            id: el._id,
                            ...el,
                            entity: entities[el.entityId],
                            nameKey: intl.formatMessage({ id: activity?.name }),
                            sectorValue: sector ? intl.formatMessage({ id: sector.value }) : null,
                            objectiveArray: activity?.objectives.map(obj => intl.formatMessage({ id: obj.objectiveKey })).join(", "),
                            reference: activity.reference,
                            codeNACE: activity.codeNACE
                        };
                    }))
                }
                else setActivities(res.data.map(el => {
                    const sector = sectorConf.find(sector => sector.key === el.sectorKey);
                    return {
                        id: el._id,
                        ...el,
                        nameKey: el.name && intl.formatMessage({ id: el.name }),
                        sectorValue: sector ? intl.formatMessage({ id: sector.value }) : null,
                        objectiveArray: el.objectives.map(obj => intl.formatMessage({ id: obj.objectiveKey })).join(", ")
                    };
                }))
            };
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
            setAlert({ open: true, messageId: error?.data?.id || "ERROR.FETCHING_DATA", severity: "error" });
        });
    }, [token, intl, sectorConf, reset, date, entities, entityId, assigned]);

    useEffect(() => {
        if (!viewKPIPermission) return;
        if (entityId && date === CURRENT_YEAR.toString()) {
            setKPI(entities[entityId]?.KPI || defaultKPI);
        }
        else if (entityId) {
            setLoadingKPI(true);
            API.entities.getEntityKPI(token, entityId, date)
                .then(({ data }) => {
                    if (data) setKPI(data.KPI)
                    else setKPI(defaultKPI);
                    setLoadingKPI(false);
                    setNewKPI(false);
                })
                .catch(error => {
                    console.error(error);
                    if (error?.response?.status === 404) {
                        setKPI(defaultKPI);
                        setNewKPI(true);
                        setAlert({ open: true, messageId: "WARNING.KPI_NOT_ASSIGNED", severity: "warning" });
                    } else {
                        setKPI(null);
                        setAlert({ open: true, messageId: error?.data?.id || "ERROR.FETCHING_DATA", severity: "error" });
                    }
                    setLoadingKPI(false);
                })
        }
        else {
            setKPI(null);
        }

    }, [token, date, entities, viewKPIPermission, entityId]);

    const headers = [
        {
            field: 'reference',
            type: 'number',
            headerName: intl.formatMessage({ id: 'REFERENCE' }),
            renderCell: (row) => <Typography component="span">{row.value}</Typography>,
        },
        {
            field: 'nameKey',
            headerName: intl.formatMessage({ id: 'NAME' }),
            flex: 1,
            renderCell: (row) => <ExpandedGridCell value={row.value} width={row.colDef.computedWidth} />,
            minWidth: 300
        },
        {
            field: 'codeNACE',
            headerName: intl.formatMessage({ id: 'CODE_NACE' }),
            flex: 1,
            renderCell: (row) => <ExpandedGridCell value={row.value || <NoValueCell />} width={row.colDef.computedWidth} />,
            minWidth: 200
        },
        {
            field: 'sectorValue',
            headerName: intl.formatMessage({ id: 'SECTOR' }),
            flex: 1,
            renderCell: (row) => <ExpandedGridCell value={row.value} width={row.colDef.computedWidth} />,
            minWidth: 100
        },
        {
            field: 'objectiveArray',
            headerName: intl.formatMessage({ id: 'OBJECTIVES' }),
            flex: 1,
            renderCell: (row) => <ExpandedGridCell value={row.value} width={row.colDef.computedWidth} />,
            minWidth: 100
        },
    ];
    if (assigned) headers.splice(0, 0, {
        field: 'entity',
        headerName: intl.formatMessage({ id: 'ENTITY' }),
        valueGetter: (row) => row.value?.name,
        renderCell: (row) => <ExpandedGridCell value={row.value} width={row.colDef.computedWidth} />,
        minWidth: 100,
        flex: 1
    });
    if (entityId) {
        headers.splice(1, 0, {
            field: 'aligned',
            headerName: intl.formatMessage({ id: 'ALIGNED' }),
            type: 'boolean',
            minWidth: 50
        })
        headers.push({
            field: 'actions',
            headerName: intl.formatMessage({ id: 'ACTIONS' }),
            type: 'actions',
            getActions: (el) => [
                <Tooltip key="info" title={<FormattedMessage id="ACTIVITY_DETAILS" />}>
                    <IconButton size="small" color="info" onClick={() => { setInfoSelected({ ...el.row, ...el.row.activityId }); setOpen(true); }}><InfoOutlined fontSize="small" /></IconButton>
                </Tooltip>,
                <Button key="edit" disabled={!updateActivityPermission} color="secondary" size="small" onClick={() => { if (updateActivityPermission) { setData(el.row); setOpenEdit(true); } }} startIcon={<EditOutlined fontSize="small" />}><FormattedMessage id="EDIT" /></Button>
            ],
            minWidth: 200
        });
    }

    return <Stack sx={{ width: '100%' }} gap={1}>
        <AlertSnackbar open={alert.open} onClose={() => setAlert({ ...alert, open: false })} severity={alert.severity} messageId={alert.messageId} action={alert.action} />
        {entity || assigned ? <BreadcrumbsAndNavigation noMargin entity={entity} baseNavigate="/activities" rootUrl="/activities" activityMode /> : null}
        <ActivityDetails noAction={!!entityId} open={open} onClose={() => { setInfoSelected(null); setOpen(false); }} activity={infoSelected} AlertCall={setAlert} />
        <EntityActivityDetails open={openInfo} onClose={() => { setInfoSelected(null); setOpenInfo(false); }} activity={infoSelected} setEdit={(activity) => { setData(activity); setOpenEdit(true) }} setReset={() => setReset(r => r + 1)} AlertCall={setAlert} />
        <ActivityAssignForm open={openAssign} onClose={() => setOpenAssign(false)} entityId={entityId} onReset={() => setReset(r => r + 1)} AlertCall={setAlert} selectedDate={date ? new Date(date) : undefined} />
        <ActivityUpdateForm open={openEdit} onClose={() => { setData(null); setOpenEdit(false) }} activity={data} entity={entity || infoSelected?.entity} onReset={(newActivity) => { setReset(r => r + 1); if (openInfo && newActivity && infoSelected !== null) setInfoSelected(newActivity); }} AlertCall={setAlert} />
        {Boolean(entityId) && <Dialog open={openExport} onClose={() => setOpenExport(false)} maxWidth="sm" fullWidth fullScreen={mediumScreen}>
            <EntityActivityExport entityId={entityId} KPI={KPI} onClose={() => setOpenExport(false)} year={date} />
        </Dialog>}
        {Boolean(entityId) && <Dialog open={openHistory} onClose={() => setOpenHistory(false)} maxWidth="md" fullWidth fullScreen={mediumScreen}>
            <CardHeader title={<FormattedMessage id="ACTIVITY.HISTORY_KPI" />} action={<><Switch icon={<SwitchIcon graphView={graphView} />} checkedIcon={<SwitchIcon graphView={graphView} />}
                checked={graphView} onChange={() => setGraphView(!graphView)} sx={{ minWidth: 50 }} /><IconButton onClick={() => { setOpenHistory(false); setGraphView(false) }}><CloseOutlined /></IconButton></>} />
            <Divider />
            <DialogContent>
                <EntityKpiHistory entityId={entityId} graphView={graphView} />
            </DialogContent>
        </Dialog>}
        {Boolean(entityId) && <Card sx={{ p: 0.5 }}>
            <Stack direction={mediumScreen ? "column" : "row"} spacing={1} alignItems={mediumScreen ? "flex-start" : "center"}>
                <Autocomplete
                    id="set-date-prefix"
                    value={date} onChange={(e, newValue) => {
                        setNewKPI(false);
                        setDate(newValue || new Date().getFullYear().toString())
                    }}
                    options={generateYearsBetween(2020, CURRENT_YEAR + 10)}
                    size="small"
                    fullWidth
                    renderInput={(params) => (
                        <TextField {...params} sx={smallScreen ? { mt: 0.5 } : undefined} label={smallScreen ? <FormattedMessage id="DATE.PREFIX" /> : undefined} InputProps={{ ...params.InputProps, startAdornment: <InputAdornment sx={{ mx: 0, pl: 0.5, ...(smallScreen && { display: 'none' }) }} position="start"><FormattedMessage id="DATE.PREFIX" /></InputAdornment> }} />
                    )}
                />
                {viewKPIPermission && <Chip onClick={() => setOpenHistory(true)} icon={<RestoreOutlined fontSize="small" color="primary" />} label={<FormattedMessage id="HISTORY" />} variant="outlined" />}
                {getReportPermission && KPI !== null && <Chip onClick={() => setOpenExport(true)} icon={<FileDownloadOutlined fontSize="small" color="primary" />} label={<FormattedMessage id="EXPORT" />} variant="outlined" />}
            </Stack>
        </Card>}
        {viewKPIPermission && Boolean(entityId) && KPI && <Stack direction={mediumScreen ? "column" : "row"} gap={1}>
            {Object.keys(KPI).map(key => {
                const kpi = KPI[key]
                return <KpiCard loading={loadingKPI || loading} key={key} title={key} kpi={kpi} entityId={entityId} dateYear={date} setKPI={setKPI} isNew={newKPI && activities.length === 0} changeNew={setNewKPI} />;
            })}
        </Stack>}
        <Card>
            <CardHeader
                title={<FormattedMessage id="ACTIVITIES" />}
                subheader={Boolean(activities?.length) && <Text variant="caption"><FormattedMessage id={(Boolean(entityId) || assigned) ? "ACTIVITY_TOOLTIP" : "TAXONOMY.STANDARD"} /></Text>}
                action={Boolean(entity) && assignActivityPermission && <Chip disabled={loading} variant={activities.length ? "outlined" : "filled"} onClick={() => setOpenAssign(true)} icon={<AddOutlined fontSize="small" color="primary" />} label={<FormattedMessage id="ACTIVITY.ASSIGN" />} />}
            />
            <CardContent>
                <CustomDataGrid isLoading={loading} rows={activities} columns={headers} handleData={({ row }) => { setInfoSelected({ ...row.activityId, ...row }); entityId || assigned ? setOpenInfo(true) : setOpen(true); }} />
            </CardContent>
        </Card>
    </Stack>;
} 