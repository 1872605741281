import { forwardRef, useEffect, useState } from "react";
import { Alert, Card, Container, Divider, IconButton, Stack, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { EntityCard, BreadcrumbsAndNavigation, EntityForm, AlertSnackbar, InvalidIdSplash, SearchBar, CustomDataGrid, ExpandedGridCell, MeasureEntityForm, DisplayVersion, SideDrawer } from "components";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { selectEntities, selectMeasureEntities } from "redux/entitySlice";
import { VirtuosoGrid } from "react-virtuoso";
import { FormattedMessage, useIntl } from "react-intl";
import { BuildOutlined, BusinessCenterOutlined, EditOutlined, HistoryEduOutlined, InsertChartOutlined, OutlinedFlag, PlaceOutlined, QueryStatsOutlined, RestorePageOutlined } from "@mui/icons-material";
import { permissionSelector, userSelector } from "redux/userSlice";
import { selectFeaturesConfiguration } from "redux/configurationSlice";
import API from 'api';

const getComponents = ((width) => ({
    List: forwardRef(({ style, children, ...props }, ref) => (
        <div
            ref={ref}
            {...props}
            style={{
                display: "flex",
                flexWrap: "wrap",
                ...style,
            }}
        >
            {children}
        </div>
    )),
    Item: ({ children, ...props }) => (
        <div
            {...props}
            style={{
                paddingTop: "0.4rem",
                paddingRight: "0.4rem",
                width: width,
                display: "flex",
                flex: "none",
                alignContent: "stretch",
                boxSizing: "border-box",
            }}
        >
            {children}
        </div>
    ),
}));

export const CustomLinkButton = ({ display, onClick, icon, tooltip, color }) => display ? <Tooltip title={tooltip} arrow>
    <IconButton color={color || "primary"} component="span" onClick={onClick}>{icon}</IconButton>
</Tooltip> : null;

export const EntityIconLinks = (props) => {
    const { entity, tableIcons } = props;
    const navigate = useNavigate();
    const measureEntity = useSelector(selectMeasureEntities)[entity._id];
    const [openNewMeasureForm, setOpenNewMeasureForm] = useState(false);

    const viewGoalPermission = useSelector((state) => permissionSelector(state, 'view-goal'));
    const viewMetricPermission = useSelector((state) => permissionSelector(state, 'view-metric'));
    const viewMeasurementPermission = useSelector((state) => permissionSelector(state, 'view-measurement'));
    const viewTopicPermission = useSelector((state) => permissionSelector(state, 'view-topic'));
    const configureMeasureEntityPermission = useSelector((state) => permissionSelector(state, 'update-entity'));
    const viewActivitiesPermission = useSelector((state) => permissionSelector(state, 'view-activity'));
    const featuresConfiguration = useSelector(selectFeaturesConfiguration);

    return <>
        <MeasureEntityForm entity={openNewMeasureForm} open={Boolean(openNewMeasureForm)} onClose={() => setOpenNewMeasureForm(false)} />
        <Stack direction="row">
            {[
                <CustomLinkButton key="entities" onClick={() => navigate('/entities/' + entity._id)} icon={<PlaceOutlined fontSize='small' />} tooltip={<FormattedMessage id="ENTITIES" />} display={true} />,
                <CustomLinkButton key="topics" onClick={() => navigate('/topics/' + entity._id)} icon={<HistoryEduOutlined fontSize='small' />} tooltip={<FormattedMessage id="ESG.TOPICS" />} display={viewTopicPermission} />,
                <CustomLinkButton key="goals" onClick={() => navigate('/goals/' + entity._id)} icon={<OutlinedFlag fontSize='small' />} tooltip={<FormattedMessage id="ESG.GOALS" />} display={viewGoalPermission} />,
                <CustomLinkButton key="metrics" onClick={() => navigate('/metrics/' + entity._id)} icon={<QueryStatsOutlined fontSize='small' />} tooltip={<FormattedMessage id="ESG.METRICS" />} display={viewMetricPermission} />,
                viewActivitiesPermission && featuresConfiguration?.taxonomy?.enabled === true ? <Divider sx={tableIcons ? { mx: 0.5 } : { my: 1 }} key="divider1" orientation="vertical" flexItem /> : null,
                <CustomLinkButton key="activities" onClick={() => navigate('/activities/' + entity._id)} icon={<BusinessCenterOutlined fontSize='small' />} tooltip={<FormattedMessage id="ACTIVITIES" />} display={viewActivitiesPermission && featuresConfiguration?.taxonomy?.enabled === true} />,
                viewMeasurementPermission || configureMeasureEntityPermission ? <Divider sx={tableIcons ? { mx: 0.5 } : { my: 1 }} key="divider2" orientation="vertical" flexItem /> : null,
                measureEntity
                    ? <CustomLinkButton key="measurements" onClick={() => navigate('/measureEntities/' + entity._id)} icon={<InsertChartOutlined fontSize='small' />} tooltip={<FormattedMessage id="MEASUREMENTS" />} display={viewMeasurementPermission} />
                    : <CustomLinkButton key="configuration" onClick={() => setOpenNewMeasureForm(entity)} icon={<BuildOutlined fontSize='small' />} tooltip={<FormattedMessage id="CONFIGURE.DATA" />} display={configureMeasureEntityPermission} />,
            ]}
        </Stack>
    </>
}

export default function EntitiesView(props) {
    const { token } = useSelector(userSelector)
    const queryId = useParams().entityId;
    const navigateState = useLocation().state;
    const intl = useIntl();
    const allEntities = useSelector(selectEntities);
    const entityId = allEntities[queryId] ? queryId : null;
    const [openForm, setOpenForm] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [entityLogs, setEntityLogs] = useState({ loading: true });
    const [alert, setAlert] = useState({ open: false });
    const [tableView, setTableView] = useState(false);
    const [search, setSearch] = useState("");

    const onAlertClose = () => setAlert({ ...alert, open: false });

    const updateEntityPermission = useSelector((state) => permissionSelector(state, 'update-entity'));
    const viewLogsPermission = useSelector((state) => permissionSelector(state, 'view-change-logs'));

    const mq_xs = useMediaQuery(theme => theme.breakpoints.only('xs'));
    const mq_sm = useMediaQuery(theme => theme.breakpoints.only('sm'));
    const mq_md = useMediaQuery(theme => theme.breakpoints.only('md'));
    const mq_lg = useMediaQuery(theme => theme.breakpoints.only('lg'));
    const mq_xl = useMediaQuery(theme => theme.breakpoints.only('xl'));

    const getSize = () => {
        if (mq_xs) return "100%";
        else if (mq_sm) return "100%";
        else if (mq_md) return "50%";
        else if (mq_lg) return "33%";
        else if (mq_xl) return "20%";
        else return "100%";
    }

    const renderView = (array) => {
        const handleLogDetails = (entity) => {
            if (token && entity._id && viewLogsPermission) {
                setOpenDrawer(true);
                setEntityLogs({ loading: true });
                API.logs.getLogsById(token, entity._id).then(response => {
                    if (response.data) setEntityLogs({ entity: entity.name, data: response.data });
                    else setEntityLogs({ data: [] });
                }).catch(error => {
                    console.error(error);
                    setEntityLogs({ data: [] });
                })
            }
            else return;
        }
        const headers = [
            {
                field: 'name',
                headerName: intl.formatMessage({ id: 'NAME' }),
                flex: 1,
                renderCell: (row) => <ExpandedGridCell value={row.value} width={row.colDef.computedWidth} />,
                minWidth: 250
            },
            {
                field: 'description',
                headerName: intl.formatMessage({ id: 'DESCRIPTION' }),
                flex: 1,
                renderCell: (row) => row.value ? <ExpandedGridCell value={row.value} width={row.colDef.computedWidth} /> : <Typography component="span" color="text.disabled"><FormattedMessage id="NONE_ENTERED" /></Typography>,
                minWidth: 300
            },
            {
                field: 'connections',
                type: 'number',
                headerName: intl.formatMessage({ id: 'CONNECTIONS' }),
                valueGetter: ({ value }) => value?.childrenArray?.length ? value.childrenArray.length : 0,
                renderCell: (row) => <ExpandedGridCell value={row.value + " " + intl.formatMessage({ id: 'ENTITIES_GENITIV' }).toLocaleLowerCase()} width={row.colDef.computedWidth} />,
            },
            {
                field: 'links',
                type: 'actions',
                headerName: intl.formatMessage({ id: 'LINKS' }),
                flex: 1,
                renderCell: (row) => <EntityIconLinks entity={row.row} tableIcons />,
                minWidth: 250
            },
            updateEntityPermission || viewLogsPermission ? {
                field: 'actions',
                type: 'actions',
                headerName: intl.formatMessage({ id: 'ACTIONS' }),
                renderCell: (row) => [
                    <CustomLinkButton color="secondary" key="entities" onClick={() => setOpenForm(row.row)} icon={<EditOutlined fontSize='small' />} tooltip={<FormattedMessage id="EDIT" />} display={updateEntityPermission} />,
                    <CustomLinkButton color="info" key="logs" onClick={() => handleLogDetails(row.row)} icon={<RestorePageOutlined fontSize='small' />} tooltip={<FormattedMessage id="LOGS" />} display={viewLogsPermission} />,
                ],
            } : null,
        ].filter(Boolean);
        
        if (tableView) return <Card sx={{ p: 2, mt: 0.5 }}>
            <CustomDataGrid rows={array} columns={headers} />
        </Card>

        else return <VirtuosoGrid
            style={{ flexGrow: 1 }}
            totalCount={array.length}
            data={array}
            components={getComponents(getSize())}
            itemContent={(_, entity) => <EntityCard key={entity._id} entityId={entity._id} setOpenForm={setOpenForm} setEntityLogs={handleLogDetails} />}
        />
    }

    useEffect(() => {
        if (navigateState) setAlert(navigateState);
    }, [navigateState]);

    let array = [];
    if (queryId && !entityId) return <InvalidIdSplash queryId={queryId} />;
    else if (entityId && allEntities[entityId]) {
        const parentEntityChildren = allEntities[entityId].connections.childrenArray;
        for (const child of parentEntityChildren) {
            array.push(allEntities[child]);
        }
    }
    else array = Object.values(allEntities).filter(entity => entity.connections.parentId === null || !allEntities[entity.connections.parentId]);

    if (search !== "") array = array.filter(el => el.name.includes(search));

    return <Container maxWidth={false} disableGutters sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
        <div style={{ display: 'block' }}>
            <BreadcrumbsAndNavigation entity={allEntities[entityId]} entityMode />
            <Card sx={{ p: 0.5, mb: 0.5 }}>
                <SearchBar disabled={!array.length} search={search} setSearch={setSearch} tableView={tableView} setTableView={setTableView} entityId={entityId} addEntity={{ setOpenForm: setOpenForm }} parentExists={array.length > 0 && entityId === null} />
            </Card>
        </div>
        <EntityForm openForm={Boolean(openForm)} setOpenForm={setOpenForm} entity={typeof openForm === 'object' ? openForm : undefined} parentId={entityId} />
        <SideDrawer open={openDrawer} toggleDrawer={() => { setOpenDrawer(false); setEntityLogs({ loading: true }) }} state={{
            title: <FormattedMessage id={!entityLogs.loading ? "LOGS" : "LOADING_DATA"} />,
            subtitle: entityLogs?.entity?.name,
            display: <DisplayVersion data={entityLogs.loading ? entityLogs : entityLogs.data} />
        }} />
        {array.length ? renderView(array) : <Card sx={{ width: '100%', mt: 0.5 }}><Alert severity="warning"><FormattedMessage id="NO_ENTITIES_FOUND" /></Alert></Card>}
    </Container>;
}