import React from 'react';
import { Box, List, ListItem, Stack, Typography, useTheme } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { formatDateLocale } from 'utils';
import { getLabelId } from 'utils';
import { useSelector } from 'react-redux';
import { selectMeasurementsTypesConf, selectGoalCategoryTypesConf, selectCollectorTypesConf, selectStatusTypes } from 'redux/configurationSlice';
import { ValueCell } from 'components/metricReportDetailCard/MetricReportDetailCard';
import { UserList } from 'components';
import { selectUsersAndGroups } from 'redux/usersAndGroupsSlice';
import { selectEntities } from 'redux/entitySlice';


const DisplayValueChange = ({ method, previousValue, currentValue, intlPrefix, sx, label, labelColor, labelSize }) => {
    const theme = useTheme();
    const allEntities = useSelector(selectEntities);
    const measurementTypes = useSelector(selectMeasurementsTypesConf);
    const goalCategories = useSelector(selectGoalCategoryTypesConf);
    const goalStatus = useSelector(selectStatusTypes);
    const collectorCategories = useSelector(selectCollectorTypesConf);
    const usersAndGroups = useSelector(selectUsersAndGroups);

    const users = usersAndGroups?.users || [];
    const groups = usersAndGroups?.groups || [];

    const renderValue = (value, currentValueFlag) => {
        // no value
        if (value === null || value === undefined || value === "") return <FormattedMessage id="NONE" />;
        // measurement type
        else if (label?.includes("measurementTypeKey")) {
            const type = measurementTypes.find(el => el.key === value);
            if (type) return type.name || <FormattedMessage id={type.key} />;
            else return null;
        }
        // entity
        else if (typeof value === "string" && label === 'entityId') {
            const entity = allEntities[value];
            return entity?.name
        }
        // goal category
        else if (typeof value === "number" && label === 'category' && intlPrefix === 'ESG.GOAL.') {
            const goal = goalCategories.find(el => el.key === value)?.value;
            return <FormattedMessage id={goal ? (intlPrefix + goal) : "NONE"} />
        }
        // goal status
        else if (typeof value === "number" && label === 'status' && intlPrefix === 'STATUS.') {
            const status = goalStatus.find(el => el.key === value)?.value;
            return <FormattedMessage id={status ? (intlPrefix + status) : "NONE"} />
        }
        // collector type
        else if (typeof value === "number" && label === 'category.scope.collector' && intlPrefix === 'COLLECTOR_TYPE.') {
            const collector = collectorCategories.find(el => el.key === value)?.value;
            return <FormattedMessage id={collector ? (intlPrefix + collector) : "NONE"} />
        }
        // source type
        else if (label === 'category.scope.sourceType') {
            const sources = collectorCategories.find(el => el.key === value)?.value;
            return <FormattedMessage id={sources ?? "NONE"} />
        }
        else if (label === 'attributes.picture') {
            return value ? <img
                style={{
                    maxWidth: '240px',
                    maxHeight: '135px',
                    width: 'auto',
                    height: 'auto'
                }}
                src={process.env.REACT_APP_API_BASE_URL + '/' + value}
                alt={value}
                loading="lazy"
            /> : null
        }
        // translated texts
        else if (intlPrefix) return <FormattedMessage id={intlPrefix + (typeof value === "string" ? value.toUpperCase() : value)} />;
        // date
        else if (typeof value === "string" && new Date(value).toString() !== 'Invalid Date' && value.includes("T") && value.includes("Z")) return formatDateLocale(value);
        // long strings
        else if (typeof value === "string" && value.length > 64) return <Box mt={currentValueFlag ? 1 : 0} width={200}>
            <ValueCell customTitle={<FormattedMessage id={"ESG.TARGET.VALUE." + (currentValueFlag ? "CURRENT" : "PREVIOUS")} />} color={currentValueFlag ? "success" : "error"} value={value} customStringWidth={64} tableCell variant fullWidth />
        </Box>;
        // user lists
        else if (typeof value === "object" && label === 'participants' && (users?.length || groups?.length)) return <UserList simple users={value.map(el => users.find(user => user.id === el.id)).filter(Boolean)} groups={value.map(el => groups.find(group => group._id === el.id)).filter(Boolean)} />;
        // array (default or position)
        else if (typeof value === "object" && value?.length >= 0) return value.length ?
            <List dense>
                {value.map((el, i) => <ListItem sx={{ pr: 0, justifyContent: 'flex-end' }} key={el}>
                    <Typography variant="caption" color="text.secondary" pr={1}>
                        {label === 'geoProperties.position' ? <FormattedMessage id={i ? "LONGITUDE" : "LATITUDE"} /> : `${i + 1}.`}
                    </Typography>
                    <Typography variant="body1">{el}</Typography>
                </ListItem>)}
            </List>
            : <FormattedMessage id="NONE" />;
        // default (object, no formatting)
        else if (typeof value === "object") return JSON.stringify(value);
        // default (string)
        else return value;
    }

    if (!method || (method !== "Create" && (!currentValue && isNaN(currentValue)) && (!previousValue && isNaN(previousValue)))) return null;

    return <Stack sx={sx ? sx : undefined} direction="row" spacing={2} alignItems={method === 'Create' ? "center" : "flex-start"} justifyContent="space-between" width='100%'>
        <Typography color={labelColor ? labelColor : "secondary"} display="block" variant={labelSize === 'large' ? "body1" : "body2"} component="span" ><FormattedMessage id={getLabelId(label)} /></Typography>

        <Stack direction="column" alignItems='flex-end' textAlign='end'>
            {(method !== "Create" && label !== 'attributes.picture') && <Typography sx={{ overflowWrap: "break-word", textDecoration: "line-through" }} display="block" variant="body2" color={previousValue !== null && previousValue !== undefined && previousValue !== "" ? "error" : "text.disabled"} component="span">{renderValue(previousValue)}</Typography>}
            {method !== "Delete" && <Typography sx={{ overflowWrap: "break-word" }} display="block" variant="h6" component="span" color={currentValue !== null && currentValue !== undefined && currentValue !== "" ? theme.palette.success.main : "text.disabled"}>{renderValue(currentValue, true)}</Typography>}
        </Stack>
    </Stack>
}

export default DisplayValueChange