import React, { useEffect, useState, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { userSelector } from "redux/userSlice";
import { selectDateRange } from "redux/dateRangeSlice";
import { LoadingData } from "components";
import { Alert, Box } from "@mui/material";
import { MultiAxisLineChart } from "charts";
import { localISOString } from 'utils';
import API from "api";

const MetricHistoryChartView = ({ metricId, label, reportName, setLoading }) => {
    const { token } = useSelector(userSelector);
    const intl = useIntl();

    const selectedDateRange = useSelector(selectDateRange);
    const [data, setData] = useState({ loading: true });

    const render = useCallback(() => {
        if (!data.loading && data.datasets?.length > 0) return <Box sx={{ minHeight: 300 }}><MultiAxisLineChart disableDrawer={true} name={"trendLineChart_" + metricId} data={data} /></Box>;
        else if (data.loading) return <LoadingData />;
        else if (data.error) return <Alert severity="error"><FormattedMessage id='NO_DATA' /></Alert>;
        else return <Alert severity="warning"><FormattedMessage id='NO_DATA_TIME_PERIOD' /></Alert>

    }, [data, metricId])

    const convertData = useCallback((apiData) => {
        setData({
            datasets: [{
                label: label ? (label.name || intl.formatMessage({ id: label.key })) : reportName,
                key: label?.key,

                data: apiData.map(el => ({
                    x: el.y,
                    y: el.x,
                    unit: label?.unit
                }))
            }]
        });
    }, [setData, intl, label, reportName]);

    useEffect(() => {
        setData({ loading: true })
        API.metricReports.getMetricReportTimeseries(token, [metricId], localISOString(selectedDateRange.dateFrom), localISOString(selectedDateRange.dateTo)).then(items => {
            if (items.data && Object.keys(items.data).length > 0) {
                for (const key in items.data) {
                    convertData(items.data[key]);
                }
            } else {
                setData({ loading: false })
            }
            setLoading(false);
        }).catch((error) => {
            console.error(error);
            setData({ error: error });
            setLoading(false);
        })
    }, [metricId, token, convertData, selectedDateRange.dateFrom, selectedDateRange.dateTo, setLoading])

    return <>
        {render()}
    </>
}

export default React.memo(MetricHistoryChartView);