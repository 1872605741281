import { useSelector } from 'react-redux';
import { userSelector, permissionSelector } from 'redux/userSlice';
import { CustomDataGrid, SideDrawer, MeasurementDetails, LoadingData, ConfirmationDialog, MeasurementDisplayBlock } from 'components';
import { renderDateCell } from 'components/dashboardView/TableHeaders';
import { useState } from 'react';
import { Alert, useMediaQuery, Chip, Tooltip, IconButton, Stack, Typography } from '@mui/material';
import { ThumbDownOutlined, ThumbUpOutlined } from '@mui/icons-material';
import { FormattedMessage, useIntl } from 'react-intl';
import API from 'api';


export default function StagingMeasurementsTable({ loading, data, setData, setAlert }) {
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const intl = useIntl();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [drawerDetails, setDrawerDetails] = useState({});
    const { token } = useSelector(userSelector);
    const [openConfirmDialog, setOpenConfirmDialog] = useState({ type: "", data: null });

    const updateStagingMeasurementPermission = useSelector((state) => permissionSelector(state, 'update-staging-measurement'));
    const deleteStagingMeasurementPermission = useSelector((state) => permissionSelector(state, 'delete-staging-measurement'));

    const onClose = () => setOpenConfirmDialog({ type: "", data: null });

    const handleDelete = () => {
        API.stagingMeasurements.deleteMeasurement(token, openConfirmDialog?.data?._id).then(response => {
            if (response.data) {
                if (setAlert) setAlert({ open: true, messageId: "SUCCESS.DELETE", severity: "success" });
                if (setData) setData(old => old.filter(el => el._id !== response.data._id));
            }
            onClose()
        }).catch(error => {
            console.error(error);
            if (setAlert) setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_DELETED", severity: "error" });
        })
    }
    const handleSubmit = () => {
        API.stagingMeasurements.approveMeasurement(token, openConfirmDialog?.data?._id).then(() => {
            if (setAlert) setAlert({ open: true, messageId: "SUCCESS.POST_MEASUREMENTS", severity: "success" });
            if (setData) setData(old => old.filter(el => el._id !== openConfirmDialog.data._id));
            onClose();
        }).catch(error => {
            console.error(error);
            if (setAlert) setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_APPROVED", severity: "error" });
        })
    }

    const tableHeaders = [
        {
            field: 'date',
            type: 'dateTime',
            headerName: intl.formatMessage({ id: "DATE" }),
            ...renderDateCell(intl),
            ...(!smallScreen && { flex: 1 }),
            minWidth: 200,
        },
        {
            field: 'entity',
            headerName: intl.formatMessage({ id: "ENTITY" }),
            valueGetter: ({ row }) => row.entity?.name,
            ...(!smallScreen && { flex: 1 }),
            minWidth: 150,
        },
        {
            field: 'dataType',
            headerName: intl.formatMessage({ id: "MEASUREMENT_TYPE" }),
            valueGetter: (row) => row.value?.name || intl.formatMessage({ id: row.value?.key || "undefined" }),
            ...(!smallScreen && { flex: 1 }),
            minWidth: 200,
        },
        {
            field: 'value',
            type: 'number',
            headerName: intl.formatMessage({ id: "VALUE" }),
            ...(!smallScreen && { flex: 1 }),
            renderCell: (row) => <Typography color={"text." + (row.value ? "primary" : "disabled")}>{row.value || <FormattedMessage id="NONE" />}</Typography>,
            minWidth: 150,
        },
        {
            field: 'unit',
            headerName: intl.formatMessage({ id: "UNIT" }),
            ...(!smallScreen && { flex: 1 }),
            minWidth: 100,
        },
        {
            field: 'actions',
            headerName: intl.formatMessage({ id: 'ACTIONS' }),
            type: 'actions',
            getActions: (el) => [
                updateStagingMeasurementPermission && <Tooltip key="approve" title={<FormattedMessage id="APPROVE" />} arrow>
                    <IconButton component="span" size="small" color="success" onClick={() => setOpenConfirmDialog({ type: "submit", data: el.row })}><ThumbUpOutlined fontSize='small' /></IconButton>
                </Tooltip>,
                deleteStagingMeasurementPermission && <Tooltip key="delete" title={<FormattedMessage id="REJECT" />} arrow>
                    <IconButton component="span" size="small" color="error" onClick={() => setOpenConfirmDialog({ type: "delete", data: el.row })}><ThumbDownOutlined fontSize='small' /></IconButton>
                </Tooltip>
            ].filter(Boolean),
            minWidth: 200
        }
    ];

    if (data.error) return <>{loading ? <LoadingData /> : <Alert severity="error"><FormattedMessage id='NO_DATA' /></Alert>}</>;
    else return <>
        <SideDrawer open={openDrawer} state={drawerDetails} toggleDrawer={() => setOpenDrawer(false)} />
        <ConfirmationDialog
            open={openConfirmDialog.type !== ""}
            content={<MeasurementDisplayBlock noPaper data={openConfirmDialog.data} detail={<Chip size="small" color="warning" label={<FormattedMessage id="MEASUREMENTS.STAGING_PENDING" />} />} />}
            title={<Stack direction="column" spacing={1} alignItems="flex-start">
                <FormattedMessage id={`CONFIRM_${openConfirmDialog.type === "submit" ? "APPROVAL" : "REJECTION"}`} />
                {openConfirmDialog.type === "delete" && <Typography variant="caption" color="warning.main" component="div">*<FormattedMessage id="STAGING_DATA_REJECT_HELPER_TEXT" /></Typography>}
            </Stack>
            }
            customButtonTitle={<FormattedMessage id={openConfirmDialog.type === "submit" ? "APPROVE" : "REJECT"} />}
            customButtonColor={openConfirmDialog.type === "submit" ? "success" : "error"}
            handleCancel={onClose}
            handleCustomButton={() => {
                if (openConfirmDialog.type === "delete") handleDelete();
                else if (openConfirmDialog.type === "submit") handleSubmit();
                else return;
            }}
            maxWidth="xs"
            fullWidth
        />
        <CustomDataGrid isLoading={loading} rows={!data.error ? data : []} handleData={({ row }) => {
            setOpenDrawer(true);
            setDrawerDetails({
                title: <FormattedMessage id='LOADING_DATA' />,
                subtitle: <FormattedMessage id='SPLASH.WAIT' />,
                display: <LoadingData noText />
            });
            API.stagingMeasurements.getSingleMeasurement(token, row._id).then(({ data }) => {
                setDrawerDetails({
                    title: <FormattedMessage id="MEASUREMENT_DETAILS" />,
                    subtitle: <FormattedMessage id="MEASUREMENTS.STAGING_PENDING" />,
                    display: <MeasurementDetails stagingData measurement={data} sideDrawer onClose={() => setOpenDrawer(false)} onReset={({ _id }) => setData(old => old.filter(el => el._id !== _id))} setAlert={setAlert} />
                });
            }).catch(error => {
                console.error(error);
                setDrawerDetails({
                    title: <FormattedMessage id="MEASUREMENT_DETAILS" />,
                    subtitle: <FormattedMessage id="ERROR.FETCHING_DATA" />,
                    display: <Alert severity="error"><FormattedMessage id='NO_DATA' /></Alert>
                });
                setAlert({ open: true, messageId: error?.data?.id || "ERROR.FETCHING_DATA", severity: "error" });
            });
        }} columns={tableHeaders} enableExport />
    </>;
}