import { FormattedMessage } from 'react-intl';
import { Backdrop, Typography, Box, Button } from '@mui/material';
import { ErrorOutline, LogoutOutlined, RefreshOutlined } from '@mui/icons-material';
import API from 'api';
import { useSelector } from 'react-redux';
import { unauthorizedFlagSelector, crashedAPIFlagSelector } from 'redux/userSlice';

const PageError = (props) => {
    const { messageId } = props;
    const unauthorizedFlag = useSelector(unauthorizedFlagSelector);
    const crashedFlag = useSelector(crashedAPIFlagSelector);

    return (
        <Backdrop open sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} >
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <ErrorOutline fontSize='large' color="inherit" />
                {messageId && <Typography variant="body1" color="inherit">
                    <FormattedMessage id={messageId} />
                </Typography>}
                {unauthorizedFlag && <Typography variant="h6"><FormattedMessage id="UNAUTHORIZED.NO_PERMISSION" /></Typography>}
                {crashedFlag && <Typography variant="h6"><FormattedMessage id="CRASHED_API" /></Typography>}
                <Box sx={{ mt: 2, gap: 1 }} display='flex'>
                    <Button variant="outlined" startIcon={<RefreshOutlined />} color="inherit" onClick={() => window.history.go(0)}><FormattedMessage id="REFRESH" /></Button>
                    {API.keycloak.authenticated && <Button variant="outlined" startIcon={<LogoutOutlined />} color="inherit" onClick={() => API.keycloak.logout()}><FormattedMessage id="LOG_OUT" /></Button>}
                </Box>
            </Box>
        </Backdrop>
    );
}

export default PageError;