import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router"
import { ConfirmationDialog, DisplayBox, AlarmDetailsDialog, AlarmSeverityIcon } from "components";
import { Stack, IconButton, Tooltip, Box, List, Typography, Button, Chip, ListItem, ListItemText } from "@mui/material";
import { BackspaceOutlined, ThumbUpOutlined, PageviewOutlined, PolicyOutlined } from "@mui/icons-material";
import { formatDateLocale } from "utils";
import { useSelector } from "react-redux";
import { permissionSelector } from "redux/userSlice";
import { selectMeasurementsTypesConf } from "redux/configurationSlice";

export default function AlarmButtons({ alarm, smallScreen, openDrawer, disableClear, disableAcknowledge, handleAcknowledgeAlarm, handleClearAlarm }) {
    const navigate = useNavigate();
    const [openAckDialog, setOpenAckDialog] = useState(false);
    const [openClrDialog, setOpenClrDialog] = useState(false);
    const [drawer, setDrawer] = useState(false);
    const [profile, setProfile] = useState({});
    const measurementTypes = useSelector(selectMeasurementsTypesConf);
    const acknowledgeAlarmsPermission = useSelector((state) => permissionSelector(state, 'acknowledge-alarms'));
    const clearAlarmsPermission = useSelector((state) => permissionSelector(state, 'clear-alarms'));

    const dialogContent = <Stack direction="column" spacing={1}>
        <DisplayBox label={<FormattedMessage id="NAME" />} value={alarm?.name} />
        <DisplayBox label={<FormattedMessage id="SEVERITY" />} value={alarm?.severity ? <span><AlarmSeverityIcon severity={alarm.severityKey} /> {alarm.severity}</span> : null} />
        <DisplayBox label={<FormattedMessage id="TRIGGERED_TIME" />} value={formatDateLocale(alarm?.triggeredTime)} />

        <DisplayBox label={<FormattedMessage id="ACKNOWLEDGED_TIME" />} value={formatDateLocale(alarm?.acknowledgedTime)} />
        <DisplayBox label={<FormattedMessage id="ACKNOWLEDGED_BY" />} value={alarm?.acknowledgedBy} />
        <DisplayBox label={<FormattedMessage id="CONDITIONS_PASSED" />} value={
            <List dense>
                {alarm?.conditionsPassed?.map((el, index) => {
                    const measureType = measurementTypes.find(type => type.key === el.measurementTypeKey);
                    return <ListItem key={el.measurementTypeKey + index}>
                        <ListItemText primary={<Box component="span">
                            <Typography component="span" color="text.disabled" display="inline" sx={{ pr: 1 }}>{index + 1}.</Typography>
                            <Typography variant="body2" component="span" display="inline">{measureType.name || <FormattedMessage id={measureType.key} />} </Typography>
                            <span>
                                <Typography component="span" display="inline"> {el.value}</Typography>
                                <Typography variant="body2" component="span" color="text.secondary" display="inline">{el.unit}</Typography>
                            </span>
                        </Box>}
                            secondary={<Typography variant="caption" component="span" color="text.secondary">{formatDateLocale(el.timestamp)}</Typography>}
                        />
                    </ListItem>
                })}
            </List>
        } />
    </Stack>;

    return (
        <>
            <AlarmDetailsDialog open={drawer} onClose={() => setDrawer(false)} alarmProfile={profile} />
            <ConfirmationDialog
                fullWidth
                open={openAckDialog}
                title={<FormattedMessage id="CONFIRM_ACKNOWLEDGE_FOR" />}
                content={dialogContent}
                handleCancel={() => { setOpenAckDialog(false); }}
                handleCustomButton={() => { handleAcknowledgeAlarm(); setOpenAckDialog(false); }}
                customButtonTitle={<FormattedMessage id="YES" />}
                customButtonColor='info'
            />
            <ConfirmationDialog
                fullWidth
                open={openClrDialog}
                title={<Stack direction="column" spacing={1} alignItems="flex-start">
                    <FormattedMessage id="CONFIRM_CLEAR_FOR" /> {alarm.acknowledgedBy !== undefined
                        ? <Chip icon={<ThumbUpOutlined fontSize="small" />} size="small" label={<FormattedMessage id="ACKNOWLEDGED" />} />
                        : <Typography variant="caption" color="warning.main" component="div">*<FormattedMessage id="ACKNOWLEDGE_HELPER_TEXT" /></Typography>}
                </Stack>}
                content={dialogContent}
                handleCancel={() => { setOpenClrDialog(false); }}
                handleCustomButton={() => { handleClearAlarm(); setOpenClrDialog(false); }}
                customButtonTitle={<FormattedMessage id="YES" />}
                customButtonColor='error'
            />
            <Stack sx={{ width: '100%' }} direction="row" justifyContent="space-between">
                {smallScreen
                    ? <Box>
                        {acknowledgeAlarmsPermission && <Tooltip title={<FormattedMessage id="ACKNOWLEDGE" />} placement="top" arrow>
                            <span>
                                <IconButton disabled={!!disableAcknowledge} color="info" key="acknowledge" onClick={() => setOpenAckDialog(true)}><ThumbUpOutlined fontSize="small" /></IconButton>
                            </span>
                        </Tooltip>}
                        {clearAlarmsPermission && <Tooltip title={<FormattedMessage id="CLEAR" />} placement="top" arrow>
                            <span>
                                <IconButton disabled={!!disableClear} color="error" key="clear" onClick={() => setOpenClrDialog(true)}> <BackspaceOutlined fontSize="small" /> </IconButton>
                            </span>
                        </Tooltip>}
                    </Box>
                    : <Box>
                        {acknowledgeAlarmsPermission && <Button color="info" disabled={!!disableAcknowledge} onClick={() => setOpenAckDialog(true)}><FormattedMessage id="ACKNOWLEDGE" /></Button>}
                        {clearAlarmsPermission && <Button color="error" disabled={!!disableClear} onClick={() => setOpenClrDialog(true)}><FormattedMessage id="CLEAR" /></Button>}
                    </Box>}
                <Box>
                    {openDrawer &&
                        <Tooltip title={<FormattedMessage id='ALARM.DETAILS' />} placement="top" arrow>
                            <IconButton color="primary" onClick={() => navigate('/alarms/details/' + alarm.id)}><PageviewOutlined fontSize="small" /></IconButton>
                        </Tooltip>
                    }
                    <Tooltip title={<FormattedMessage id="VIEW_PROFILE" />} placement="top" arrow>
                        <IconButton color="primary" onClick={() => { setProfile(alarm.profileId); setDrawer(true); }}><PolicyOutlined fontSize="small" /></IconButton>
                    </Tooltip>
                </Box>
            </Stack>
        </>
    )
}