import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { selectMetricTypesConf } from 'redux/configurationSlice';
import { selectEntities } from 'redux/entitySlice';
import { Autocomplete, CardHeader, Checkbox, Collapse, Dialog, DialogContent, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, IconButton, List, ListItem, MenuItem, Stack, TextField, Tooltip, Typography, useMediaQuery, } from '@mui/material';
import { AssignOwnerField, DescriptionField } from 'components';
import { CloseOutlined, InfoOutlined, PreviewOutlined } from '@mui/icons-material';

export function AllEntitiesCheckbox({ entities, allEntitiesFlag, setAllEntitiesFlag }) {
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const [open, setOpen] = useState(false);
    const onClose = () => setOpen(false);
    return <>
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth fullScreen={smallScreen}>
            <CardHeader title={<FormattedMessage id="ENTITIES" />} action={<IconButton size="small" onClick={onClose}><CloseOutlined /></IconButton>} />
            <Divider />
            <DialogContent>
                <List disablePadding>
                    {Object.values(entities).map((el, index) => <ListItem key={el._id}><Typography variant='caption' pr={1} color="text.secondary">{index + 1}.</Typography> {el.name}</ListItem>)}
                </List>
            </DialogContent>
        </Dialog>
        <FormControlLabel control={<Checkbox checked={allEntitiesFlag} onChange={(e) => setAllEntitiesFlag(e.target.checked)} />} label={<FormattedMessage id="ESG.METRIC.ALL_ENTITIES_LABEL" />} />
        {allEntitiesFlag && <FormHelperText>
            <FormattedMessage id="ESG.METRIC.ALL_ENTITIES_LABEL_HELPERTEXT" /> <b>{Object.keys(entities).length}</b> <span style={{ textTransform: 'lowercase' }}><FormattedMessage id="ENTITIES_AKUZATIV" /></span>
            <Tooltip title={<FormattedMessage id="PREVIEW" />} placement='right'>
                <IconButton size="small" onClick={() => setOpen(true)}><PreviewOutlined fontSize='small' /></IconButton>
            </Tooltip>
        </FormHelperText>}
    </>
}

const InfoTooltip = ({ type }) => {
    return <Tooltip title={<FormattedMessage id={"ESG.METRICS.NEW_QUANTITATIVE_METRIC_TOOLTIP.BOTTOM_" + (type === "up" ? "UP" : "DOWN")} />} arrow>
        <InfoOutlined sx={({ typography }) => ({ height: typography.fontSize, width: typography.fontSize })} />
    </Tooltip>
}

const DescriptiveSection = ({ setEditMode, entityId, selectedEntity, setselectedEntity, allEntitiesFlag, setAllEntitiesFlag, propagation, setPropagation, categoryType, name, setName, description, setDescription, owner, setOwner, metricType, setMetricType }) => {
    const entities = useSelector(selectEntities);
    const entitiesList = Object.keys(entities).map(ent => entities[ent]);
    const metricTypes = useSelector(selectMetricTypesConf);

    return <Stack direction="column" spacing={2} sx={{ mb: 2 }}>
        <FormControl>
            <FormLabel required><FormattedMessage id="NAME" /></FormLabel>
            <TextField
                required
                fullWidth
                variant="outlined"
                id="NAME"
                type="text"
                size="small"
                value={name}
                inputProps={{ maxLength: process.env.REACT_APP_ESG_INPUT_RESTRICTION_SMALL }}
                onChange={(e) => { setName(e.target.value); setEditMode(true) }}
            />
        </FormControl>
        <DescriptionField noCharacterLimit description={description} onChange={(value) => { setDescription(value); setEditMode(true) }} />
        <FormControl>
            <FormLabel required={!entityId}><FormattedMessage id="ENTITY" /></FormLabel>
            {entityId ? <Typography variant="h6" color="primary">{selectedEntity[0]?.name}</Typography>
                : <Collapse in={!allEntitiesFlag}><Autocomplete
                    multiple disableCloseOnSelect
                    disabled={allEntitiesFlag}
                    id="select-entity"
                    options={entitiesList}
                    getOptionLabel={(entity) => entity ? entity.name : ""}
                    value={selectedEntity}
                    onChange={(e, value) => { setselectedEntity([...value]); setEditMode(true) }}
                    renderInput={(params) => (
                        <TextField {...params} key={entitiesList.map(entity => entity._id)} />
                    )}
                    size="small"
                    fullWidth
                /></Collapse>}
            {!entityId && <AllEntitiesCheckbox entities={entities} allEntitiesFlag={allEntitiesFlag} setAllEntitiesFlag={setAllEntitiesFlag} />}
        </FormControl>

        {propagation ? <Collapse in={categoryType === process.env.REACT_APP_ESG_METRIC_DEFAULT_TYPE}>
            <FormControl fullWidth>
                <FormLabel><FormattedMessage id="ESG.METRICS.PROPAGATION" /></FormLabel>
                <TextField
                    select
                    size="small"
                    value={propagation || ""}
                    onChange={(e) => { setPropagation(e.target.value); setEditMode(true) }}
                    helperText={Boolean(propagation) && <Typography variant='caption' sx={{ display: "block" }}> <FormattedMessage id="ESG.METRIC.TEMPLATE_HELPER_TEXT" /> <InfoTooltip type={propagation} /> </Typography>}
                >
                    {[null, 'up', 'down'].map(type => <MenuItem key={type} value={type}><Typography component="span" color={!type ? "text.disabled" : "inherit"}><FormattedMessage id={"ESG.METRICS.PROPAGATION." + type} /></Typography></MenuItem>)}
                </TextField>
            </FormControl>
        </Collapse> : null}

        <AssignOwnerField owner={owner} onChange={(value) => { setOwner(value); setEditMode(true); }} />

        <FormControl>
            <FormLabel required><FormattedMessage id="ESG.METRIC.CATEGORY" /></FormLabel>
            <TextField
                select
                size="small"
                value={metricType}
                onChange={(e) => { setMetricType(e.target.value); setEditMode(true) }}
            >
                {metricTypes.map(type => {
                    return <MenuItem key={type.key} value={type.key}><FormattedMessage id={"ESG.TYPE." + type.key} /></MenuItem>
                })}
            </TextField>
        </FormControl>
    </Stack >

}

export default DescriptiveSection;