import { OpenInNewOutlined, RestartAltOutlined } from "@mui/icons-material";
import { Alert, Autocomplete, Button, Card, CardContent, CardHeader, Chip, Collapse, CardActions, Divider, TextField, Typography, IconButton, Tooltip, Stack, FormControlLabel, Switch, Box, useMediaQuery, CircularProgress } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { selectMeasurementsTypesConf } from "redux/configurationSlice";
import { selectEntities, selectMeasureEntities } from "redux/entitySlice";
import { userSelector, permissionSelector } from "redux/userSlice";
import { MeasurementInputField, AlertSnackbar, MeasurementsReportCard, ConfirmationDialog, SearchBar, MeasurementEntryLinks } from 'components';
import { useLocation, useNavigate } from "react-router";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { enGB, hr } from "date-fns/locale";
import { Virtuoso } from "react-virtuoso";
import API from 'api';

export default function MeasurementsEntryView() {
    const navigate = useNavigate();
    const intl = useIntl();
    const listRef = useRef(null);
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const { token, username } = useSelector(userSelector)
    const measurementsTypes = useSelector(selectMeasurementsTypesConf);
    const measureEntities = useSelector(selectMeasureEntities);
    const entities = useSelector(selectEntities);
    const navigateState = useLocation().state;
    const [search, setSearch] = useState("");
    const [selected, setSelected] = useState(null);
    const [removed, setRemoved] = useState([]);
    const [data, setData] = useState({});
    const [timestamp, setTimestamp] = useState(null);
    const [error, setError] = useState(false);
    const [alert, setAlert] = useState({ open: false });
    const [reportData, setReportData] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const singleMetricSelect = useMemo(() => navigateState?.metric, [navigateState]);

    const createMeasurementPermission = useSelector((state) => permissionSelector(state, 'create-measurement'));
    const createStagingMeasurementPermission = useSelector((state) => permissionSelector(state, 'create-staging-measurement'));
    const updateMetricPermission = useSelector((state) => permissionSelector(state, 'update-metric'));
    const createStagingMetricPermission = useSelector((state) => permissionSelector(state, 'create-staging-metricValue'));

    const onAlertClose = () => setAlert({ ...alert, open: false });

    useEffect(() => {
        if (!createMeasurementPermission && !createStagingMeasurementPermission && (updateMetricPermission || createStagingMetricPermission)) {
            navigate('/measurementsEntry/metrics', { ...(selected && { state: selected }) })
        }
    }, [navigate, selected, createMeasurementPermission, createStagingMeasurementPermission, updateMetricPermission, createStagingMetricPermission]);

    useEffect(() => {
        if (navigateState && !navigateState.metric) setSelected(navigateState);
        else if (navigateState && navigateState.metric) setSelected(measureEntities[navigateState.entityId]);
        else setSelected(null);
    }, [navigateState, measureEntities]);

    useEffect(() => {
        setRemoved([]);
        setData({});
        setError(false);
    }, [selected]);

    const onChange = ({ key, value, unit }) => {
        setError(false);
        setData((oldData) => {
            oldData[key] = {
                measurementTypeKey: key,
                value: value,
                unit: unit
            }
            return oldData;
        })
    }

    const handleCancel = () => {
        if (!reportData && !singleMetricSelect) {
            const newMeasurements = Object.entries(data).filter(([key, value]) => !removed.includes(key) && (value?.value || value?.value === 0)).map(([key, value]) => value);
            if (!newMeasurements.length) setSelected(null);
            else setOpenConfirmDialog(true);
        }
        else {
            setSelected(null);
            setReportData(null);
        }
    }

    const onSubmit = () => {
        if (!createMeasurementPermission && !createStagingMeasurementPermission) return null;
        setSubmitted(true);

        const newMeasurements = Object.entries(data).filter(([key, value]) => !removed.includes(key) && (value?.value || value?.value === 0))?.map(([key, value]) => value);
        if (!newMeasurements.length) {
            setError(true);
            setSubmitted(false);
            setAlert({ open: true, messageId:  "ERROR.NO_DATA_FORM", severity: "error" })
        } else {
            if (!error) setAlert({ open: true, messageId: "UPLOAD_HELPER_TEXT", severity: "info" });
            
            const requestBody = {
                entityId: selected.entityId,
                data: newMeasurements,
                collectorName: username,
                ...(timestamp && { timestamp: timestamp }),
            }

            API.measurements.storeManual(token, requestBody).then((data) => {
                setAlert({ open: true, messageId: "SUCCESS.POST_MEASUREMENTS", severity: "success" });
                setSubmitted(false);
                setTimestamp(null);
                setReportData(data.data);
            }).catch((error) => {
                setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_CREATED", severity: "error" })
                setSubmitted(false);
            })
        };
    }

    const reportResetForm = () => {
        setReportData(null);
        setData({});
        setRemoved([]);
    }

    const onResetToggle = () => {
        setRemoved([]);
        setError(false);
        setSubmitted(false);
    }

    const metricDataAvailable = useMemo(() => singleMetricSelect ? selected?.measurementTypes.manual.types.includes(singleMetricSelect.measurementType.key) : true, [singleMetricSelect, selected]);
    const typeDataAvailable = useMemo(() => !singleMetricSelect && selected && selected.measurementTypes.manual?.types?.length, [singleMetricSelect, selected]);
    const anyDataAvailable = ((singleMetricSelect && metricDataAvailable) || (!singleMetricSelect && typeDataAvailable));

    const items = useMemo(() => {
        const array = typeDataAvailable
            ? selected && selected.measurementTypes.manual.types.filter(type => !removed.includes(type))
            : [selected && selected.measurementTypes.manual.types.find(el => el === singleMetricSelect.measurementType.key)].filter(Boolean);

        if (search.length) return array.filter(el => {
            let type = measurementsTypes.find(type => type.key === el);
            return (type.name || intl.formatMessage({ id: type.key }))?.includes(search)
        });
        else return array;
    }, [selected, singleMetricSelect, removed, typeDataAvailable, search, measurementsTypes, intl]);

    return <>
        <ConfirmationDialog
            open={openConfirmDialog}
            title={<FormattedMessage id="MEASUREMENTS_ENTRY.CANCEL" />}
            customCancelTitle={<FormattedMessage id="NO" />}
            customButtonTitle={<FormattedMessage id="YES" />}
            handleCancel={() => setOpenConfirmDialog(false)}
            handleCustomButton={() => { setOpenConfirmDialog(false); setSelected(null); }}
        />
        <Card sx={{ mx: 'auto', maxWidth: 800, width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
            <div>

                <CardHeader
                    title={singleMetricSelect
                        ? <FormattedMessage id="ESG.METRICS_ENTRY" />
                        : <FormattedMessage id={"MEASUREMENTS_ENTRY" + (reportData ? ".REPORT" : "")} />
                    }
                    subheader={singleMetricSelect ? <></> : <MeasurementEntryLinks single selected={selected} />}
                    action={selected && <Tooltip title={<FormattedMessage id="MEASURE_ENTITY.VIEW" />} placement="left">
                        <span><IconButton size="small" onClick={() => navigate('/measureEntities/' + selected.entityId)}><OpenInNewOutlined /></IconButton></span>
                    </Tooltip>}
                />

                {reportData && selected ?
                    <MeasurementsReportCard entityName={entities[selected.entityId].name} userName={username} measurements={reportData.measurements} onReset={reportResetForm} metricReport={singleMetricSelect} />
                    : <>
                        <CardContent>
                            {singleMetricSelect
                                ? <Typography variant="h5" color="primary" display="inline">{`${singleMetricSelect.name} (${singleMetricSelect.entityName})`}</Typography>
                                : <Autocomplete
                                    id="selectEntities"
                                    options={Object.values(measureEntities)}
                                    getOptionLabel={(option) => entities[option.entityId].name}
                                    value={selected}
                                    onChange={(e, option) => setSelected(option)}
                                    isOptionEqualToValue={(option, value) => option._id === value._id}
                                    renderInput={(params) => (
                                        <TextField {...params} label={<FormattedMessage id="ENTITY" />} />
                                    )}
                                    size="small"
                                    fullWidth
                                />
                            }
                            {!metricDataAvailable && <Alert sx={{ mt: 2 }} severity="warning"><FormattedMessage id="MEASUREMENTS.TYPE_ENTITY_WARNING" /></Alert>}
                            {<Collapse in={Boolean(selected)}>
                                {typeDataAvailable ? <Divider sx={{ my: 1, mt: 4 }} ><Typography color="primary" variant="button"><FormattedMessage id="MEASUREMENTS" /></Typography></Divider> : <Box sx={{ mt: 4 }} />}
                                {Boolean(items.length || search.length) && <Box mr={1} my={0.5}>
                                    <SearchBar search={search} setSearch={setSearch} variant="standard" />
                                </Box>}
                                {items.length > 0 && <Box height={200}>
                                    <Virtuoso
                                        ref={listRef}
                                        data={items}
                                        itemContent={(_, item) => {
                                            const type = measurementsTypes.find(el => el.key === item);
                                            if (type) return <MeasurementInputField variant={typeDataAvailable ? "outlined" : "standard"} defaultValue={data[type.key] && data[type.key].value} key={type.key + "-field"} type={type} onChange={onChange} onDelete={() => setRemoved(state => [...state, type.key])} selectedUnit={singleMetricSelect ? singleMetricSelect.unit : data[type.key]?.unit} fromEdit onError />;
                                            else return null;
                                        }}
                                    />
                                </Box>}
                                {typeDataAvailable ? <>
                                    <Divider sx={{ my: 1 }} />
                                    <Stack direction={smallScreen ? "column" : "row"} spacing={1} sx={{ pl: 0.5 }}>
                                        <FormControlLabel control={<Switch color={new Date(timestamp).toString() === "Invalid Date" ? "error" : "primary"} checked={Boolean(timestamp)} onChange={() => setTimestamp(!timestamp ? new Date() : null)} />}
                                            label={
                                                new Date(timestamp).toString() === "Invalid Date"
                                                    ? <Typography color="error">
                                                        <FormattedMessage id="MEASUREMENTS.CUSTOM_TS" />
                                                    </Typography>
                                                    : <Typography color={!timestamp ? "text.disabled" : "primary"}>
                                                        <FormattedMessage id={!timestamp ? "MEASUREMENTS.CURRENT_TS" : "MEASUREMENTS.CUSTOM_TS"} />
                                                    </Typography>
                                            }
                                        />
                                        <Collapse orientation="horizontal" in={Boolean(timestamp)}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={intl.locale === "hr" ? hr : enGB}>
                                                <DateTimePicker
                                                    label={intl.formatMessage({ id: "DATE_SELECTED" })}
                                                    value={new Date(timestamp)}
                                                    onChange={setTimestamp}
                                                    sx={{ mt: 1 }}
                                                    slotProps={{ textField: { size: 'small' } }}
                                                />
                                            </LocalizationProvider>
                                        </Collapse>
                                    </Stack>
                                </> : <Alert sx={{ mt: 2, display: singleMetricSelect ? 'none' : 'default' }} severity="warning"><FormattedMessage id="MEASUREMENTS.NO_TYPE_ENTITY_WARNING" /></Alert>}
                            </Collapse>}
                            {removed.length > 0 && <Chip label={<FormattedMessage id="RESET" />} icon={<RestartAltOutlined fontSize="small" />} onClick={onResetToggle} />}
                        </CardContent>
                    </>}
            </div>
            {
                <CardActions sx={{ justifyContent: 'space-between' }}>
                    <span>{selected && <>
                        {!reportData && !!anyDataAvailable && 
                            <Button onClick={onSubmit} disabled={error || submitted || (timestamp && new Date(timestamp).toString() === "Invalid Date")} startIcon={submitted ? <CircularProgress size="1rem" /> : null} >
                                <FormattedMessage id={"SUBMIT"} />
                            </Button>
                        }
                        <Button color="warning" onClick={handleCancel}><FormattedMessage id="CANCEL" /></Button>
                    </>}</span>
                    {!singleMetricSelect && !createMeasurementPermission && createStagingMeasurementPermission && <Chip color="warning" size="small" label={<FormattedMessage id={"MEASUREMENTS.STAGING_REVIEW" + (reportData ? ".SENT" : "")} />} />}
                </CardActions>
            }
        </Card>
    </>;
}