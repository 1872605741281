import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { DateRangeSelector, MeasurementsTableContainer, MeasurementsTrendGraph } from "components";
import { Avatar, Card, CardActions, CardContent, CardHeader, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Switch, Tooltip } from "@mui/material";
import { VisibilityOutlined, FilterAltOutlined, Timeline, ViewList } from "@mui/icons-material";
import OWAttribution from "components/attribution/OWAttribution";

export const SwitchIcon = ({ graphView }) => {
    return <Avatar sx={{ width: 24, height: 24, bgcolor: 'primary.main' }}>
        {graphView ? <Timeline fontSize="small" /> : <ViewList fontSize="small" />}
    </Avatar>;
}

const SwitchTypeOption = ({ currentType, changeType, hide }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (type) => {
        setAnchorEl(null);
        if ([-1, 0, 1, 2].includes(type)) changeType(type);
    };

    const Selected = ({ show }) => <ListItemIcon>{show && <Tooltip title={<FormattedMessage id="SHOWING" />}><VisibilityOutlined color="secondary" /></Tooltip>}</ListItemIcon>;

    return <>
        <IconButton color="secondary" onClick={handleClick}><FilterAltOutlined /></IconButton>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={() => handleClose()}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            <MenuItem selected={currentType === 0} onClick={() => handleClose(0)}><Selected show={currentType === 0} /> <ListItemText secondary={<FormattedMessage id="SERVICE.OWM" />}><FormattedMessage id="SERVICE_TYPES" /></ListItemText></MenuItem>
            <MenuItem selected={currentType === 1} onClick={() => handleClose(1)}><Selected show={currentType === 1} /> <FormattedMessage id="SENSOR_TYPES" /></MenuItem>
            <MenuItem selected={currentType === 2} onClick={() => handleClose(2)}><Selected show={currentType === 2} /> <FormattedMessage id="MANUAL_TYPES" /></MenuItem>
            <Divider key="divider" />
            <MenuItem key="select-all" selected={currentType === -1} onClick={() => handleClose(-1)}><Selected show={currentType === -1} /> <FormattedMessage id="SHOW_ALL" /></MenuItem>
        </Menu>
    </>;
}

const MeasurementsCharts = ({ entityId, tableView }) => {
    const [graphView, setGraphView] = useState(!tableView);
    const [type, setType] = useState(-1);

    const getSubheaderMessageId = () => {
        if (type === 0) return "SERVICE_TYPE";
        else if (type === 1) return "SENSOR_TYPE";
        else if (type === 2) return "MANUAL_TYPE";
        else if (type === -1) return "MEASUREMENT_TYPES.ALL";
        else return "undefined"
    }

    return <Stack direction="column" gap={1}>
        <Card sx={{ width: '100%' }}>
            <CardHeader title={<FormattedMessage id="DATE_SELECTED" />} />
            <CardContent >
                <DateRangeSelector />
            </CardContent>
        </Card>
        <Card>
            <CardHeader title={<FormattedMessage id={graphView ? "GRAPH" : "TABLE"} />} subheader={<FormattedMessage id={getSubheaderMessageId()} />}
                action={<Stack direction="row" alignItems="flex-start">
                    <Switch icon={<SwitchIcon graphView={graphView} />} checkedIcon={<SwitchIcon graphView={graphView} />}
                        checked={graphView} onChange={() => setGraphView(!graphView)} sx={{ minWidth: 50, mb: 3 }} />
                    <SwitchTypeOption currentType={type} changeType={setType} />
                </Stack>}
            />
            <CardContent>
                {graphView ? <MeasurementsTrendGraph specialCaseTooltip entityIdArray={entityId ? [entityId] : undefined} fixedCollectorTypes={type > -1 ? [type] : []} /> :
                    <MeasurementsTableContainer entityId={entityId} collectorTypes={type > -1 ? [type] : []} />}
            </CardContent>
            {type <= 0 && <CardActions>
                <OWAttribution />
            </CardActions>}
        </Card>
    </Stack>

}

export default MeasurementsCharts;