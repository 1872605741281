import { PreviewOutlined } from "@mui/icons-material";
import { IconButton, List, ListItem, ListItemText, Tooltip, Typography, Stack } from "@mui/material";
import { FormattedMessage } from "react-intl";
import React, { useState } from "react";
import { MetricDetailsView, } from 'components';
import { useSelector } from "react-redux";
import { selectMeasurementsTypesConf } from "redux/configurationSlice";
import { selectEntities } from "redux/entitySlice";

export default function CompactMetricList({ metrics, compact }) {
    const [metric, setMetric] = useState(null);
    const measurementTypes = useSelector(selectMeasurementsTypesConf);
    const entities = useSelector(selectEntities);

    return <>
        <MetricDetailsView metricId={metric?._id} complex={metric?.complex || metric?.metricType === "complexMetrics" || metric?.categoryType === "Complex"} openDialog={Boolean(metric)} small onClose={() => setMetric(null)} />
        <List dense>
            {metrics.map((metric, index) => {
                const measureType = measurementTypes.find(el => el.key === metric?.category?.scope?.measurementTypeKey);
                return <ListItem
                    key={metric._id}
                    secondaryAction={
                        <Tooltip title={<FormattedMessage id="ESG.METRIC.VIEW" />} placement="left">
                            <span>
                                <IconButton size="small" color="primary" edge="end" aria-label="view" onClick={() => setMetric(metric)}>
                                    <PreviewOutlined fontSize="small" />
                                </IconButton>
                            </span>
                        </Tooltip>
                    }
                >
                    <ListItemText
                        primaryTypographyProps={{ variant: 'button' }}
                        secondaryTypographyProps={{ variant: 'span' }}
                        primary={<span><Typography color="text.disabled" display="inline">{index + 1}.</Typography> {metric.name} {compact && <Typography color="text.secondary" display="inline" variant="caption">{entities[metric.entityId]?.name}</Typography>}</span>}
                        secondary={!compact ? <Stack direction="column" alignItems="flex-start">
                            {metric.complex || metric.metricType === "complexMetrics"
                                ? <FormattedMessage id="ESG.METRIC.COMPLEX" />
                                : Boolean(metric.category) && (metric.category.type === "Qualitative"
                                    ? <FormattedMessage id="ESG.METRIC.QUALITATIVE" />
                                    : <span><FormattedMessage id="ESG.METRIC.QUANTITATIVE" /> {measureType && <span>({measureType.name || <FormattedMessage id={measureType.key} />})</span>}</span>
                                )}
                            <Typography color="primary" display="inline"> {entities[metric.entityId]?.name}</Typography>
                        </Stack>
                            : null}
                    />
                </ListItem>
            })}
        </List>
    </>
}