import { CardHeader, Dialog, DialogContent, Divider, IconButton, TextField, useMediaQuery } from "@mui/material";
import { CheckOutlined, CloseOutlined } from "@mui/icons-material";
import { FormattedMessage, useIntl } from "react-intl";
import { useEffect, useState } from "react";

export default function MetricValueFormDialog({ open, onClose, subtitle, value, onSubmit }) {
    const intl = useIntl();
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const [editValue, setEditValue] = useState("");

    const handleSubmit = () => {
        if (value !== editValue && typeof onSubmit === 'function') onSubmit(editValue);
    }
    const handleClose = () => {
        if (typeof onClose === 'function') onClose();
    }

    useEffect(() => {
        if (value || !open) setEditValue(value);
    }, [value, open]);

    return <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth fullScreen={smallScreen}>
        <CardHeader title={<FormattedMessage id="ESG.METRIC.VALUE" />} subheader={subtitle} action={[
            <IconButton disabled={value === editValue} key="edit" color="success" onClick={handleSubmit}><CheckOutlined /></IconButton>,
            <IconButton key="close" onClick={handleClose}><CloseOutlined /></IconButton>,
        ]} />
        <Divider />
        <DialogContent>
            <TextField fullWidth label={intl.formatMessage({ id: "NEW_VALUE" })} value={editValue} onChange={(e) => setEditValue(e.target.value)} multiline />
        </DialogContent>
    </Dialog>
}