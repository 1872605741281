import { Alert, Snackbar, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

export default function AlertSnackbar(props) {
    const { open, onClose, hideCloseButton, autoHide, keepMounted, severity, messageId, action, anchorOrigin } = props;
    const autoHideDuration = Number(autoHide);
    return <Snackbar
        anchorOrigin={(messageId !== "UNAUTHORIZED" && anchorOrigin) || { vertical: 'top', horizontal: 'center' }}
        open={open}
        onClose={(e) => onClose()}
        key={"alert-message"}
        autoHideDuration={keepMounted ? null : (autoHideDuration || 5000)}
    >
        <Alert severity={severity} sx={{ width: '100%' }} onClose={hideCloseButton ? undefined : onClose} action={action}>
            <Typography>
                <FormattedMessage id={messageId} />
            </Typography>
        </Alert>
    </Snackbar>
}