import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from 'api';

const initialState = {
  data: {
    entities: {},
    measureEntities: {}
  },
  status: 'idle',
  error: null
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchEntities = createAsyncThunk('entities/fetchEntities', async (token, { rejectWithValue }) => {
  const { getEntitiesConfiguration } = API.configuration;
  if (token) {
    try {
      const response = await getEntitiesConfiguration(token);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      if (!error?.response) throw error;
      return rejectWithValue(error);
    }
  }
  return initialState.data;
}
);

export const entitySlice = createSlice({
  name: 'entities',
  initialState,
  reducers: {
    updateEntity: (state, action) => {
      state.data.entities[action.payload._id] = action.payload;
    },
    addNewEntity: (state, action) => {
      state.data.entities[action.payload._id] = action.payload;
      if (action.payload.connections.parentId) {
        state.data.entities[action.payload.connections.parentId].connections.childrenArray.push(action.payload._id)
      }
    },
    deleteEntity: (state, action) => {
      if (action.payload[0].connections.parentId) {
        const index = state.data.entities[action.payload[0].connections.parentId].connections.childrenArray.indexOf(action.payload[0]._id);
        state.data.entities[action.payload[0].connections.parentId].connections.childrenArray.splice(index, 1);
      }
      action.payload.forEach(ent => {
        delete state.data.entities[ent._id]
        if (state.data.measureEntities[ent._id]) delete state.data.measureEntities[ent._id];
      });
    },
    updateMeasureEntity: (state, action) => {
      state.data.measureEntities[action.payload.entityId] = action.payload;
    },
    deleteMeasureEntity: (state, action) => {
      delete state.data.measureEntities[action.payload];
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchEntities.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchEntities.rejected, (state, action) => {
        console.error("Failed to load entities...");
        if (!action.payload) state.error = "CRASHED";
        else if (action.payload.data?.id === "UNAUTHORIZED") state.error = "UNAUTHORIZED";
        else state.error = null;
        state.status = 'failed';
      })
      .addCase(fetchEntities.fulfilled, (state, action) => {
        state.status = 'complete';
        state.data = {
          entities: action.payload.entities,
          measureEntities: action.payload.measureEntities
        };
      });
  },
});

export const { addNewEntity, updateEntity, deleteEntity, updateMeasureEntity, deleteMeasureEntity } = entitySlice.actions;

// Selectors
export const selectEntities = (state) => {
  return state.entities.data.entities;
}
export const selectMeasureEntities = (state) => {
  return state.entities.data.measureEntities;
}

export default entitySlice.reducer;