import { useState, useEffect, useCallback } from 'react';
import { Card, CardHeader, CardContent, Stack, CardActions, IconButton, Tooltip, Typography, Chip } from "@mui/material";
import { DisplayBox, LoadingData, AlertSnackbar, AlarmButtons, AlarmSeverityIcon } from 'components';
import { NotificationMethodIcon } from 'components/notesView/NotesView';
import { useNavigate } from 'react-router';
import { ListAltOutlined } from '@mui/icons-material';
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { userSelector } from "redux/userSlice";
import { selectEntities } from 'redux/entitySlice';
import { selectSeverityTypes, selectMeasurementsTypesConf } from 'redux/configurationSlice';
import { formatDateLocale } from 'utils';
import API from "api";

const AlarmDetailsView = ({ alarm, alarmId, openDrawer }) => {

    const intl = useIntl();
    const navigate = useNavigate();
    const [disableActions, setDisableActions] = useState(false);
    const severityTypes = useSelector(selectSeverityTypes);
    const entities = useSelector(selectEntities);
    const measurementTypes = useSelector(selectMeasurementsTypesConf);

    const { token } = useSelector(userSelector);
    const [defaultAlarm, setAlarm] = useState({});
    const [reset, setReset] = useState(0);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ open: false });

    const onAlertClose = () => setAlert({ ...alert, open: false });

    useEffect(() => {
        if (alarmId) {
            setLoading(true);
            API.alarm.getSingleAlarm(alarmId, token).then(response => {
                if (response && response.data) {
                    const severity = severityTypes.find(el => el.key === response.data.severityType).value;
                    setAlarm({ ...response.data, severity: intl.formatMessage({ id: "ALARMS.PROFILE_RULES." + severity }) })
                }
                else setAlarm({})
                setLoading(false);
            }).catch(error => {
                setLoading(false);
                console.error(error);
            });
        }
        else if (alarm) setAlarm(alarm);
        else return () => { };

    }, [token, alarm, alarmId, intl, severityTypes, reset]);

    const alarmDetails = useCallback(() => {
        const severity = severityTypes.find(el => el.key === defaultAlarm?.severityType)?.value;
        return loading ? <LoadingData />
            :
            <Stack direction="column" gap={1}>
                <DisplayBox label={<FormattedMessage id="NAME" />} value={defaultAlarm?.name} />
                <DisplayBox label={<FormattedMessage id="ALARM.MESSAGE" />} value={defaultAlarm.message?.length > 0 ? <Typography sx={{ fontWeight: 'bold' }}>{defaultAlarm.message}</Typography> : null} />
                <DisplayBox label={<FormattedMessage id="ENTITY" />} value={entities[defaultAlarm?.entityId]?.name} />
                <DisplayBox label={<FormattedMessage id="SEVERITY" />} value={<span><AlarmSeverityIcon severity={severity} /> {intl.formatMessage({ id: "ALARMS.PROFILE_RULES." + severity })}</span>} />
                <DisplayBox label={<FormattedMessage id="TRIGGERED_TIME" />} value={formatDateLocale(defaultAlarm?.triggeredTime)} />
                <DisplayBox label={<FormattedMessage id="STATUS" />} value={defaultAlarm?.sendStatus && defaultAlarm?.sendStatus.map((el, index) => <Chip key={el.statusType + index} variant='outlined' sx={{ mb: 0.5, borderColor: 'transparent' }} icon={<NotificationMethodIcon noTooltip type={el.statusType} />} label={<FormattedMessage id={"NOTE.STATUS." + el.message} />} />)} />
                <DisplayBox label={<FormattedMessage id="CONDITIONS_PASSED" />} value={defaultAlarm?.conditionsPassed && defaultAlarm.conditionsPassed.map((el, index) => {
                    const measureType = measurementTypes.find(type => type.key === el.measurementTypeKey);
                    return <Card key={el.measurementTypeKey + index} sx={{ mb: 0.5 }}>
                        <CardHeader title={measureType.name || <FormattedMessage id={measureType.key} />} subheader={formatDateLocale(el.timestamp)} />
                        <CardContent sx={{ pt: 0 }}>
                            <Typography variant="h4" color="primary" component="span" display="inline">{el.value}</Typography>
                            <Typography component="span" display="inline">{el.unit}</Typography>
                        </CardContent>
                    </Card>;
                })} />

                {defaultAlarm?.acknowledgedTime && <DisplayBox label={<FormattedMessage id="ACKNOWLEDGED_TIME" />} value={formatDateLocale(defaultAlarm?.acknowledgedTime)} />}
                {defaultAlarm?.acknowledgedBy && <DisplayBox label={<FormattedMessage id="ACKNOWLEDGED_BY" />} value={defaultAlarm?.acknowledgedBy} />}
                {defaultAlarm?.clearedTime && <DisplayBox label={<FormattedMessage id="CLEARED_TIME" />} value={formatDateLocale(defaultAlarm?.clearedTime)} />}
                {defaultAlarm?.clearedBy && <DisplayBox label={<FormattedMessage id="CLEARED_BY" />} value={defaultAlarm?.clearedBy} />}
            </Stack>
    }, [severityTypes, defaultAlarm, intl, entities, measurementTypes, loading]);

    const handleAcknowledgeAlarm = () => API.alarm.acknowledgeAlarm(alarmId, token).then(response => {
        if (response?.data) {
            setAlert({ open: true, messageId: "ALARM.ACKNOWLEDGE_SUCCESS", severity: "success" });
            setReset(reset => reset + 1)
            setDisableActions(false);
        }
    }).catch(error => {
        setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_ACKNOWLEDGED", severity: "error" });
        console.error(error);
        setDisableActions(false);
    });

    const handleClearAlarm = () => API.alarm.clearAlarm(alarmId, token).then(response => {
        if (response?.data) {
            setAlert({ open: true, messageId: "ALARM.CLEAR_SUCCESS", severity: "success" });
            setReset(reset => reset + 1)
            setDisableActions(false);
        }
    }).catch(error => {
        setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_CLEARED", severity: "error" });
        console.error(error);
        setDisableActions(false);
    });

    return <>
        <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
        {!openDrawer ?
            <Card sx={{ mx: 'auto', minWidth: '50%' }}>
                <CardHeader title={<FormattedMessage id="ALARM.DETAILS" />} action={<Tooltip title={<FormattedMessage id="ALL_ALARMS" />} placement="left">
                    <IconButton color="primary" onClick={() => navigate('/alarms')}><ListAltOutlined /></IconButton>
                </Tooltip>} />
                <CardContent sx={{ pb: 2 }}>
                    {alarmDetails()}
                </CardContent>
                {!loading && <CardActions>
                    <AlarmButtons
                        alarm={defaultAlarm}
                        smallScreen={false}
                        openDrawer={false}
                        handleAcknowledgeAlarm={() => handleAcknowledgeAlarm(alarmId)}
                        handleClearAlarm={() => handleClearAlarm(alarmId)}
                        disableAcknowledge={Boolean(disableActions || defaultAlarm.acknowledgedBy)}
                        disableClear={Boolean(disableActions || defaultAlarm.clearedBy)}
                    />
                </CardActions>}
            </Card>
            : alarmDetails()}
    </>
}
export default AlarmDetailsView;
