
import { FormattedMessage } from 'react-intl';
import { Backdrop, Typography, Box, useTheme } from '@mui/material';
import { LoadingDrop } from 'components';

const PageLoading = (props) => {
    const { messageId } = props;
    const theme = useTheme();

    return (
        <Backdrop open sx={{ color: theme.palette.background.paper, zIndex: (theme) => theme.zIndex.drawer + 1, bgcolor: theme.palette.primary.main }} >
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 8 }}>
                <LoadingDrop />
                {messageId && <Typography className='jello-horizontal' variant="h6" color="inherit">
                    <FormattedMessage id={messageId} />
                </Typography>}
            </Box>
        </Backdrop>
    );
}

export default PageLoading;