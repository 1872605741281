import { Chip, Typography, Tooltip, Box } from "@mui/material";
import { NotificationMethodIcon } from "components/notesView/NotesView";
import { FormattedMessage } from "react-intl";
import { AlarmSeverityIcon, ExpandedGridCell } from "components";
import { getStatusColor } from "utils";

export const renderDateCell = (intl) => {
    const locale = intl.locale === 'en' ? 'en-GB' : intl.locale;
    return {
        renderCell: (row) => {
            const invalidDate = row?.value?.toString() === 'Invalid Date';
            const value = invalidDate ? intl.formatMessage({ id: 'NONE' }) : row.value?.toLocaleString(locale);
            if (!value) return null;
            return <Tooltip title={value}>
                <Typography color={"text." + (invalidDate ? "disabled" : "primary")} style={{ maxWidth: `${row.colDef.computedWidth}px`, overflow: 'hidden', textOverflow: 'ellipsis' }}>{value}</Typography>
            </Tooltip>
        },
        minWidth: 150,
    }
}

export const dashboardTableHeaders = (smallScreen, intl, entities, statusTypes, priorityTypes, topics, notificationMethodTypes, severityTypes) => [
    {
        modelProp: ["name"],
        field: 'name',
        headerName: intl.formatMessage({ id: "NAME" }),
        minWidth: 200,
        ...(!smallScreen && { flex: 1 }),
        ...(smallScreen && { width: 200 }),
    },
    {
        modelProp: ["title"],
        field: 'title',
        headerName: intl.formatMessage({ id: 'TITLE' }),
        ...(!smallScreen && { flex: 2 }),
        editable: true,
        renderCell: (row) => <Typography component="span">{row.row.title} {row.row.archived && (<Chip size="small" label={<FormattedMessage id="NOTE.ARCHIVED" />} />)}</Typography>,
        minWidth: 300
    },

    {
        modelProp: ["description"],
        field: 'description',
        headerName: intl.formatMessage({ id: "DESCRIPTION" }),
        minWidth: 200,
        ...(!smallScreen && { flex: 1 }),
        ...(smallScreen && { width: 200 }),
    },
    {
        modelProp: ["content"],
        field: 'content',
        headerName: intl.formatMessage({ id: "NOTE.CONTENT" }),
        minWidth: 200,
        ...(!smallScreen && { flex: 1 }),
        ...(smallScreen && { width: 200 }),
    },
    {
        modelProp: ["owner"],
        field: 'owner',
        headerName: intl.formatMessage({ id: "OWNER" }),
        minWidth: 100,
        ...(!smallScreen && { flex: 1 }),
        ...(smallScreen && { width: 200 }),
    },
    {
        modelProp: ["_id"],
        field: 'id',
        headerName: intl.formatMessage({ id: "ID" }),
        ...(!smallScreen && { flex: 1 }),
        ...(smallScreen && { width: 150 }),
    },
    {
        modelProp: ["enabled"],
        field: 'enabled',
        headerName: intl.formatMessage({ id: "ENABLED" }),
        ...(!smallScreen && { flex: 1 }),
        ...(smallScreen && { width: 200 }),
        type: 'boolean',
        valueGetter: (row) => Boolean(row.row.enabled),
    },
    {
        modelProp: ["timestamp"],
        field: 'date',
        type: 'dateTime',
        headerName: intl.formatMessage({ id: "DATE" }),
        valueGetter: ({ value }) => new Date(value ? value : undefined),
        ...renderDateCell(intl),
        ...(!smallScreen && { flex: 1 }),
        ...(smallScreen && { width: 200 }),
    },
    {
        modelProp: ["measurementTypeKey"],
        field: 'measurementType',
        headerName: intl.formatMessage({ id: "MEASUREMENT_TYPE" }),
        ...(!smallScreen && { flex: 1 }),
        ...(smallScreen && { width: 200 }),
    },
    {
        modelProp: ["value"],
        field: 'value',
        headerName: intl.formatMessage({ id: "VALUE" }),
        ...(!smallScreen && { flex: 1 }),
        ...(smallScreen && { width: 150 }),
    },
    {
        modelProp: ["unit", "reportData.reportUnit.unit"],
        field: 'unit',
        headerName: intl.formatMessage({ id: "UNIT" }),
        minWidth: 100,
        ...(!smallScreen && { flex: 1 }),
        ...(smallScreen && { width: 150 }),
    },
    {
        modelProp: ["entityId"],
        field: 'entityId',
        headerName: intl.formatMessage({ id: "ENTITY" }),
        valueGetter: (row) => entities[row.row.entityId]?.name,
        ...(smallScreen && { width: 150 }),
    },
    {
        modelProp: ["type", "category"],
        field: 'type',
        headerName: intl.formatMessage({ id: 'ESG.TYPE' }),
        ...(!smallScreen && { flex: 1 }),
        valueGetter: (row) => intl.formatMessage({ id: row.row.title ? "NOTE.TYPE." + row.row.typeValue : "ESG.TYPE." + row.row.type }),
        minWidth: 150
    },
    {
        modelProp: ["category.type",],
        field: 'categoryType',
        headerName: intl.formatMessage({ id: 'ESG.METRIC.CATEGORY' }),
        ...(!smallScreen && { flex: 1 }),
        valueGetter: (row) => intl.formatMessage({ id: "ESG.METRIC.TYPE." + row.row.categoryType?.toUpperCase() }),
        minWidth: 150
    },
    {
        modelProp: ["reportData.createdAt"],
        field: 'createdAt',
        type: 'dateTime',
        headerName: intl.formatMessage({ id: 'CREATED_AT' }),
        ...(!smallScreen && { flex: 1 }),
        valueGetter: ({ row }) => new Date(row.reportData.createdAt ? row.reportData.createdAt : undefined),
        ...renderDateCell(intl),
        minWidth: 180
    },
    {
        modelProp: ["reportData.startDate"],
        field: 'startDate',
        type: 'dateTime',
        headerName: intl.formatMessage({ id: 'START_DATE' }),
        ...(!smallScreen && { flex: 1 }),
        valueGetter: ({ row }) => new Date(row.reportData.startDate ? row.reportData.startDate : undefined),
        ...renderDateCell(intl),
        minWidth: 180
    },
    {
        modelProp: ["reportData.reportInterval"],
        field: 'reportInterval',
        headerName: intl.formatMessage({ id: 'REPORT_INTERVAL' }),
        valueGetter: (row) => row.row.reportInterval && intl.formatMessage({ id: "GROUPING." + row.row.reportInterval?.toUpperCase() }),
        minWidth: 150
    },
    {
        modelProp: ["reportData.dataFrequencyInterval"],
        field: 'dataFrequencyInterval',
        headerName: intl.formatMessage({ id: 'DATA_FREQUENCY_INTERVAL' }),
        valueGetter: (row) => row.row.dataFrequencyInterval && intl.formatMessage({ id: "GROUPING." + row.row.dataFrequencyInterval?.toUpperCase() }),
        minWidth: 150
    },
    {
        modelProp: ["reportData.calculationMethod"],
        field: 'calculationMethod',
        headerName: intl.formatMessage({ id: 'CALCULATION_METHOD' }),
        minWidth: 150
    },
    {
        modelProp: ["datetime.dateFrom", "dateRange.startDate"],
        field: 'dateFrom',
        type: 'dateTime',
        headerName: intl.formatMessage({ id: "DATE_FROM" }),
        ...(!smallScreen && { flex: 1 }),
        ...(smallScreen && { width: 200 }),
        valueGetter: ({ value }) => new Date(value ? value : undefined),
        ...renderDateCell(intl),
    },
    {
        modelProp: ["datetime.dateTo", "dateRange.endDate"],
        field: 'dateTo',
        type: 'dateTime',
        headerName: intl.formatMessage({ id: "DATE_TO" }),
        ...(!smallScreen && { flex: 1 }),
        ...(smallScreen && { width: 200 }),
        valueGetter: ({ value }) => new Date(value ? value : undefined),
        ...renderDateCell(intl)
    },
    {
        modelProp: ["datetime.lastUpdated"],
        field: 'lastUpdated',
        type: 'dateTime',
        headerName: intl.formatMessage({ id: "LAST_UPDATED" }),
        ...(!smallScreen && { flex: 1 }),
        ...(smallScreen && { width: 200 }),
        valueGetter: ({ value }) => new Date(value ? value : undefined),
        ...renderDateCell(intl),
    },
    {
        modelProp: ["lastUpdatedBy"],
        field: 'lastUpdatedBy',
        headerName: intl.formatMessage({ id: "COLLECTOR" }),
        ...(!smallScreen && { flex: 1 }),
        ...(smallScreen && { width: 200 }),
    },
    {
        modelProp: ["notificationMethod"],
        field: 'notificationMethod',
        headerName: intl.formatMessage({ id: 'NOTIFICATIONS.METHOD' }),
        ...(!smallScreen && { flex: 1 }),
        valueGetter: (row) => row.row.notificationMethod.map(method => intl.formatMessage({ id: "NOTIFICATIONS.METHOD." + notificationMethodTypes.find(el => el.key === method).value })).join(","),
        renderCell: (row) => row.row.notificationMethod?.map(el => <NotificationMethodIcon key={el} type={el} status={row.row.sendStatus.find(s => s.statusType === el)?.message} />),
        minWidth: 150
    },
    {
        modelProp: ["status"],
        field: 'status',
        headerName: intl.formatMessage({ id: 'STATUS' }),
        ...(!smallScreen && { flex: 1 }),
        valueGetter: (row) => {
            const status = statusTypes.find(status => status.key === row.row.status)
            return intl.formatMessage({ id: "STATUS." + status.value });
        },
        renderCell: (row) => <Typography color={getStatusColor(row.row.status)}>
            {row.value}
        </Typography>,
        minWidth: 150
    },
    {
        modelProp: ["topicId"],
        field: 'topicId',
        headerName: intl.formatMessage({ id: 'ESG.TOPIC' }),
        ...(!smallScreen && { flex: 1 }),
        valueGetter: (row) => topics.find(el => el._id === row.row.topicId)?.name,
        minWidth: 150
    },
    {
        modelProp: ["priority"],
        field: 'priority',
        headerName: intl.formatMessage({ id: 'PRIORITY' }),
        ...(!smallScreen && { flex: 1 }),
        valueGetter: (row) => intl.formatMessage({ id: "PRIORITY." + priorityTypes.find(type => type.key === row.row.priority).value }),
        minWidth: 150
    },
    {
        modelProp: ["measurable"],
        field: 'currentValue',
        headerName: intl.formatMessage({ id: "ESG.TARGET.VALUE.CURRENT" }),
        ...(!smallScreen && { flex: 2 }),
        editable: true,
        renderCell: (row) => <Typography>{row.row.currentValue}</Typography>,
        minWidth: 300
    },
    {
        modelProp: ["measurable"],
        field: 'endValue',
        headerName: intl.formatMessage({ id: "ESG.TARGET.VALUE.GOAL" }),
        ...(!smallScreen && { flex: 2 }),
        editable: true,
        renderCell: (row) => <Typography>{row.row.endValue}</Typography>,
        minWidth: 300
    },
    {
        modelProp: ["geoProperties.address"],
        field: 'address',
        headerName: intl.formatMessage({ id: "ADDRESS" }),
        ...(!smallScreen && { flex: 1 }),
        ...(smallScreen && { width: 200 }),
    },
    {
        modelProp: ["active"],
        field: 'active',
        headerName: intl.formatMessage({ id: "ACTIVE" }),
        ...(!smallScreen && { flex: 1 }),
        ...(smallScreen && { width: 200 }),
        type: 'boolean',
        valueGetter: (row) => Boolean(row.row.active),
    },
    {
        modelProp: ["goalId"],
        field: 'goalId',
        headerName: intl.formatMessage({ id: "ESG.GOAL" }),
        ...(!smallScreen && { flex: 1 }),
        ...(smallScreen && { width: 200 }),
        minWidth: 100,
    },
    {
        modelProp: ["metricId"],
        field: 'metricId',
        headerName: intl.formatMessage({ id: "ESG.METRIC" }),
        ...(!smallScreen && { flex: 1 }),
        ...(smallScreen && { width: 200 }),
        valueGetter: ({ row }) => {
            if (typeof row.metricId === 'string' && row.metricName) return row.metricName;
            else if (typeof row.metricId === 'object' && row.metricId?.name && row.metricId?.entityId) return `${row.metricId.name} (${entities[row.metricId.entityId].name})`;
        },
        minWidth: 100,
    },
    {
        modelProp: ["message"],
        field: 'message',
        headerName: intl.formatMessage({ id: "ALARM.MESSAGE" }),
        ...(!smallScreen && { flex: 1 }),
        ...(smallScreen && { width: 200 }),
    },
    {
        modelProp: ["collector"],
        field: 'collector',
        headerName: intl.formatMessage({ id: "COLLECTOR" }),
        ...(!smallScreen && { flex: 1 }),
        ...(smallScreen && { width: 200 }),
    },
    {
        modelProp: ["profileId"],
        field: 'profileId',
        headerName: intl.formatMessage({ id: "DASHBOARD.OPTIONS.profileId" }),
        ...(!smallScreen && { flex: 1 }),
        ...(smallScreen && { width: 200 }),
    },
    {
        modelProp: ["triggeredTime"],
        field: 'triggeredTime',
        type: 'dateTime',
        headerName: intl.formatMessage({ id: "TRIGGERED_TIME" }),
        ...(!smallScreen && { flex: 1 }),
        ...(smallScreen && { width: 200 }),
        valueGetter: ({ row }) => new Date(row.triggeredTime ? row.triggeredTime : undefined),
        ...renderDateCell(intl),
    },
    {
        modelProp: ["clearedTime"],
        field: 'clearedTime',
        type: 'dateTime',
        headerName: intl.formatMessage({ id: "CLEARED_TIME" }),
        ...(!smallScreen && { flex: 1 }),
        ...(smallScreen && { width: 200 }),
        valueGetter: ({ row }) => new Date(row.clearedTime ? row.clearedTime : undefined),
        ...renderDateCell(intl),
    },
    {
        modelProp: ["clearedBy"],
        field: 'clearedBy',
        headerName: intl.formatMessage({ id: "CLEARED_BY" }),
        ...(!smallScreen && { flex: 1 }),
        ...(smallScreen && { width: 200 }),
    },
    {
        modelProp: ["acknowledgedTime"],
        field: 'acknowledgedTime',
        type: 'dateTime',
        headerName: intl.formatMessage({ id: "ACKNOWLEDGED_TIME" }),
        ...(!smallScreen && { flex: 1.2 }),
        ...(smallScreen && { width: 200 }),
        valueGetter: ({ row }) => new Date(row.acknowledgedTime ? row.acknowledgedTime : undefined),
        ...renderDateCell(intl),
    },
    {
        modelProp: ["acknowledgedBy"],
        field: 'acknowledgedBy',
        headerName: intl.formatMessage({ id: "ACKNOWLEDGED_BY" }),
        ...(!smallScreen && { flex: 1 }),
        ...(smallScreen && { width: 200 }),
    },
    {
        modelProp: ["severityType"],
        field: 'severityType',
        headerName: intl.formatMessage({ id: "SEVERITY" }),
        ...(!smallScreen && { flex: 1 }),
        ...(smallScreen && { width: 200 }),
        valueGetter: (row) => intl.formatMessage({ id: "ALARMS.PROFILE_RULES." + severityTypes.find(type => type.key === row.row.severityType)?.value }),
        renderCell: (row) => <Typography> <AlarmSeverityIcon severity={severityTypes.find(type => type.key === row.row.severityType)?.value} />{row.value}</Typography>,
        minWidth: 150,
    },
    {
        modelProp: ["rules"],
        field: 'rules',
        headerName: intl.formatMessage({ id: 'ALARMS.PROFILE_RULES' }),
        type: 'array',
        valueGetter: ({ row }) => row?.rules ? Object.keys(row.rules).map(el => intl.formatMessage({ id: "ALARMS.PROFILE_RULES." + el })).join(",") : "undefined",
        renderCell: ({ row, value }) => <Box sx={{ width: 250, overflowX: 'auto' }}>
            {value !== "undefined"
                ? <Typography component="span">
                    {Object.keys(row.rules).map(el => <Chip key={el} component="span" variant="outlined" size="small" sx={{ mr: 0.5, '& .MuiChip-label': { pl: 0 } }} avatar={<AlarmSeverityIcon severity={el} customStyle={{ width: 20, height: 20, py: 0.5 }} />} label={intl.formatMessage({ id: "ALARMS.PROFILE_RULES." + el })} />)}
                </Typography>
                : <Chip size="small" variant="outlined" label={intl.formatMessage({ id: value })} />
            }
        </Box>,
        width: 250,
    },
    {
        modelProp: ["KPI.turnover"],
        field: 'turnover',
        headerName: intl.formatMessage({ id: "turnover" }),
        ...(!smallScreen && { flex: 1 }),
        ...(smallScreen && { width: 200 }),
    },
    {
        modelProp: ["KPI.capEx"],
        field: 'capEx',
        headerName: intl.formatMessage({ id: "capEx" }),
        ...(!smallScreen && { flex: 1 }),
        ...(smallScreen && { width: 200 }),
    },
    {
        modelProp: ["KPI.opEx"],
        field: 'opEx',
        headerName: intl.formatMessage({ id: "opEx" }),
        ...(!smallScreen && { flex: 1 }),
        ...(smallScreen && { width: 200 }),
    },
    {
        modelProp: ["engagementExamples"],
        field: "engagementExamples",
        type: 'array',
        headerName: intl.formatMessage({ id: 'ENGAGEMENT' }),
        flex: 1,
        renderCell: (row) => row.value?.length ? <ExpandedGridCell list value={row.value} width={row.colDef.computedWidth} /> : <Typography color="text.disabled"><FormattedMessage id="NO_DATA" /></Typography>,
        minWidth: 100
    },
    {
        modelProp: ["raisedConcerns"],
        field: 'raisedConcerns',
        type: 'array',
        headerName: intl.formatMessage({ id: 'CONCERNS_AND_TOPICS' }),
        flex: 1,
        renderCell: (row) => row.value?.length ? <ExpandedGridCell list value={row.value} width={row.colDef.computedWidth} /> : <Typography color="text.disabled"><FormattedMessage id="NO_DATA" /></Typography>,
        minWidth: 100
    },
];
