import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { userSelector, permissionSelector } from "redux/userSlice";
import { Tooltip, Alert, Card, CardContent, CardHeader, useMediaQuery, Typography, IconButton, Grid, Chip, Stack, Dialog } from "@mui/material";
import { EditOutlined, DeleteOutline, AddOutlined, RestorePageOutlined, TroubleshootOutlined, HistoryOutlined } from "@mui/icons-material";
import { selectEntities } from 'redux/entitySlice';
import { removeMetricFromTopic } from "redux/topicSlice";
import { selectMeasurementsTypesConf } from "redux/configurationSlice";
import { useParams } from "react-router";
import { ConfirmationDialog, MetricDetailsView, BreadcrumbsAndNavigation, MetricForm, AlertSnackbar, CustomDataGrid, ExpandedGridCell, SideDrawer, DisplayVersion, MetricReportDetailCard, MetricHistoryReportView } from "components";
import { renderDateCell } from "components/dashboardView/TableHeaders";
import API from "api";


const MetricsView = (props) => {
    const { token } = useSelector(userSelector);
    const intl = useIntl();
    const entityId = useParams().entityId;
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const dispatch = useDispatch();

    const [metrics, setMetrics] = useState([]);
    const [selectedMetric, setSelectedMetric] = useState(null);

    const entities = useSelector(selectEntities);
    const measurementTypes = useSelector(selectMeasurementsTypesConf);

    const [openReport, setOpenReport] = useState(null);
    const [loading, setLoading] = useState(false);
    const [reloadData, setReloadData] = useState(false);
    const [alert, setAlert] = useState({ open: false });
    const [openViewDialog, setOpenViewDialog] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [openForm, setOpenForm] = useState(false);
    const [openHistoryList, setOpenHistoryList] = useState(false);
    const [reset, setReset] = useState(0);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [metricLogs, setMetricLogs] = useState({ loading: true });

    const createMetricPermission = useSelector((state) => permissionSelector(state, 'create-metric'));
    const updateMetricPermission = useSelector((state) => permissionSelector(state, 'update-metric'));
    const deleteMetricPermission = useSelector((state) => permissionSelector(state, 'delete-metric'));
    const viewLogsPermission = useSelector((state) => permissionSelector(state, 'view-change-logs'));

    const onAlertClose = () => setAlert({ ...alert, open: false });

    useEffect(() => {
        setSelectedMetric('')
    }, [entityId]);

    useEffect(() => {
        setLoading(true);
        API.metrics.getMetricAndComplexMetric(entityId, token).then(items => {
            if (items?.data) {
                const data = items.data.metrics.map(item => {
                    return {
                        ...item,
                        id: item._id,
                        reportInterval: item.reportData.reportInterval,
                        categoryType: item.category.type,
                    }
                })
                const complexData = items.data.complexMetrics.map(item => {
                    return {
                        ...item,
                        id: item._id,
                        reportInterval: item.reportData.reportInterval,
                        categoryType: "Complex",
                    }
                })
                setMetrics([...data, ...complexData]);
            }
            else setMetrics([])
            setLoading(false);

        }).catch(error => {
            setAlert({ open: true, messageId: error?.data?.id || "ERROR.FETCHING_DATA", severity: "error" });
            setLoading(false);
            setMetrics({ error });
            console.error(error);
        });
    }, [reloadData, token, entityId, reset]);

    useEffect(() => {
        if (selectedMetric && openDrawer && viewLogsPermission) {
            setMetricLogs({ loading: true });
            API.logs.getLogsById(token, selectedMetric._id).then(response => {
                if (response.data) {
                    setMetricLogs(response.data)
                }
            }).catch(error => {
                setMetricLogs([]);
                console.error(error);
            })
        }
        else return;
    }, [selectedMetric, token, viewLogsPermission, openDrawer])

    const handleDeleteMetric = () => {
        API.metrics[selectedMetric?.categoryType === "Complex" ? "deleteComplexMetric" : "deleteMetric"](token, selectedMetric._id).then(response => {
            if (response?.data) {
                dispatch(removeMetricFromTopic(response.data));
                setAlert({ open: true, messageId: "ESG.METRIC.DELETE_SUCCESS", severity: "success" });
                setReloadData(true);
                setOpenConfirmDialog(false);
            }
            setReset(reset + 1);
        }).catch(error => {
            setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_DELETED", severity: "error" });
            setOpenConfirmDialog(false);
            console.error(error);
        });
        setSelectedMetric('');
    }

    const renderTable = useCallback(() => {
        const headers = [
            {
                field: 'name',
                headerName: intl.formatMessage({ id: 'NAME' }),
                ...(!smallScreen && { flex: 2 }),
                renderCell: (row) => <ExpandedGridCell value={row.value} width={row.colDef.computedWidth} />,
                editable: true,
                minWidth: 300
            },
            !entityId && {
                field: 'entityId',
                headerName: intl.formatMessage({ id: 'ENTITY' }),
                valueGetter: (row) => entities[row.row.entityId]?.name,
                renderCell: ({ value }) => <Typography>{value}</Typography>,
                minWidth: 150
            },
            {
                field: 'type',
                headerName: intl.formatMessage({ id: 'ESG.METRIC.CATEGORY' }),
                ...(!smallScreen && { flex: 1 }),
                renderCell: ({ value }) => <Typography><FormattedMessage id={"ESG.TYPE." + value} /></Typography>,
                minWidth: 150
            },
            {
                field: 'categoryType',
                headerName: intl.formatMessage({ id: 'ESG.METRIC.TYPE' }),
                ...(!smallScreen && { flex: 1 }),
                renderCell: (row) => <Typography><FormattedMessage id={"ESG.METRIC.TYPE." + row.row.categoryType.toUpperCase()} /></Typography>,
                minWidth: 150
            },
            {
                field: 'createdAt',
                type: 'dateTime',
                headerName: intl.formatMessage({ id: 'CREATED_AT' }),
                ...(!smallScreen && { flex: 1 }),
                valueGetter: (row) => new Date(row.row.reportData.createdAt),
                ...renderDateCell(intl),
                minWidth: 180
            },
            {
                field: 'startDate',
                type: 'dateTime',
                headerName: intl.formatMessage({ id: 'START_DATE' }),
                ...(!smallScreen && { flex: 1 }),
                valueGetter: (row) => new Date(row.row.reportData.startDate),
                ...renderDateCell(intl),
                minWidth: 180
            },
            {
                field: 'reportInterval',
                headerName: intl.formatMessage({ id: 'REPORT_INTERVAL' }),
                renderCell: ({ value }) => <FormattedMessage id={"GROUPING." + value.toUpperCase()} />,
                minWidth: 150
            },
            {
                field: 'actions',
                headerName: intl.formatMessage({ id: 'ACTIONS' }),
                type: 'actions',
                getActions: (el) => {
                    const actions = [
                        <Tooltip key="latest-report" title={<FormattedMessage id="LATEST_REPORT" />} placement="bottom" arrow>
                            <IconButton component="span" color="primary" onClick={() => setOpenReport({ name: el.row.name, metricId: { ...el.row } })}> <TroubleshootOutlined fontSize="small" /> </IconButton>
                        </Tooltip>,
                        <Tooltip title={<FormattedMessage id="ESG.METRIC.PREVIOUS_REPORTS_VIEW" />} placement="bottom" key={'PREVIOUS_VIEW'} arrow>
                            <IconButton color="primary" onClick={() => { setSelectedMetric(el.row); setOpenHistoryList(true) }}><HistoryOutlined fontSize="small" /></IconButton>
                        </Tooltip>
                    ];
                    if (viewLogsPermission) actions.push(<Tooltip title={<FormattedMessage id="VIEW_LOGS" />} placement="bottom" arrow>
                        <IconButton component="span" color="info" key="edit" onClick={() => { setSelectedMetric(el.row); setOpenDrawer(true) }}> <RestorePageOutlined fontSize="small" /> </IconButton>
                    </Tooltip>);
                    if (entityId && updateMetricPermission) actions.push(<Tooltip title={<FormattedMessage id="ESG.METRIC.EDIT" />} placement="bottom" arrow>
                        <IconButton component="span" color="secondary" key="edit" onClick={() => { setSelectedMetric(el.row); setOpenForm(true) }}> <EditOutlined fontSize="small" /> </IconButton>
                    </Tooltip>);
                    if (entityId && deleteMetricPermission) actions.push(<Tooltip title={<FormattedMessage id="ESG.METRIC.DELETE" />} placement="bottom" arrow>
                        <IconButton component="span" color="error" key="delete" onClick={() => { setSelectedMetric(el.row); setOpenConfirmDialog(true) }}><DeleteOutline fontSize="small" /></IconButton>
                    </Tooltip>)

                    return actions;
                },
                minWidth: 250,
                flex: 1,
            },
        ].filter(el => el);

        if (metrics?.error) return <Alert severity='error'><FormattedMessage id="ERROR.NO_DATA" /></Alert>;
        else return <CustomDataGrid isLoading={loading} handleData={(el) => { setOpenViewDialog(true); setSelectedMetric(el.row) }} rows={metrics} columns={headers} />

    }, [metrics, intl, loading, smallScreen, updateMetricPermission, deleteMetricPermission, entityId, viewLogsPermission, entities]);

    const onFormDialogClose = (resetFlag) => {
        setOpenForm(false);
        setSelectedMetric('');
        if (resetFlag) setReset(reset + 1);
    }
    const onDialogClose = () => {
        setOpenConfirmDialog(false);
        setSelectedMetric('');
    }
    const onViewClose = () => {
        setOpenViewDialog(false);
        setSelectedMetric('');
    };
    const onDrawerClose = () => {
        setOpenDrawer(false);
        setSelectedMetric('');
    };
    const onHistoryClose = () => {
        setOpenHistoryList(!openHistoryList);
        setSelectedMetric('');
    }

    return (
        <Grid container direction="column">
            <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
            <MetricForm open={openForm} onClose={onFormDialogClose} entityId={entityId} metric={selectedMetric} disableSwitch={Boolean(selectedMetric)} />
            <SideDrawer open={openDrawer} toggleDrawer={onDrawerClose} state={{
                title: <FormattedMessage id="LOGS" />,
                subtitle: selectedMetric?.name,
                display: <DisplayVersion data={metricLogs} />

            }} />
            <MetricHistoryReportView
                metricId={selectedMetric?._id}
                openDialog={openHistoryList}
                onCloseHandler={onHistoryClose}
                entityName={selectedMetric?.entityName}
                reportName={selectedMetric?.name}
                type={selectedMetric?.type}
                metricModel={selectedMetric?.categoryType === "Quantitative" ? "metric" : undefined}
                qualitative={selectedMetric?.categoryType === "Qualitative"}
                label={selectedMetric && selectedMetric.categoryType !== 'Complex'
                    ? measurementTypes.find(el => el.key === selectedMetric?.category?.scope?.measurementTypeKey)
                    : (selectedMetric && { name: selectedMetric.name, key: selectedMetric.name, unit: selectedMetric.reportData?.reportUnit?.unit })
                }
            />
            <ConfirmationDialog
                open={openConfirmDialog}
                content={selectedMetric && <Stack spacing={1}>
                    <Typography variant="h6" color="error">{selectedMetric.name}</Typography>
                    {selectedMetric?.targets?.length > 0 && <Typography component="span">
                        <FormattedMessage id="ESG.TARGET.NUMBER_LABEL.METRIC_PREFIX" />
                        <Typography color="error" component="span">
                            {selectedMetric.targets.length}<FormattedMessage id="ESG.TARGET.NUMBER_LABEL.SUFFIX" />
                        </Typography>
                    </Typography>}
                </Stack>}
                title={<FormattedMessage id="ESG.METRIC.CONFIRM_DELETE" />}
                customButtonTitle={<FormattedMessage id="DELETE" />}
                customButtonColor="error"
                handleCancel={onDialogClose}
                handleCustomButton={handleDeleteMetric}
            />
            <Dialog open={openReport !== null} onClose={() => setOpenReport(null)}>
                {openReport !== null && <MetricReportDetailCard metric={openReport} entity={entities[entityId]} complex={openReport.categoryType === "Complex"} onClose={() => setOpenReport(null)} />}
            </Dialog>
            <MetricDetailsView metricId={selectedMetric?._id} complex={selectedMetric?.categoryType === "Complex"} openDialog={openViewDialog} onClose={onViewClose} setOpenViewDialog={setOpenViewDialog} setOpenForm={setOpenForm} disableEdit={!entityId} />
            <BreadcrumbsAndNavigation entity={entities[entityId]} baseNavigate="/metrics" rootUrl="/metrics" metricsMode />
            <Card sx={{ width: '100%' }}>
                <CardHeader title={<FormattedMessage id="ESG.METRIC.TABLE" />}
                    action={createMetricPermission && <Chip disabled={loading} variant="outlined" onClick={() => setOpenForm(true)} icon={<AddOutlined fontSize="small" color="primary" />} label={<FormattedMessage id="ESG.METRIC.ADD" />} />}
                />
                <CardContent>
                    {renderTable()}
                </CardContent>
            </Card>
        </Grid>
    )
};
export default MetricsView;