import { Autocomplete, Box, Button, Card, CardActions, CardContent, CardHeader, Checkbox, Chip, Collapse, FormControlLabel, IconButton, TextField, Tooltip, Typography, CircularProgress } from "@mui/material";
import { FileDownloadOutlined, FileUploadOutlined, InfoOutlined, OpenInNewOutlined } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { selectEntities, selectMeasureEntities } from "redux/entitySlice";
import { useEffect, useRef, useState } from "react";
import { MeasurementsTypeTemplatePicker, AlertSnackbar, MeasurementsReportCard, ConfirmationDialog, MeasurementEntryLinks } from 'components';
import { userSelector, permissionSelector } from "redux/userSlice";
import API from 'api';


export default function MeasurementsFileEntryView() {
    const navigate = useNavigate();
    const navigateState = useLocation().state;
    const { token, username } = useSelector(userSelector);
    const measureEntities = useSelector(selectMeasureEntities);
    const entities = useSelector(selectEntities);
    const [selected, setSelected] = useState(navigateState ? navigateState : null);
    const [file, setFile] = useState(null);
    const [skipErrors, setSkipErrors] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [reportData, setReportData] = useState(null);
    const [alert, setAlert] = useState({ open: false });
    const fileInputRef = useRef(null);
    const [submitted, setSubmitted] = useState(false);

    const createMeasurementPermission = useSelector((state) => permissionSelector(state, 'create-measurement'));
    const createStagingMeasurementPermission = useSelector((state) => permissionSelector(state, 'create-staging-measurement'));
    const updateMetricPermission = useSelector((state) => permissionSelector(state, 'update-metric'));

    const onAlertClose = () => setAlert({ ...alert, open: false });

    useEffect(() => {
        if (!createMeasurementPermission && updateMetricPermission) {
            navigate('/measurementsEntry/metrics', { ...(selected && { state: selected }) })
        }
    }, [navigate, selected, createMeasurementPermission, updateMetricPermission])

    const handleInputChange = event => {
        setFile(event.target.files[0]);
    }

    const handleInputClick = event => {
        event.target.value = null;
        setFile(null);
    }

    const handleClick = event => {
        fileInputRef.current.click();
    }

    const handleCancel = () => {
        setFile(null);
        setSelected(null);
        setOpenConfirmDialog(false);
    }

    const handleUploadClick = () => {
        if (!(createMeasurementPermission || createStagingMeasurementPermission) || !file) return null;
        setSubmitted(true);
        setAlert({ open: true, messageId: "UPLOAD_HELPER_TEXT", severity: "info" });

        API.measurements.storeFromFile(token, username, selected.entityId, file, skipErrors)
            .then(({ data }) => {
                setReportData({ ...data, skipErrors: skipErrors });
                setAlert({ open: true, messageId: "SUCCESS.POST_MEASUREMENTS", severity: "success" });
                setSubmitted(false);
                setFile(null);
                setSkipErrors(null);
            }).catch(error => {
                console.error("An error has occured: ", error);
                setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_CREATED", severity: "error" });
                setSubmitted(false);
            });
    }


    return <>

        <ConfirmationDialog
            open={openConfirmDialog}
            title={<FormattedMessage id="MEASUREMENTS_ENTRY.CANCEL" />}
            customCancelTitle={<FormattedMessage id="NO" />}
            customButtonTitle={<FormattedMessage id="YES" />}
            handleCancel={() => setOpenConfirmDialog(false)}
            handleCustomButton={handleCancel}
        />
        <Card sx={{ mx: 'auto', maxWidth: 800, width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
            {selected && <MeasurementsTypeTemplatePicker open={openDialog} onClose={() => setOpenDialog(false)} types={selected.measurementTypes.manual.types} entityName={entities[selected.entityId].name} />}
            <div>
                <CardHeader
                    title={<FormattedMessage id="MEASUREMENTS_ENTRY.FILE" />}
                    subheader={<MeasurementEntryLinks file selected={selected} />}
                    action={selected && <Tooltip title={<FormattedMessage id="MEASURE_ENTITY.VIEW" />} placement="left">
                        <span><IconButton size="small" onClick={() => navigate('/measureEntities/' + selected.entityId)}><OpenInNewOutlined /></IconButton></span>
                    </Tooltip>}
                />
                {reportData ? <MeasurementsReportCard skipErrors={reportData.skipErrors} entityName={entities[selected.entityId].name} userName={username} measurements={reportData.measurements} errors={reportData.errors} onReset={() => setReportData(null)} />
                    : <>
                        <CardContent>
                            <Autocomplete
                                id="selectEntities"
                                options={Object.values(measureEntities)}
                                getOptionLabel={(option) => entities[option.entityId].name}
                                value={selected}
                                onChange={(e, option) => setSelected(option)}
                                isOptionEqualToValue={(option, value) => option._id === value._id}
                                renderInput={(params) => (
                                    <TextField {...params} label={<FormattedMessage id="ENTITY" />} />
                                )}
                                size="small"
                                fullWidth
                                sx={{ mb: 1 }}
                            />
                            <Collapse in={Boolean(selected)}>
                                <Button startIcon={<FileUploadOutlined />} onClick={handleClick}>
                                    <FormattedMessage id={file ? "FILE_CHANGE" : "FILE_SELECT"} />
                                </Button>
                                <input
                                    name="history-table"
                                    type="file"
                                    accept='.xlsx, .csv'
                                    ref={fileInputRef}
                                    onChange={handleInputChange}
                                    onClick={handleInputClick}
                                    style={{ display: "none" }}
                                />
                                <Button startIcon={<FileDownloadOutlined />} onClick={() => setOpenDialog(true)}>
                                    <FormattedMessage id="DOWNLOAD_TEMPLATE" />
                                </Button>
                                <br />
                                <Typography variant="caption" color="text.secondary"><FormattedMessage id="TIMESTAMP_FORMAT" /> <i><FormattedMessage id="TIMESTAMP_FORMATS_LIST" /></i></Typography>
                            </Collapse>
                            <Collapse in={Boolean(file)}>
                                <Box sx={{ mt: 2, display: 'flex-column', justifyContent: 'start' }}>
                                    <div>
                                        <Chip sx={{ mr: 2 }} label={<Typography variant="caption">{file && file.name}</Typography>} onDelete={() => setFile(null)} />
                                        <FormControlLabel
                                            control={<Checkbox color="warning" size="small" checked={skipErrors} onChange={() => setSkipErrors(!skipErrors)} />}
                                            label={
                                                <Typography variant="body2">
                                                    <FormattedMessage id="IGNORE_ERRORS" />
                                                    <Tooltip title={<FormattedMessage id="IGNORE_ERRORS_TOOLTIP" />} arrow>
                                                        <InfoOutlined color="disabled" sx={{ width: '16px', height: '16px', ml: 0.2, pt: 0.2 }} />
                                                    </Tooltip>
                                                </Typography>
                                            }
                                        />
                                    </div>
                                </Box>
                            </Collapse>
                        </CardContent>
                    </>}
            </div>

            <CardActions sx={{ justifyContent: 'space-between' }}>
                <span>{selected && Boolean(file) && !reportData &&
                    <div>
                        <Button disabled={submitted} onClick={handleUploadClick} type="submit" startIcon={submitted ? <CircularProgress size="1rem" /> : null} >
                                <FormattedMessage id={"SUBMIT"} />
                        </Button>
                        <Button color="warning" onClick={() => setOpenConfirmDialog(true)}><FormattedMessage id="CANCEL" /></Button>
                    </div>}</span>
                {!createMeasurementPermission && createStagingMeasurementPermission && <Chip color="warning" size="small" label={<FormattedMessage id={"MEASUREMENTS.STAGING_REVIEW" + (reportData ? ".SENT" : "")} />} />}
            </CardActions>
        </Card>
    </>
}