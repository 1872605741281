import { palette } from './chartsColorPalette';

const defaultOptions = {
    colors: palette,
    pointRadius: 0,
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
        intersect: false,
        mode: 'point',
        axis: 'y'
    },
    plugins: {
        legend: {
            display: true,
            labels: {
                usePointStyle: true,
                font: {
                    family: 'Roboto Condensed'
                }
            }
        },
        tooltip: {
            usePointStyle: true,
            position: 'average',
            callbacks: {
                labelPointStyle: (context) => { return { pointStyle: 'circle' } }
            }
        },
        zoom: {
            pan: {
                enabled: true,
                modifierKey: 'shift',
            },
            zoom: {
                drag: {
                    enabled: true,
                    backgroundColor: 'rgb(0,229,255, 0.2)'
                },
                mode: 'xy',
            },
        }
    },
    scales: {
        y: {
            grid: {
                display: false,
            },
            ticks: {
                font: {
                    family: 'Roboto Condensed'
                }
            }
        },
        x: {
            grid: {
                display: false,
            },
            ticks: {
                font: {
                    family: 'Roboto Condensed'
                }
            }
        }
    }
}

export default defaultOptions;