import instance, { setAuthHeader } from "./axiosEnv"

const getAllReports = (token, entityId) => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();

    if (entityId) queryParams.append('entityId', entityId);

    return instance.get('/ESG/reports?' + queryParams.toString(), config);
}

const generateMetricReport = (token, entityId, year, lang, type) => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();

    queryParams.append('year', year);
    queryParams.append('reportLanguage', lang);
    if (type) queryParams.append('type', type);

    return instance({ url: '/ESG/reports/' + entityId + '?' + queryParams.toString(), method: 'GET', responseType: 'blob', ...config });
}

const archiveSingleReport = (token, reportId, year) => {
    const config = setAuthHeader(token);
    return instance.put(`/ESG/reports/${reportId}/archive/${year}`, null, config);
}

const archiveReports = (token, year) => {
    const config = setAuthHeader(token);
    return instance.put('/ESG/reports/archive/' + year, null, config);
}

const reportsAPI = {
    getAllReports,
    generateMetricReport,
    archiveSingleReport,
    archiveReports,
}

export default reportsAPI;