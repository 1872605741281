import { DefaultLayout } from 'layouts';
import { useSelector } from 'react-redux';
import { userSelector, permissionSelector } from 'redux/userSlice';
import { SplashPage } from "pages";
import { EmptyState } from 'layouts';
import { useParams } from 'react-router';
import { ActivitiesView } from 'components';
import { selectEntities } from 'redux/entitySlice';
import { FormattedMessage } from 'react-intl';


const EntityActivitiesPage = ({ entityId, assigned }) => {
    const entities = useSelector(selectEntities);

    if (entityId && !entities[entityId]) return <EmptyState message={<FormattedMessage id="SPLASH.UNAVAILABLE" />} />;
    else return <EmptyState component={<ActivitiesView entityId={entityId} assigned={assigned} />} />;
}

const EntityActivitiesPageComponent = ({ assigned }) => {
    const entityId = useParams().entityId;
    const { isSuccess: loggedIn } = useSelector(userSelector);
    const viewActivityPermission = useSelector((state) => permissionSelector(state, 'view-activity'));

    if (loggedIn && viewActivityPermission) return <DefaultLayout page={<EntityActivitiesPage entityId={entityId} assigned={assigned} />} />;
    else return <SplashPage />;
};

export default EntityActivitiesPageComponent;