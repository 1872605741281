import instance, { setAuthHeader, setContentTypeHeader } from "./axiosEnv";
import { localISOString } from "utils";

const getMeasurementHistory = (token, page, pageSize, entityId, measurementTypes, collectorTypes, dateFrom, dateTo) => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();
    if (pageSize) queryParams.append('size', pageSize);
    if (page) queryParams.append('pageNumber', page);
    if (dateFrom) queryParams.append('dateFrom', localISOString(dateFrom));
    if (dateTo) queryParams.append('dateTo', localISOString(dateTo));
    if (entityId) {
        for (let el in entityId) {
            queryParams.append('entityId', entityId[el]);
        }
    }
    if (measurementTypes) {
        for (let el in measurementTypes) {
            queryParams.append('measurementTypeKey', measurementTypes[el]);
        }
    }
    if (collectorTypes) {
        for (let el in collectorTypes) {
            queryParams.append('collectorType', collectorTypes[el]);
        }
    }
    return instance.get('/measurements?' + queryParams.toString(), config);
}

const getMeasurementValuesHistory = (token, dateFrom, dateTo, entityId, measurementTypes, collectorTypes) => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();
    queryParams.append('dateFrom', localISOString(dateFrom));
    queryParams.append('dateTo', localISOString(dateTo));
    if (entityId) {
        for (let el in entityId) {
            queryParams.append('entityId', entityId[el]);
        }
    }
    if (measurementTypes) {
        for (let el in measurementTypes) {
            queryParams.append('measurementTypeKey', measurementTypes[el]);
        }
    }
    if (collectorTypes) {
        for (let el in collectorTypes) {
            queryParams.append('collectorType', collectorTypes[el]);
        }
    }
    return instance.get('/measurements/valuesHistory?' + queryParams.toString(), config);
}

const getDistributionTypes = (token, dateFrom, dateTo, entityId) => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();
    queryParams.append('dateFrom', localISOString(dateFrom));
    queryParams.append('dateTo', localISOString(dateTo));
    for (let el in entityId) {
        queryParams.append('entityId', entityId[el]);
    }
    return instance.get('/measurements/typeDistribution?' + queryParams.toString(), config);
}

const getSingleMeasurement = (token, measurementId) => {
    const config = setAuthHeader(token);
    return instance.get('/measurements/' + measurementId, config);
}

const storeManual = (token, body) => {
    const config = setAuthHeader(token);
    const contentType = setContentTypeHeader('JSON');

    const headers = { headers: { ...config.headers, ...contentType?.headers } }

    return instance.post('/measurements/storeManual', JSON.stringify(body), headers);
}

const storeFromFile = (token, username, entityId, file, skipFlag) => {
    const config = setAuthHeader(token);

    const queryParams = new URLSearchParams();
    queryParams.append('skipFlag', skipFlag);

    const formData = new FormData();
    formData.append('collectorName', username);
    formData.append('entityId', entityId);
    formData.append('file', file, file.name);

    return instance.postForm('/measurements/history/store?' + queryParams.toString(), formData, config);
}

const updateMeasurement = (measurementId, newMeasurement, token) => {
    const config = setAuthHeader(token);
    return instance.put('/measurements/' + measurementId, newMeasurement, config);
}

const deleteMeasurement = (token, measurementId) => {
    const config = setAuthHeader(token);
    return instance.delete('/measurements/' + measurementId, config);
}

const measurementsAPI = {
    getMeasurementHistory,
    getMeasurementValuesHistory,
    getDistributionTypes,
    getSingleMeasurement,
    storeManual,
    storeFromFile,
    updateMeasurement,
    deleteMeasurement,
}

export default measurementsAPI;