import instance, { setAuthHeader } from "./axiosEnv";
import { localISOString } from "utils";

const getHistory = (token, dateFrom, dateTo, date, metricId) => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();
    queryParams.append('dateFrom', localISOString(dateFrom));
    queryParams.append('dateTo', localISOString(dateTo));
    if (date) queryParams.append('date', localISOString(date));
    if (metricId) {
        for (let el in metricId) {
            queryParams.append('metricId', metricId[el]);
        }
    }
    
    return instance.get('/ESG/staging?' + queryParams.toString(), config);
}

const getSingleMetric = (token, metricId) => {
    const config = setAuthHeader(token);
    return instance.get('/ESG/staging/' + metricId, config);
}

const approveMetric = (token, metricId) => {
    const config = setAuthHeader(token);
    return instance.put('/ESG/staging/' + metricId + '/approve', null, config);
}

const updateMetric = (metricId, newMetric, token) => {
    const config = setAuthHeader(token);
    return instance.put('/ESG/staging/' + metricId, newMetric, config);
}

const deleteMetric = (token, metricId) => {
    const config = setAuthHeader(token);
    return instance.delete('/ESG/staging/' + metricId, config);
}

const metricsAPI = {
    getHistory,
    getSingleMetric,
    approveMetric,
    updateMetric,
    deleteMetric,
}

export default metricsAPI;