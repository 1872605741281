import { localISOString } from "utils";
import instance, { setAuthHeader } from "./axiosEnv";

const getAllEntityMetrics = (entityId, token) => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();

    if (entityId) queryParams.append('entityId', entityId);
    return instance.get('/ESG/metric?' + queryParams.toString(), config);
}

const getMetric = (metricId, token) => {
    const config = setAuthHeader(token);
    return instance.get('/ESG/metric/' + metricId, config);
}

const deleteMetric = (token, metricId) => {
    const config = setAuthHeader(token);
    return instance.delete('/ESG/metric/' + metricId, config);
}

const getAllMetrics = (token, categoryType, metricTypes, entities, measurementKeys, dateFrom, dateTo,) => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();

    if (categoryType) queryParams.append("metricCategoryType", categoryType);
    if (metricTypes) {
        for (let type of metricTypes) {
            queryParams.append("type", type);
        }
    }
    if (entities) {
        for (let entity of entities) {
            queryParams.append("entityId", entity);
        }
    }
    if (measurementKeys) {
        for (let key of measurementKeys) {
            queryParams.append("measurementTypeKey", key);
        }
    }
    if (dateFrom) queryParams.append("dateFrom", localISOString(dateFrom));
    if (dateTo) queryParams.append("dateTo", localISOString(dateTo));

    return instance.get('/ESG/metric?' + queryParams.toString(), config);
}

const getAllComplexMetrics = (token) => {
    const config = setAuthHeader(token);
    return instance.get('/ESG/metric/complex/', config);
}

const getComplexMetric = (metricId, token) => {
    const config = setAuthHeader(token);
    return instance.get('/ESG/metric/complex/' + metricId, config);
}

const postMetric = (token, recalculateFlag, metricObject, direction) => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();

    if (recalculateFlag) {
        queryParams.append("recalculateFlag", recalculateFlag);
    }
    if (direction) {
        queryParams.append("direction", direction);
    }
    return instance.post('/ESG/metric?' + queryParams.toString(), metricObject, config);
}
const putMetric = (token, metricId, metricObject) => {
    const config = setAuthHeader(token);
    return instance.put('/ESG/metric/' + metricId, metricObject, config);
}

const postComplexMetric = (token, metricObject) => {
    const config = setAuthHeader(token);
    return instance.post('/ESG/metric/complex/', metricObject, config);
}

const putComplexMetric = (token, metricId, metricObject) => {
    const config = setAuthHeader(token);
    return instance.put('/ESG/metric/complex/' + metricId, metricObject, config);
}

const deleteComplexMetric = (token, metricId) => {
    const config = setAuthHeader(token);
    return instance.delete('/ESG/metric/complex/' + metricId, config);
}

const getMetricAndComplexMetric = (entityId, token) => {
    const config = setAuthHeader(token);

    const queryParams = new URLSearchParams();
    if (entityId) queryParams.append('entityId', entityId);

    return instance.get('/ESG/metric/metricAndComplex?' + queryParams.toString(), config);
}

const metricsAPI = {
    getAllEntityMetrics,
    getMetric,
    deleteMetric,
    getAllMetrics,
    getAllComplexMetrics,
    getComplexMetric,
    postMetric,
    putMetric,
    postComplexMetric,
    putComplexMetric,
    deleteComplexMetric,
    getMetricAndComplexMetric,
}

export default metricsAPI;