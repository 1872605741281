import { TextField, InputAdornment, IconButton, Stack, Box, Tooltip, useMediaQuery } from "@mui/material";
import { AddOutlined, CloseOutlined, SearchOutlined, TableChartOutlined, ViewAgendaOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { permissionSelector } from "redux/userSlice";
import { CustomChip } from "components/topicsView/TopicFilters";

export default function SearchBar(props) {
    const { search, setSearch, noSubmit, entityId, addEntity, helperText, tooltip, sx, variant, parentExists, disabled, tableView, setTableView } = props;
    const [localValue, setLocalValue] = useState(search);
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const intl = useIntl();
    const createEntityPermission = useSelector((state) => permissionSelector(state, 'create-entity'));
    const createEntityChildrenPermission = useSelector((state) => permissionSelector(state, 'create-entity-child'));

    const handleSearch = (value) => {
        if (typeof setSearch === 'function') setSearch(value || localValue);
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') handleSearch();
        if (e.key === 'Escape') {
            setLocalValue("");
            handleSearch("");
        }
    }

    const handleChange = (e) => {
        setLocalValue(e.target.value);
        if (noSubmit) handleSearch(e.target.value)
    }

    useEffect(() => {
        if (!localValue?.length && typeof setSearch === 'function') setSearch("");
    }, [localValue, setSearch])

    useEffect(() => {
        setLocalValue("");
    }, [entityId])

    return (
        <Stack direction="row" spacing={1} alignItems={smallScreen ? "flex-start" : "center"} sx={{ width: '100%' }}>
            <TextField
                sx={{
                    ...sx,
                    '.MuiInputBase-root': {
                        paddingLeft: noSubmit ? 1 : 0,
                    },
                    '.MuiOutlinedInput-input': {
                        paddingLeft: 0,
                    }
                }}
                disabled={disabled}
                value={localValue}
                onKeyDown={handleKeyPress}
                onChange={handleChange}
                placeholder={intl.formatMessage({ id: "SEARCH_PLACEHOLDER." + ((smallScreen || noSubmit || tooltip) ? "NO_SUBMIT" : "SUBMIT") })}
                InputProps={{
                    startAdornment: !noSubmit && (
                        <InputAdornment position="start">
                            <IconButton onClick={() => handleSearch()} aria-label="search">
                                <SearchOutlined color={disabled ? "disabled" : "primary"} />
                            </IconButton>
                        </InputAdornment>
                    ),
                    endAdornment: localValue.length > 0 && (
                        <InputAdornment position="end">
                            <Tooltip title={<FormattedMessage id="CLEAR" />}>
                                <IconButton component="span" onClick={() => handleChange({ target: { value: "" } })} size="small" aria-label="search">
                                    <CloseOutlined fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </InputAdornment>
                    )
                }}
                helperText={(helperText || (smallScreen && !noSubmit) || (!noSubmit && tooltip && !helperText)) && <Stack component="span">
                    {((smallScreen && !noSubmit) || (!noSubmit && tooltip && !helperText)) && <FormattedMessage id="SEARCH_PLACEHOLDER.SUFFIX" />}
                    {helperText}
                </Stack>}
                size="small"
                variant={variant || "outlined"}
                fullWidth
            />
            <Stack direction="row" gap={1} sx={{ pr: 1 }}>
                {Boolean(setTableView) && <Tooltip title={<FormattedMessage id={(tableView ? "TABLE" : "CARDS") + ".SHOW"} />} placement="bottom" arrow>
                    <span>
                        <IconButton disabled={disabled} size="small" onClick={() => setTableView(!tableView)}>
                            {tableView ? <TableChartOutlined color="primary" /> : <ViewAgendaOutlined />}
                        </IconButton>
                    </span>
                </Tooltip>}
                {((createEntityPermission && !parentExists) || ((createEntityPermission || createEntityChildrenPermission) && entityId !== null)) && addEntity && <Box>
                    <CustomChip smallIcons={smallScreen} variant="outlined" sx={{ px: 1 }} icon={<AddOutlined fontSize="small" color="primary" />} onClick={() => addEntity.setOpenForm(true)} label={<FormattedMessage id={"ADD.ENTITY"} />} />
                </Box>}
            </Stack>
        </Stack >
    );
}
