import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectMeasurementsTypesConf } from 'redux/configurationSlice';
import { selectDateRange } from 'redux/dateRangeSlice';
import { userSelector } from 'redux/userSlice';
import { useIntl, FormattedMessage } from 'react-intl';
import { CustomDataGrid, LoadingData, SideDrawer, MeasurementDetails, AlertSnackbar } from "components";
import { renderDateCell } from 'components/dashboardView/TableHeaders';
import { Alert, useMediaQuery } from '@mui/material';
import API from 'api';
import { selectEntities } from 'redux/entitySlice';



const MeasurementsTableContainer = ({ entityId, measureTypes, collectorTypes }) => {
    const { token } = useSelector(userSelector);
    const selectedDateRange = useSelector(selectDateRange);
    const measurementTypes = useSelector(selectMeasurementsTypesConf);
    const allEntities = useSelector(selectEntities);
    const intl = useIntl();
    const [tableData, setTableData] = useState({ loading: true });
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [openDrawer, setOpenDrawer] = useState(false);
    const [measurementDetails, setMeasurementDetails] = useState({});
    const [reset, setReset] = useState(0);
    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [totalSize, setTotalSize] = useState(-1);
    const [alert, setAlert] = useState({ open: false });


    useEffect(() => {
        if (reset === 0) setTableData({ loading: true });
        API.measurements.getMeasurementHistory(token, pageNumber + 1, pageSize, entityId ? [entityId] : undefined, measureTypes, collectorTypes, selectedDateRange.dateFrom, selectedDateRange.dateTo).then(({ data }) => {
            if (data.data?.length) {
                const convertedData = [];
                data.data.forEach(measurement => {
                    const measurementType = measurementTypes.find(mt => mt.key === measurement.measurementTypeKey);
                    convertedData.push({
                        id: measurement._id,
                        date: new Date(measurement.timestamp),
                        value: measurement.value,
                        unit: measurement.unit,
                        entity: allEntities[measurement.entityId]?.name,
                        measurementType: measurementType ? (measurementType.name || intl.formatMessage({ id: measurementType.key })) : (measurement.measurementTypeKey + `[${intl.formatMessage({ id: 'ERROR.MISSING_FIELD.MEASUREMENTTYPEKEY' })}]`),
                    })
                })
                setTableData(convertedData);
            }
            else setTableData([]);

            if (data.metadata?.length) {
                setTotalSize(data.metadata[0]?.total || 0);
            }
            else {
                setTotalSize(0);
            }
        }).catch(error => {
            console.error(error);
            setTableData({ error })
        })
    }, [entityId, intl, measurementTypes, selectedDateRange.dateFrom, selectedDateRange.dateTo, pageNumber, pageSize, allEntities, measureTypes, collectorTypes, reset, token])

    const renderTable = useCallback(() => {
        const handleRowClick = (row) => {
            setOpenDrawer(true);
            setMeasurementDetails({
                title: <FormattedMessage id='LOADING_DATA' />,
                subtitle: <FormattedMessage id='SPLASH.WAIT' />,
                display: <LoadingData noText />
            });
            API.measurements.getSingleMeasurement(token, row.row.id).then(item => {
                if (item.data) {
                    setMeasurementDetails({
                        title: <FormattedMessage id="MEASUREMENT_DETAILS" />,
                        subtitle: <></>,
                        display: <MeasurementDetails measurement={item.data} sideDrawer onClose={() => setOpenDrawer(false)} onReset={() => setReset(reset => reset + 1)} setAlert={setAlert} />
                    });
                }
            })
                .catch(error => {
                    setMeasurementDetails({
                        title: <FormattedMessage id='ERROR' />,
                        subtitle: <></>,
                        display: <Alert severity="error"><FormattedMessage id='NO_DATA' /></Alert>
                    });
                    console.error(error);
                })
        }

        const tableHeaders = [
            !entityId && {
                field: 'entity',
                headerName: intl.formatMessage({ id: "ENTITY" }),
                ...(!smallScreen && { flex: 1 }),
                ...(smallScreen && { width: 200 }),
            },
            {
                field: 'date',
                type: 'dateTime',
                headerName: intl.formatMessage({ id: "DATE" }),
                ...renderDateCell(intl),
                ...(!smallScreen && { flex: 1 }),
                ...(smallScreen && { width: 200 }),
            },
            {
                field: 'measurementType',
                headerName: intl.formatMessage({ id: "MEASUREMENT_TYPE" }),
                ...(!smallScreen && { flex: 1 }),
                ...(smallScreen && { width: 200 }),
            },
            {
                field: 'value',
                type: 'number',
                headerName: intl.formatMessage({ id: "VALUE" }),
                ...(!smallScreen && { flex: 1 }),
                ...(smallScreen && { width: 150 }),
            },
            {
                field: 'unit',
                headerName: intl.formatMessage({ id: "UNIT" }),
                ...(!smallScreen && { flex: 1 }),
                ...(smallScreen && { width: 150 }),
            },
        ].filter(Boolean);

        if (tableData.error) return <Alert severity="error"><FormattedMessage id='NO_DATA' /></Alert>;
        else return <CustomDataGrid isLoading={tableData.loading} paginationMode="server" pageSize={pageSize} pageNumber={pageNumber} setPageSize={setPageSize} setPageNumber={setPageNumber} rowCount={totalSize} rows={!tableData.loading ? tableData : []} columns={tableHeaders} handleData={handleRowClick} enableExport />;

    }, [intl, smallScreen, tableData, pageSize, pageNumber, totalSize, token, entityId]);

    return <>
        <AlertSnackbar open={alert.open} onClose={() => setAlert({ ...alert, open: false })} severity={alert.severity} messageId={alert.messageId} />
        {renderTable()}
        <SideDrawer open={openDrawer} toggleDrawer={() => setOpenDrawer(false)} state={measurementDetails} />
    </>
}

export default MeasurementsTableContainer;