import { useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { selectMeasurementsTypesConf, selectCollectorTypesConf } from 'redux/configurationSlice';
import { selectMeasureEntities } from 'redux/entitySlice';
import { UnitSelectorField } from "components"
import { Autocomplete, FormLabel, TextField, MenuItem, Stack, FormControl, Typography, Collapse, Button } from '@mui/material';
import { ExpandMoreOutlined, ExpandLessOutlined } from '@mui/icons-material';
import { GroupHeader, GroupItems } from 'components/unitSelectorField/UnitSelectorField';


const MeasurementsSection = ({ setEditMode, allEntitiesFlag, existingMetric, measurementType, setMeasurementType, categoryType, setCategoryType, entityId, selectedEntity, sourceType, setSourceType, collectorName, setCollectorName, unit, setUnit, calculationMethod, setCalculationMethod, reportUnit, setReportUnit }) => {
    const measureEntities = useSelector(selectMeasureEntities);
    const measurementTypes = useSelector(selectMeasurementsTypesConf);
    const sourceTypes = useSelector(selectCollectorTypesConf);
    const selectedEntityMeasurementTypes = measureEntities[entityId || selectedEntity[0]?._id]?.measurementTypes
    const intl = useIntl();
    const calculationMethods = ['sum', 'avg', 'min', 'max', 'last', 'count'];
    const [openAdditionalData, setOpenAdditionalData] = useState(false);
    const checkIncludes = (measurementType, measureEntityMeasurementTypes) => {
        if (allEntitiesFlag) return true;
        if (!measureEntityMeasurementTypes) return false;
        const measureEntityCondition = measureEntityMeasurementTypes.service.OWM.includes(measurementType.key) || measureEntityMeasurementTypes.manual.types.includes(measurementType.key)
            || (measureEntityMeasurementTypes?.sensor && Object.keys(measureEntityMeasurementTypes.sensor).includes(measurementType.key));

        return measureEntityCondition;
    }

    const notConfiguredType = selectedEntity.length === 1 && !allEntitiesFlag && measurementType && !checkIncludes(measurementType, selectedEntityMeasurementTypes);

    const quantitativeMetric = categoryType === process.env.REACT_APP_ESG_METRIC_DEFAULT_TYPE;

    return <Stack direction="column" spacing={2} sx={{ mb: 2 }}>
        <FormControl>
            <FormLabel ><FormattedMessage id="ESG.METRIC.TYPE" /></FormLabel>
            <TextField
                disabled={Boolean(existingMetric)}
                select
                size="small"
                value={categoryType}
                onChange={(e) => { setCategoryType(e.target.value); setEditMode(true) }}
            >
                {[process.env.REACT_APP_ESG_METRIC_DEFAULT_TYPE, "Qualitative"].map(type => <MenuItem key={type} value={type}><FormattedMessage id={"ESG.METRIC.TYPE." + type.toUpperCase()} /></MenuItem>)}
            </TextField>
        </FormControl>
        <Collapse in={quantitativeMetric}>
            <Stack direction="column" spacing={2}>
                <FormControl >
                    <FormLabel required><FormattedMessage id="CALCULATION_METHOD" /></FormLabel>
                    <TextField
                        select
                        disabled={Boolean(existingMetric)}
                        size="small"
                        value={calculationMethod}
                        onChange={(e) => { setCalculationMethod(e.target.value); setEditMode(true) }}
                    >
                        {calculationMethods.map((method, index) => <MenuItem key={index} value={method}><FormattedMessage id={"CALCULATION_METHOD." + method} /></MenuItem>)}
                    </TextField>
                </FormControl>

                <FormControl color={notConfiguredType ? 'warning' : 'primary'}>
                    <FormLabel required ><FormattedMessage id="MEASUREMENT_TYPE" /> </FormLabel>
                    <Autocomplete
                        id="select-measurement-type"
                        options={measurementTypes.map(type => ({ ...type, isAvailable: checkIncludes(type, selectedEntityMeasurementTypes) })).sort((a, b) => b.isAvailable - a.isAvailable)}
                        groupBy={(allEntitiesFlag || selectedEntity.length > 1) ? undefined : (option) => option.isAvailable}
                        renderGroup={(allEntitiesFlag || selectedEntity.length > 1) ? undefined : (params) => (<li key={params.key}>
                            <GroupHeader><FormattedMessage id={"MEASUREMENT." + (params.group ? "CONFIGURED" : "NOT_CONFIGURED")} /></GroupHeader>
                            <GroupItems>{params.children}</GroupItems>
                        </li>)}
                        getOptionLabel={(measurementType) => measurementType ? (measurementType.name || intl.formatMessage({ id: measurementType.key })) : ""}
                        value={measurementType}
                        onChange={(e, measurementType) => {
                            setReportUnit({
                                prefix: "",
                                unit: "",
                                suffix: ""
                            });
                            setUnit(measurementType ? (measurementType.dataType.measure ? "" : null) : "");
                            setMeasurementType(measurementType);
                            setEditMode(true)
                        }}
                        renderInput={(params) => (
                            <TextField {...params} {...(notConfiguredType && { color: 'warning' })} />
                        )}
                        isOptionEqualToValue={(option, value) => value === "" || option.key === value.key}
                        size="small"
                        fullWidth
                    />
                    {!(allEntitiesFlag || selectedEntity.length > 1) && <Stack direction="row" spacing={0.5} sx={{ pt: 0.5 }} alignItems='center'>
                        {notConfiguredType && <Typography color="warning.main" variant="caption">
                            <FormattedMessage id="MEASUREMENTS.TYPE_ENTITY_WARNING" />. <FormattedMessage id="MEASUREMENTS.TYPE_ENTITY_WARNING_ADDITIONAL.METRIC" />
                        </Typography>}
                    </Stack>}
                </FormControl>
            </Stack>
            <Button size='small' sx={{ float: 'left', mt: 2 }} onClick={() => setOpenAdditionalData(!openAdditionalData)} startIcon={openAdditionalData ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}>
                <FormattedMessage id="MEASUREMENT_TYPE.FILTER" />
            </Button>
            <Collapse in={openAdditionalData} sx={{ width: '100%', display: 'block' }}>
                <Stack direction="column" spacing={2} sx={{ border: '1px solid lightgray', p: 2 }}>
                    <Typography variant='caption' sx={{ display: "block", color: "info.main" }}><FormattedMessage id={`ESG.METRIC.UNIT.FILTER_DESCRIPTION`} /></Typography>
                    <FormControl fullWidth>
                        <FormLabel ><FormattedMessage id="SOURCE_TYPE" /></FormLabel>
                        <TextField
                            select
                            size="small"
                            value={sourceType >= 0 ? sourceType : ''}
                            onChange={(e) => { setSourceType(e.target.value); setEditMode(true) }}
                        >
                            {[{ key: '', value: '' }, ...sourceTypes].map(type => <MenuItem sx={type.key === '' && sourceType === '' ? { display: 'none' } : undefined} key={type.key} value={type.key}>{type?.value.length ? <FormattedMessage id={type.value + '_TYPE'} /> : <Typography variant="inherit" color="text.disabled"><FormattedMessage id="DATA_TYPE.NONE" /></Typography>}</MenuItem>)}
                        </TextField>
                    </FormControl>
                    <FormControl fullWidth>
                        <FormLabel ><FormattedMessage id="COLLECTOR" /></FormLabel>
                        <TextField
                            variant="outlined"
                            placeholder={intl.formatMessage({ id: "COLLECTOR_NAME" })}
                            type="text"
                            size="small"
                            value={collectorName}
                            onChange={(e) => { setCollectorName(e.target.value); setEditMode(true) }}
                        />
                    </FormControl>
                    <UnitSelectorField hideEmptyInput unit={unit} setUnit={setUnit} measurementType={measurementType} setEditMode={setEditMode} />
                </Stack>
            </Collapse>
        </Collapse>
    </Stack>
}

export default MeasurementsSection;