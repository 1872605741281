import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { userSelector, permissionSelector } from "redux/userSlice";
import { selectUsersAndGroups, selectUsersAndGroupsStatus, deleteGroup, fetchUsersAndGroups } from "redux/usersAndGroupsSlice";
import { AlertSnackbar, ConfirmationDialog, CustomDataGrid, UserGroupDetailsView, UserGroupForm, UserGroupFetchAlert, ExpandedGridCell, RolesUsersLinks } from "components";
import { CardActions, Alert, Button, Card, CardContent, CardHeader, useMediaQuery, Chip, Typography, IconButton } from "@mui/material";
import { DeleteOutline, EditOutlined, AddOutlined, CancelOutlined } from "@mui/icons-material";
import { store } from 'redux/store';
import API from "api";

const UserGroupsView = (props) => {

    const { token } = useSelector(userSelector);
    const usersAndGroups = useSelector(selectUsersAndGroups);
    const status = useSelector(selectUsersAndGroupsStatus);
    const [userGroups, setUserGroups] = useState([]);
    const [userGroup, setUserGroup] = useState(null);
    const [userGroupId, setUserGroupId] = useState('');
    const [loading, setLoading] = useState(false);
    const [reloadData, setReloadData] = useState(false);
    const [alert, setAlert] = useState({ open: false });

    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
    const [openAddEditForm, setOpenAddEditForm] = useState(false);
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const intl = useIntl();
    const dispatch = useDispatch();
    const viewUsersPermission = useSelector((state) => permissionSelector(state, 'view-users'));
    const manageUsersPermission = useSelector((state) => permissionSelector(state, 'manage-users'));

    const onAlertClose = () => setAlert({ ...alert, open: false });

    useEffect(() => {
        if (!viewUsersPermission) {
            setUserGroups([]);
            return () => {
                setReloadData(false);
            }
        }
        else if (status === 'failed') {
            setLoading(true);
            store.dispatch(fetchUsersAndGroups(token)).then(() => setLoading(false)).catch(error => {
                setAlert({ open: true, messageId: error?.data?.id || "ERROR.FETCHING_USERS_DATA", severity: "error" });
                setUserGroups({ error });
                setLoading(false);
            });
        } else if (status === 'complete') {
            setUserGroups(usersAndGroups.groups);
        } else setUserGroups([]);

        return () => {
            setReloadData(false);
        }
    }, [token, reloadData, status, usersAndGroups, viewUsersPermission]);

    const handleDeleteUserGroup = () => {
        API.userGroup.deleteUserGroup(userGroupId, token).then(response => {
            if (response?.data) {
                setAlert({ open: true, messageId: "USER_GROUP.DELETE_SUCCESS", severity: "success" });
                setReloadData(true);
                setOpenConfirmDialog(false);
                dispatch(deleteGroup(response.data));
            }
        }).catch(error => {
            setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_DELETED", severity: "error" });
            setOpenConfirmDialog(false);
            console.error(error);
        });
        setUserGroupId('');
    }

    const headers = [
        {
            field: 'name',
            headerName: intl.formatMessage({ id: 'NAME' }),
            ...(!smallScreen && { flex: 1 }),
            renderCell: (row) => <ExpandedGridCell value={row.value} width={row.colDef.computedWidth} />,
            minWidth: 200
        },
        {
            field: 'users',
            headerName: intl.formatMessage({ id: 'USERS' }),
            ...(!smallScreen && { flex: 1 }),
            renderCell: (row) => <>
                {row.row.users.length > 1 ?
                    <>{row.row.users[0]}
                        <span><Chip sx={{ ml: 0.5 }} size="small" variant="outlined" label={<span style={{ textTransform: 'lowercase' }}>{row.row.users.length - 1} <FormattedMessage id="MORE" /></span>} /></span>
                    </>
                    : row.row.users
                }
            </>,
            minWidth: 200
        },
        {
            field: 'actions',
            headerName: intl.formatMessage({ id: 'ACTIONS' }),
            type: 'actions',
            getActions: (el) => manageUsersPermission
                ? [
                    <IconButton color="info" key="edit" onClick={() => { setOpenAddEditForm(true); setUserGroup(el.row) }}><EditOutlined fontSize="small" /></IconButton>,
                    <IconButton color="error" key="delete" onClick={() => { setOpenConfirmDialog(true); setUserGroupId(el.id) }}><DeleteOutline fontSize="small" /></IconButton>
                ]
                : [smallScreen ? <IconButton key="disabled-actions" disabled><CancelOutlined fontSize="small" /></IconButton> : <Button key="disabled-actions" disabled><FormattedMessage id="DISABLED" /></Button>],
            minWidth: 150
        }
    ]

    return (<>
        <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
        <UserGroupDetailsView open={openDetailsDialog} onClose={() => setOpenDetailsDialog(false)} userGroup={userGroups?.find(userGroup => userGroup.id === userGroupId)} />
        <ConfirmationDialog
            open={openConfirmDialog}
            content={userGroupId && <Typography variant="h6" color="error">{userGroups.find(el => el.id === userGroupId).name}</Typography>}
            title={<FormattedMessage id="USER_GROUP.CONFIRM_DELETE" />}
            customButtonTitle={<FormattedMessage id="DELETE" />}
            customButtonColor="error"
            handleCancel={() => setOpenConfirmDialog(false)}
            handleCustomButton={handleDeleteUserGroup}
        />

        <UserGroupForm open={openAddEditForm} onClose={() => setOpenAddEditForm(false)} userGroup={userGroup} reloadData={() => setReloadData(true)} />

        <Card sx={{ width: '100%' }}>
            <CardHeader title={<FormattedMessage id="USER_GROUPS.TABLE" />}
                action={manageUsersPermission && <Chip variant="outlined" onClick={() => { setOpenAddEditForm(true); setUserGroup(null) }} icon={<AddOutlined fontSize="small" color="primary" />} label={<FormattedMessage id="USER_GROUP.ADD" />} />}
            />
            <CardActions sx={{ px: 2 }}>
                <RolesUsersLinks userGroups />
            </CardActions>

            {status === 'disabled'
                ? <CardContent><UserGroupFetchAlert /></CardContent>
                : <CardContent>
                    {userGroups?.error
                        ? <Alert severity='error'><FormattedMessage id="ERROR.NO_DATA" /></Alert>
                        : <CustomDataGrid isLoading={loading} rows={userGroups} columns={headers} handleData={(el) => { setOpenDetailsDialog(true); setUserGroupId(el.row.id) }} />
                    }
                </CardContent>
            }
        </Card>
    </>
    )
}

export default UserGroupsView;