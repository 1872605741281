import { DefaultLayout, EmptyState } from 'layouts';
import { SplashPage } from 'pages';
import { ReportsView } from 'components';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/userSlice';



const ReportsPage = () => {
    return <EmptyState component={<ReportsView />} />;
}

const ReportsPageComponent = () => {
    const { isSuccess: loggedIn } = useSelector(userSelector);

    if (loggedIn) return <DefaultLayout page={<ReportsPage />} />;
    else return <SplashPage />;
};

export default ReportsPageComponent;