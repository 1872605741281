import { PreviewOutlined } from "@mui/icons-material";
import { IconButton, List, ListItem, ListItemText, Tooltip, Typography } from "@mui/material";
import { DisplayBox, UserGroupDetailsView, UserGroupFetchAlert, LoadingData } from "components";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { permissionSelector } from "redux/userSlice";
import { selectUsersAndGroupsStatus } from "redux/usersAndGroupsSlice";


export default function UserList({ label, users, groups, simple }) {
    const [group, setGroup] = useState(null);
    const status = useSelector(selectUsersAndGroupsStatus);
    const viewUsersPermission = useSelector((state) => permissionSelector(state, 'view-users'));

    if (!viewUsersPermission) return <DisplayBox label={label} value={<Typography color="text.disabled"><FormattedMessage id="UNAUTHORIZED.NO_PERMISSION" /></Typography>} />;
    if (status === 'disabled') return <DisplayBox label={label} value={<UserGroupFetchAlert variant="outlined" />} />
    else if (status === 'failed' || status === 'loading') return <LoadingData />
    else return <>
        <UserGroupDetailsView userGroup={group} open={Boolean(group)} onClose={() => setGroup(null)} />
        <DisplayBox label={label} value={
            (users?.length || groups?.length)
                ? <List dense>
                    {users?.map((user, index) => (
                        <ListItem key={"user-" + index}><Typography variant="caption" color="text.secondary" sx={{ pr: 1 }}>{index + 1}.</Typography>{user?.username ? user.username : user}</ListItem>
                    ))}
                    {groups?.map((group, index) => (
                        <ListItem key={"group-" + index}
                            secondaryAction={<Tooltip title={<FormattedMessage id="USER_GROUPS.VIEW" />} placement="left">
                                <span>
                                    <IconButton size="small" color="primary" edge="end" aria-label="view" onClick={() => group?.id ? setGroup(group) : null}>
                                        <PreviewOutlined fontSize="small" />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            }>
                            <ListItemText
                                primary={<span>
                                    <Typography component="span" variant="caption" color="text.secondary" sx={{ pr: 1 }}>{index + (users ? users.length : 0) + 1}.</Typography>
                                    {group?.name ? group.name : group}</span>
                                }
                                secondary={!simple && <FormattedMessage id="USER_GROUP" />}
                            />
                        </ListItem>
                    ))}
                </List>
                : null
        } />
    </>
}