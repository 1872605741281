import configuration from "./configurationAPI";
import entities from "./entitiesAPI";
import authKeycloak, { keycloak } from "./authKeycloak";
import user from "./userAPI";
import measurements from "./measurementsAPI";
import metricReports from "./metricReportAPI";
import metrics from "./metricsAPI";
import notes from "./noteAPI";
import role from "./roleAPI";
import measureEntity from "./measureEntityAPI";
import topic from "./topicApi";
import goal from "./goalAPI";
import logs from "./logsAPI";
import target from "./targetAPI";
import measurementTypes from "./measurementTypesAPI";
import userGroup from './userGroupAPI';
import alarm from './alarmAPI';
import alarmProfile from "./alarmProfileAPI";
import dashboard from "./dashboardAPI";
import activity from "./activityAPI";
import stagingMeasurements from "./stagingMeasurementsAPI";
import stagingMetrics from "./stagingMetricsAPI";
import stakeholder from './stakeholderAPI';
import reports from "./reportsAPI";

const API = {
    authKeycloak,
    keycloak,
    configuration,
    user,
    role,
    measurements,
    metricReports,
    metrics,
    notes,
    entities,
    measureEntity,
    topic,
    goal,
    logs,
    target,
    measurementTypes,
    userGroup,
    alarm,
    alarmProfile,
    dashboard,
    activity,
    stagingMeasurements,
    stagingMetrics,
    stakeholder,
    reports,
}

export default API;