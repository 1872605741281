import React, { useState } from 'react';
import MenuItems from './MenuItems';
import { AlertSnackbar } from 'components';
import { Drawer } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomMenuDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(40),
            },
            [theme.breakpoints.down('sm')]: {
                width: '100vw',
            },
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                [theme.breakpoints.down('sm')]: {
                    width: 0,
                },
                [theme.breakpoints.up('sm')]: {
                    width: ((fontSize) => {
                        if (fontSize < 12) return theme.spacing(7);
                        else if (fontSize > 16) return theme.spacing(10);
                        else return theme.spacing(8);
                    })(theme.typography.fontSize),
                },
            }),
        },
    }),
);

const NavBar = (props) => {
    const { open, selectedItem } = props;
    const [alert, setAlert] = useState({ open: false });

    return <>
        <AlertSnackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} open={alert.open} onClose={() => setAlert({ ...alert, open: false })} hideCloseButton severity={alert.severity} messageId={alert.messageId} />
        <CustomMenuDrawer variant="permanent" open={open}>
            <MenuItems open={open} selectedItem={selectedItem} setAlert={setAlert} />
        </CustomMenuDrawer>
    </>
};

export default NavBar;