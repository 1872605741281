import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { userSelector, permissionSelector } from "redux/userSlice";
import { AddEditRoleModal, AlertSnackbar, ConfirmationDialog, CustomDataGrid, RoleDetailsView, ExpandedGridCell, RolesUsersLinks } from "components";
import { CardActions, Button, Card, CardContent, CardHeader, useMediaQuery, Chip, Typography, IconButton, Alert } from "@mui/material";
import { EditOutlined, DeleteOutline, CancelOutlined, AddOutlined } from "@mui/icons-material";
import API from "api";


const RolesView = () => {
    const { token } = useSelector(userSelector);
    const [roles, setRoles] = useState([]);
    const [roleId, setRoleId] = useState('');
    const [loading, setLoading] = useState(false);
    const [reloadData, setReloadData] = useState(false);
    const [alert, setAlert] = useState({ open: false });
    const [openDialog, setOpenDialog] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [openRoleDetailsDialog, setOpenRoleDetailsDialog] = useState(false);
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const intl = useIntl();

    const manageRolesPermission = useSelector((state) => permissionSelector(state, 'manage-roles'));

    const onAlertClose = () => setAlert({ ...alert, open: false });

    useEffect(() => {
        setLoading(true);
        API.role.getAllRoles(token).then(items => {
            if (items && items.data) {
                const data = items.data.map(item => {
                    return {
                        id: item._id,
                        name: item.name,
                        permissions: item.permissions,
                        entities: item.entityIds.map((entityId) => entityId.name)
                    }
                })
                setRoles(data);
            }
            else setRoles([])
            setLoading(false);
        }).catch(error => {
            setAlert({ open: true, messageId: error?.data?.id || "ERROR.FETCHING_ROLES", severity: "error" });
            setRoles({ error });
            setLoading(false);
            console.error(error);
        });

        return () => {
            setReloadData(false);
        }
    }, [token, reloadData]);

    const handleDeleteRole = () => {
        API.role.deleteRole(roleId, token).then(response => {
            if (response?.data) {
                setAlert({ open: true, messageId: "ROLE.DELETE_SUCCESS", severity: "success" });
                setReloadData(true);
                setOpenConfirmDialog(false);
            }
        }).catch(error => {
            setAlert({ open: true, messageId: error?.data?.id || "ERROR.NOT_DELETED", severity: "error" });
            setOpenConfirmDialog(false);
            console.error(error);
        });
        setRoleId('');
    }

    const headers = [
        {
            field: 'name',
            headerName: intl.formatMessage({ id: 'NAME' }),
            ...(!smallScreen && { flex: 1 }),
            renderCell: (row) => <ExpandedGridCell value={row.value} width={row.colDef.computedWidth} />,
            minWidth: 200
        },
        {
            field: 'permissions',
            sortable: false,
            headerName: intl.formatMessage({ id: 'PERMISSIONS' }),
            ...(!smallScreen && { flex: 1 }),
            valueGetter: (row) => row.row.permissions.map(el => intl.formatMessage({ id: el })).join(","),
            renderCell: (row) => {
                if (!row.row.permissions.length) return <Typography color="text.disabled"><FormattedMessage id="NO_DATA" /></Typography>;
                else {
                    const firstPermission = intl.formatMessage({ id: row.row.permissions[0] })
                    return <>
                        {row.row.permissions.length > 1 ? <>{firstPermission}
                            <span><Chip sx={{ ml: 0.5 }} size="small" variant="outlined" label={<span style={{ textTransform: 'lowercase' }}>{row.row.permissions.length - 1} <FormattedMessage id="MORE" /></span>} /></span>
                        </>
                            : firstPermission
                        }
                    </>
                }
            },
            minWidth: 200
        },
        {
            field: 'entities',
            sortable: false,
            type: 'array',
            headerName: intl.formatMessage({ id: 'ENTITIES' }),
            ...(!smallScreen && { flex: 1 }),
            valueGetter: (row) => row.row.entities.join(","),
            renderCell: (row) => <>
                {row.row.entities.length > 1 ?
                    <>{row.row.entities[0]}
                        <span><Chip sx={{ ml: 0.5 }} size="small" variant="outlined" label={<span style={{ textTransform: 'lowercase' }}>{row.row.entities.length - 1} <FormattedMessage id="MORE" /></span>} /></span>
                    </>
                    : row.row.entities
                }
            </>,
            minWidth: 200
        },
        {
            field: 'actions',
            headerName: intl.formatMessage({ id: 'ACTIONS' }),
            type: 'actions',
            getActions: (el) => !manageRolesPermission || el.row.name === process.env.REACT_APP_ADMIN_ROLE ?
                [smallScreen ? <IconButton key="disabled-actions" disabled><CancelOutlined fontSize="small" /></IconButton> : <Button key="disabled-actions" disabled><FormattedMessage id="DISABLED" /></Button>] :
                [
                    <IconButton color="secondary" key="edit" onClick={() => { setOpenDialog(true); setRoleId(el.id) }}><EditOutlined fontSize="small" /></IconButton>,
                    <IconButton color="error" key="delete" onClick={() => { setOpenConfirmDialog(true); setRoleId(el.id) }}><DeleteOutline fontSize="small" /></IconButton>
                ],
            minWidth: 150
        }
    ]

    return (<>
        <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
        {!!roles.length && <RoleDetailsView open={openRoleDetailsDialog} onClose={() => setOpenRoleDetailsDialog(false)} role={roles.find(role => role.id === roleId)} setReloadData={setReloadData} setRoleId={setRoleId} />}
        <ConfirmationDialog
            open={openConfirmDialog}
            content={roleId && roles.length && <Typography variant="h6" color="error">{roles.find(el => el.id === roleId).name}</Typography>}
            title={<FormattedMessage id="ROLE.CONFIRM_DELETE" />}
            customButtonTitle={<FormattedMessage id="DELETE" />}
            customButtonColor="error"
            handleCancel={() => setOpenConfirmDialog(false)}
            handleCustomButton={() => handleDeleteRole()}
        />
        <AddEditRoleModal
            openDialog={openDialog}
            onClose={() => setOpenDialog(false)}
            roleId={roleId}
            reloadData={() => { setReloadData(true); setOpenDialog(false); setRoleId(''); }}
        />

        <Card sx={{ width: '100%' }}>
            <CardHeader title={<FormattedMessage id="ROLE.TABLE" />}
                action={manageRolesPermission && <Chip variant="outlined" onClick={() => { setOpenDialog(true); setRoleId('') }} icon={<AddOutlined fontSize="small" color="primary" />} label={<FormattedMessage id="ROLE.ADD" />} />}
            />
            <CardActions sx={{ px: 2 }}>
                <RolesUsersLinks roles />
            </CardActions>

            <CardContent>
                {roles?.error
                    ? <Alert severity='error'><FormattedMessage id="ERROR.NO_DATA" /></Alert>
                    : <CustomDataGrid isLoading={loading} rows={roles} columns={headers} handleData={(el) => { setRoleId(el.row.id); setOpenRoleDetailsDialog(true); }} />
                }
            </CardContent>
        </Card>
    </>
    )
}

export default RolesView;